<template>
    <v-card>
        <v-card-title class="headline blue-grey--text">Labot {{item.name}}</v-card-title>

        <v-card-text v-if="inputData">

            <v-form ref="form" v-if="item">
                <v-row no-gutters class="mt-10" align="center">
                    <v-col cols="12" md="4">
                        <v-select
                                class="px-3 py-1"
                                v-model="item.category_type"
                                color="blue-grey darken-1"
                                item-text="display_name"
                                :items="inputData.categoryTypes"
                                :rules="formRules"
                                label="Kategorijas veids"
                                return-object
                                outlined
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field
                                class="px-3 py-1"
                                v-model="item.name"
                                label="Nosaukums"
                                color="blue-grey darken-1"
                                :rules="formRules"
                                outlined
                                required>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" class="px-3 pb-3">
                        <v-radio-group v-model="item.agency_costs" row label="Aģentūras izdevumi" class="pa-0">
                            <v-radio label="Jā" :value="1"/>
                            <v-radio label="Nē" :value="0"/>
                        </v-radio-group>
                    </v-col>

                </v-row>

                <v-row row justify="center" justify-md="end" no-gutters class="mb-4">
                    <v-btn  text @click="hideEditModal" dark color="deep-orange lighten-1">Atcelt</v-btn>
                    <v-btn  text @click="submit" dark color="blue">Labot</v-btn>
                </v-row>
            </v-form>


        </v-card-text>

    </v-card>

</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "edit-category",
        data: () => ({
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),
        mounted() {
            this.$store.dispatch('getCategoryInputData')
        },
        computed: {
            ...mapGetters({
                item: 'editableItem',
                inputData: 'categoryInputData'
            })
        },
        methods: {
            hideEditModal(){
                this.$store.dispatch('hideEditModal')
            },
            submit(){
                this.$Progress.start()
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('updateCategory', {
                        name: this.item.name,
                        category_type_id: this.item.category_type.id,
                        agency_costs: this.item.agency_costs
                    })
                }  else {
                    this.$Progress.fail()
                }
            },
        }
    }
</script>

<style scoped>

</style>