<template>
  <v-container>

    <template v-if="invoice">
      <v-row align="center" justify="space-between" class="px-4 py-4">
        <h1 class="blue-grey--text ">Rēķins nr. {{invoice.id}}</h1>
        <v-btn text class="blue-grey--text" @click="goBack">
          Atpakaļ
        </v-btn>
      </v-row>

        <v-row no-gutters>
          <!--  Invoice details        -->
          <v-col cols="12" class="pa-4">
            <v-card height="100%">
              <div class="px-2 pt-2">
                <v-row no-gutters class="mb-2 px-2">
                  <div class=" px-2">
                    <span class="title font-weight-light ">Rēķina detaļas</span>
                  </div>
                  <v-spacer/>
                  <v-tooltip top v-if="!showEditInvoiceDetailsForm">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="showEditForm" text small slot="activator">
                        <v-icon color="grey">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Labot rēķina detaļas</span>
                  </v-tooltip>

                  <v-tooltip top v-if="showEditInvoiceDetailsForm">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="hideEditForm" text small slot="activator">
                        <v-icon color="grey">mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span>Atcelt</span>
                  </v-tooltip>
                </v-row>
                <hr>
              </div>
            <v-row class="px-4" no-gutters >
              <v-col cols="6" md="2" class="pa-2">
                <div class="caption grey--text">Rēķina nr</div>
                <div class="font-weight-light">{{invoice.number ? invoice.number : invoice.id}}</div>
              </v-col>
              <v-col cols="6" md="2" class="pa-2" v-if="!showEditInvoiceDetailsForm">
                <div class="caption grey--text">Rēķina datums</div>
                <div class="font-weight-light">{{invoice.invoice_date}}</div>
              </v-col>
              <v-col cols="6" md="2" class="pa-2" v-if="!showEditInvoiceDetailsForm">
                <div class="caption grey--text">Aģentūras izmaksas</div>
                <div class="font-weight-light">{{invoice.agency_costs}}</div>
              </v-col>
              <v-col cols="6" md="2" class="pa-2">
                <div class="caption grey--text">Kopā EUR</div>
                <div class="font-weight-light">{{invoice.total}}</div>
              </v-col>
              <v-col cols="6" md="2" class="pa-2">
                <div class="caption grey--text">PVN EUR</div>
                <div class="font-weight-light">{{invoice.vat}}</div>
              </v-col>
              <v-col cols="6" md="2" class="pa-2">
                <div class="caption grey--text">Summa EUR</div>
                <div class="font-weight-light">{{invoice.total_with_vat}}</div>
              </v-col>
              <v-col cols="6" md="2" class="pa-2" v-if="invoice.order.advance_payment > 0">
                <div class="caption grey--text">Ananss EUR</div>
                <div class="font-weight-light">{{invoice.order.advance_payment}}</div>
              </v-col>
              <template v-if="invoice.order.social_benefit > 0">
                <v-col cols="6" md="2" class="pa-2">
                  <div class="caption grey--text">VSAA EUR</div>
                  <div class="font-weight-light">{{invoice.order.social_benefit}}</div>
                </v-col>
                <v-col cols="12" md="2" class="pa-2">
                  <div class="caption grey--text">Apmaksai pēc VSAA EUR</div>
                  <div class="font-weight-light">{{invoice.order.social_benefit}}</div>
                </v-col>
              </template>
              <v-col cols="12" md="4" class="pa-2" v-if="!showEditInvoiceDetailsForm">
                <div class="caption grey--text">Apmaksas veids</div>
                <div class="font-weight-light">{{invoice.payment_method ? invoice.payment_method.display_name : 'Nav norādīts'}}</div>
              </v-col>
              <v-col cols="12" md="6" class="pa-2" v-if="!showEditInvoiceDetailsForm">
                <div class="caption grey--text">Summa vārdiem</div>
                <div class="font-weight-light">{{invoice.sum_in_words ? invoice.sum_in_words : 'Nav norādīts'}}</div>
              </v-col>

            </v-row>

            <edit-invoice-details v-if="showEditInvoiceDetailsForm"/>
            </v-card>
          </v-col>

        </v-row>

        <!--  INVOICE items        -->
        <v-row no-gutters class="px-4">
          <v-col cols="12">
            <v-card>
              <v-col cols="12"  class="pa-2" v-if="invoice.invoice_items.length > 0">
                <div class="mb-2 px-2">
                  <span class="title font-weight-light teal--text text--darken-3">Izvēlētās pozīcijas</span>
                </div>
                <v-divider class="teal darken-3"/>
              </v-col>

              <v-col cols="12" class="px-8">
                <div v-for="invoiceItem in invoice.invoice_items" :key="invoiceItem.id">
                  <v-row no-gutters class="py-2" align="center">

                    <v-col cols="12" lg="4" class="px-2" v-if="invoiceItem">
                      <div class="caption grey--text">Pozīcija</div>
                      <div class="font-weight-light">{{invoiceItem.name}}</div>
                    </v-col>
                    <v-col cols="4" lg="1" class="px-2" >
                      <div class="caption grey--text">Cena EUR</div>
                      <div class="font-weight-light">{{invoiceItem.price}}</div>
                    </v-col>
                    <v-col cols="5" lg="1" class="px-2" >
                      <div class="caption grey--text">Skaits</div>
                      <div class="font-weight-light">{{invoiceItem.quantity}}</div>
                    </v-col>
                    <v-col cols="3" lg="1" class="px-2" >
                      <div class="caption grey--text">PVN % </div>
                      <div class="font-weight-light">{{invoiceItem.vat_rate}}</div>
                    </v-col>
                    <v-col cols="4" lg="1" class="px-2" >
                      <div class="caption grey--text">PVN EUR </div>
                      <div class="font-weight-light">{{invoiceItem.vat}}</div>
                    </v-col>
                    <v-col cols="5" lg="2" class="px-2" >
                      <div class="caption grey--text">SUMMA EUR </div>
                      <div class="font-weight-light">{{invoiceItem.total_with_vat}}</div>
                    </v-col>
                    <v-col cols="6" sm="1" class="px-2 pt-2" >
                        <v-tooltip top >
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon @click="editItem(invoiceItem)" text small slot="activator" class="pb-2">
                              <v-icon color="teal darken-3">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Labot pozīciju</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="6" sm="1" class="px-2 pt-2" >
                      <template v-if="invoice.invoice_items.length > 1">
                        <v-tooltip top >
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon @click="deleteInvoiceItem(invoiceItem.id)" text small slot="activator" class="pb-2">
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Dzēst pozīciju</span>
                        </v-tooltip>
                      </template>
                    </v-col>
                  </v-row>
                  <div v-if="invoice.invoice_items.length > 1">
                    <v-divider/>
                  </div>
                </div>
              </v-col>
            </v-card>
          </v-col>
        </v-row>

        <add-invoice-items v-if="showAddInvoiceItemsForm"/>
        <!--  Invoice actions        -->
        <v-row no-gutters>
          <v-col cols="12"  class="pa-4">
            <v-card>
            <div class="mb-2 px-2 pt-2">
              <div class="pb-2">
                <span class="title font-weight-light px-2 pb-2">Darbības</span>
              </div>
              <hr>
            </div>

            <v-row class="pa-2" justify="center">
              <v-btn text class="blue-grey--text mx-4" @click="showAddItemsForm" v-if="!showAddInvoiceItemsForm && invoice.order.order_items.length > 0">Pievienot papildus pozīcījas</v-btn>
              <v-btn text class="blue-grey--text mx-4" @click="streamInvoicePDF(invoice.order.uuid)">Drukāt rēķinu</v-btn>
            </v-row>

            </v-card>

          </v-col>
        </v-row>


      <v-dialog v-model="showEditModal" max-width="700" persistent>
        <edit-invoice-item/>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import EditInvoiceDetails from "../components/EditInvoiceDetails";
  import AddInvoiceItems from "../components/AddInvoiceItems";
  import AddOrderServiceProvider from "../components/AddOrderServiceProvider";
  import EditInvoiceItem from "../components/EditInvoiceItem";

  export default {
    name: "Invoice",
    components: {
      AddOrderServiceProvider,
      EditInvoiceDetails,
      EditInvoiceItem,
      AddInvoiceItems
    },
    data: () => ({

    }),
    mounted() {
      this.$store.dispatch('clearSingleInvoice')
      this.$store.dispatch('hideEditModal')
      this.$store.dispatch('getSingleInvoice', this.$route.params.invoiceId)
    },
    computed: {
      ...mapGetters({
        invoice: 'invoice',
        showEditModal: 'showEditModal',
        showEditInvoiceDetailsForm: 'showEditInvoiceDetailsForm',
        showAddInvoiceItemsForm: 'showAddInvoiceItemsForm',
      }),
    },
    methods: {
      goBack(){
        this.$store.dispatch('hideInvoiceEditForms')
        this.$router.push({ path: '/orders/' + this.$route.params.orderId })
      },
      editItem(item){
        this.$store.dispatch('showEditModal', item)
      },
      showEditForm(){
        this.$store.dispatch('showEditInvoiceDetailsForm')
      },
      showAddItemsForm(){
        this.$store.dispatch('showAddInvoiceItemsForm')
      },
      hideEditForm(){
          this.$store.dispatch('hideInvoiceEditForms')
      },
      deleteInvoiceItem(invoiceItemId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteInvoiceItem', {
            invoiceItemId: invoiceItemId,
            invoiceId : this.invoice.id
          })
        }
      },
      streamInvoicePDF (orderUUID) {
        var baseUrl = process.env.VUE_APP_BASE_URL
        window.open( baseUrl + '/api/' + orderUUID + '/pdf/invoice' , "_blank");
      }



    }
  }
</script>
