<template>

  <v-container v-if="order">
    <v-row align="center">
      <v-col >
        <h1 class="heading blue-grey--text ">Izrakstīt rēķinu pasūtījumam  nr. {{order.id}}</h1>
      </v-col>
      <v-spacer/>
      <v-btn text class="blue-grey--text" @click="goBack">
        Atcelt
      </v-btn>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" class="pa-4" >
        <v-card>
          <div class="px-2 pt-2">
            <v-row no-gutters class="mb-2 px-2">
              <div class=" px-2">
                <span class="title font-weight-light blue-grey--text">Pasūtījuma detaļas</span>
              </div>
              <v-spacer/>
            </v-row>
            <v-divider class="blue-grey"/>


          <v-row class="mb-2 px-4">
            <v-col cols="12" md="6">
              <v-row no-gutters>
                <v-col cols="12" md="4">
                  <div class="caption grey--text">Pasūtījuma nr.</div>
                  <div class="font-weight-light">{{order.id}}</div>
                </v-col>
                <v-col cols="12" md="4">
                  <div class="caption grey--text">Pasūtījuma datums</div>
                  <div class="font-weight-light">{{order.order_date}}</div>
                </v-col>
                <v-col cols="12" md="4" v-if="order.branch">
                  <div class="caption grey--text">Filiāle</div>
                  <div class="font-weight-light">{{order.branch.name}}</div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-row no-gutters v-if="order.customer">
                <v-col cols="12" md="6">
                  <div class="caption grey--text">Klients</div>
                  <div class="font-weight-light">{{order.customer.name}}</div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="caption grey--text">Reg. nr. / PK</div>
                  <div class="font-weight-light">{{order.customer.reg_nr ? order.customer.reg_nr : "Nav norādīts"}}</div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <div class="caption grey--text">E-pasts</div>
                  <div class="font-weight-light">{{order.customer.email ? order.customer.email : "Nav norādīts"}}</div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="caption grey--text">Tel nr.</div>
                  <div class="font-weight-light">{{order.customer.phone ? order.customer.phone : "Nav norādīts"}}</div>
                </v-col>
              </v-row>
            </v-col>

          </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>



    <v-row no-gutters>
      <v-col cols="12" class="pa-4" >
        <v-card>
          <div class="px-2 pt-2">
            <v-row no-gutters class="mb-2 px-2">
              <div class=" px-2">
                <span class="title font-weight-light blue-grey--text">Rēķina pozīcijas</span>
              </div>
              <v-spacer/>
            </v-row>
            <v-divider class="blue-grey"/>

            <v-row class="mb-2 px-4">

              <v-col cols="12" lg="9">
                <v-form ref="form">
                  <v-row no-gutters>
                      <v-col cols="12" md="3">
                            <v-menu
                                    v-model="showPicker"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                        color="blue-grey darken-1"
                                        class="px-4"
                                        v-model="invoiceDate"
                                        :rules="formRules"
                                        label="Rēkina datums"
                                        readonly
                                        v-on="on"
                                />
                              </template>
                              <v-date-picker v-model="invoiceDate" color="blue-grey lighten-1"  @input="showPicker = false"/>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                                  v-model="invoiceNumber"
                                  hide-details
                                  label="Rēķina nr."
                                  color="grey"
                                  class="pb-4 px-4"
                          />
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-select
                                  color="blue-grey darken-1"
                                  class="px-4"
                                  label="Apmaksas veids"
                                  v-model="paymentMethod"
                                  :items="order.payment_methods"
                                  item-text="display_name"
                                  return-object
                                  :rules="formRules"
                          />
                        </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field
                                v-model="order.agency_costs"
                                hide-details
                                label="Aģentūtas izmaksas"
                                type="number"
                                color="grey"
                                class="pb-4 px-4"
                        />
                      </v-col>

                      <v-col cols="12" >

                      <template v-if="order.order_items">

                        <v-checkbox
                                v-model="selectAll"
                                hide-details
                                @change="selectAllItems"
                                :label=" selectAll ? 'Noņemt visus' : 'Atzīmēt visus' "
                                color="blue-grey"
                                class="py-2"
                        />

                        <v-row no-gutters v-for="item in order.order_items" :key="item.id" align="center">
                          <v-col class="pb-2" cols="12" md="5">
                            <v-checkbox
                                    v-model="selectedInvoiceItems"
                                    :value="item"
                                    hide-details
                                    :label="item.item.name"
                                    color="blue-grey"
                                    class="py-2"
                            />
                          </v-col>

                            <v-col cols="6" md="2">
                              <v-text-field
                                      type="number"
                                      class="ma-0 py-1"
                                      v-model="item.price"
                                      hide-details
                                      label="Cena EUR"
                                      color="blue-grey"
                                      dense
                              />
                            </v-col>
                            <v-col cols="6" md="2">
                              <v-text-field
                                      type="number"
                                      class="ma-0 pa-0 px-2"
                                      v-model="item.quantity"
                                      hide-details
                                      label="Daudzums"
                                      color="blue-grey"
                                      dense
                              />
                            </v-col>
                            <v-col >
                              <v-radio-group v-model="item.vat_rate" row hide-details class="mt-0">
                                <v-radio color="blue-grey" name="vatRate" label="21 %" :value="21"  hide-details/>
                                <v-radio color="blue-grey" name="vatRate" label="0 %" :value="0"  hide-details/>
                              </v-radio-group>
                            </v-col>
                        </v-row>
                      </template>

                    </v-col>
                  </v-row>
                </v-form>
              </v-col>

              <v-col cols="12" lg="3">
                <v-row no-gutters class="px-4 my-4">
                  <v-col cols="12">
                    <span class="caption">Summa EUR:</span>
                     {{total}}
                  </v-col>
                  <v-col cols="12">
                    <span class="caption">PVN EUR:</span>
                    {{totalVat}}
                  </v-col>
                  <v-col cols="12" class="mb-4">
                    <span class="caption">Kopā EUR: </span>
                    {{totalWithVat}}
                  </v-col>
                  <v-col cols="12" v-if="order.advance_payment > 0">
                    <span class="caption"> Avanss EUR: </span>
                    {{order.advance_payment}}
                  </v-col>
                  <v-col cols="12" v-if="order.social_benefit > 0" class="mb-4">
                    <span class="caption"> VSAA EUR: </span>
                    {{order.social_benefit}}
                  </v-col>
                  <v-col cols="12" v-if="order.social_benefit > 0">
                    <span class="caption"> Summa apmaksai EUR: </span>
                   {{(totalAfterBenefits - +order.advance_payment).toFixed(2)}}
                  </v-col>
                </v-row>
              </v-col>

            </v-row>

            <v-row row justify="center" justify-md="end" no-gutters class="mb-4">
              <v-btn  text @click="goBack" dark color="deep-orange lighten-1">Atcelt</v-btn>
              <v-btn v-if="showSubmitButton" text @click="submit" dark color="green accent-4">Pievienot</v-btn>
            </v-row>

          </div>
        </v-card>
      </v-col>
    </v-row>



  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "create-invoice",
    data: () => ({
      invoiceNumber: '',
      invoiceDate: '',
      paymentMethod: '',
      showPicker: false,
      selectAll: false,
      selectedInvoiceItems: [],
      formRules: [
        v => !!v || 'Šis lauks ir obligāts',
      ],
    }),
    mounted() {
      this.$store.dispatch('clearSingleOrder')
      this.$store.dispatch('getSingleOrder', this.$route.params.orderId).then(
      )
    },
    computed: {
      ...mapGetters({
        order: 'order',
      }),
      showSubmitButton(){
        return this.selectedInvoiceItems.length > 0
      },
      total(){
        const itemTotal = this.selectedInvoiceItems.reduce(function(sum, item){
          return sum + Number(
                  (item.price * (item.quantity ? item.quantity : 1 )) || 0)
        }, 0).toFixed(2)
        return (+itemTotal + +this.order.agency_costs).toFixed(2)
      },
      totalVat(){
        const itemsTotalVat = this.selectedInvoiceItems.reduce(function(sum, item){
          return sum + Number(
                  ((item.price * (item.quantity ? item.quantity : 1) * (item.vat_rate > 0 ? item.vat_rate / 100 : 0))) || 0)
        }, 0).toFixed(2)
        const agencyCostsVat = +this.order.agency_costs * 0.21
        return (+itemsTotalVat + +agencyCostsVat).toFixed(2)
      },
      totalWithVat(){
        var total = +this.total
        var totalVat = +this.totalVat
        return (total + totalVat).toFixed(2)
      },
      totalAfterBenefits(){
        return (+this.totalWithVat - +this.order.social_benefit).toFixed(2)
      },
    },
    methods: {
      selectAllItems(){
        if(this.selectAll === true){
          this.selectedInvoiceItems = this.order.order_items
        } else {
          this.selectedInvoiceItems = []
        }
      },
      goBack(){
        this.$router.push({ path: '/orders/' + this.order.id })
      },
      submit(){
        this.$Progress.start()
        if (this.$refs.form.validate()) {
          this.$store.dispatch('createInvoice', {
            invoice_date: this.invoiceDate,
            number: this.invoiceNumber,
            invoice_items: this.selectedInvoiceItems,
            payment_method_id: this.paymentMethod.id,
            agency_costs: this.order.agency_costs,
          })

        }  else {
          this.$Progress.fail()
        }
      },
    }
  }
</script>
