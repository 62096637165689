<template>
    <v-card v-if="item">
        <v-card-title v-if="item.item" class="headline blue-grey--text">Labot {{item.item.name}}</v-card-title>

        <v-card-text >

            <v-form ref="form" v-if="item">
                <v-row no-gutters class="mt-10" align="center">

                    <v-col cols="6" md="5">
                        <v-text-field
                                type="number"
                                class="ma-0 py-1"
                                v-model="item.price"
                                label="Cena EUR"
                                :rules="formRules"
                                dense
                        />
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-text-field
                                type="number"
                                class="ma-0 pa-0 px-2"
                                v-model="item.quantity"
                                label="Daudzums"
                                :rules="formRules"
                                dense
                        />
                    </v-col>
                    <v-col >
                        <v-radio-group v-model="item.vat_rate" row hide-details class="mt-0">
                            <v-radio color="gray"  name="vatRate" label="21 %" :value="21"  hide-details/>
                            <v-radio color="gray" name="vatRate" label="0 %" :value="0"  hide-details/>
                        </v-radio-group>
                    </v-col>

                </v-row>

                <v-row row justify="center" justify-md="end" no-gutters class="ma-4">
                    <v-btn  text @click="hideEditModal" dark color="deep-orange lighten-1">Atcelt</v-btn>
                    <v-btn  text @click="submit" dark color="blue">Labot</v-btn>
                </v-row>
            </v-form>


        </v-card-text>

    </v-card>

</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "edit-order-item",
        data: () => ({
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),
        mounted() {
        },
        computed: {
            ...mapGetters({
                item: 'editableItem',
            })
        },
        methods: {
            hideEditModal(){
                this.$store.dispatch('getSingleOrder', this.$route.params.orderId)
                this.$store.dispatch('hideEditModal')
            },
            submit(){
                this.$Progress.start()
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('updateOrderItem', {
                        price: this.item.price,
                        quantity: this.item.quantity,
                        vat_rate: this.item.vat_rate,
                    })
                }  else {
                    this.$Progress.fail()
                }
            },
        }
    }
</script>

<style scoped>

</style>