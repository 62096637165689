<template>

    <v-card justify="center" class="my-3 mx-4 px-4">

        <v-row no-gutters class="mb-2">
            <div class=" px-2 pt-2">
                <span class="title font-weight-light ">Pievienot papildus pozīcijas</span>
            </div>
            <v-spacer/>
            <v-btn right text class="py-2" @click="hideAddItemsForm">X</v-btn>
        </v-row>
        <hr>

        <v-col cols="12" >

            <template v-if="invoice.order.order_items">

                <v-row no-gutters v-for="item in invoice.order.order_items" :key="item.id" align="center">
                    <v-col class="pb-2" cols="12" md="5">
                        <v-checkbox
                                v-model="selectedInvoiceItems"
                                :value="item"
                                hide-details
                                :label="item.item.name"
                                color="blue-grey"
                                class="py-2"
                        />
                    </v-col>

                    <v-col cols="6" md="2">
                        <v-text-field
                                type="number"
                                class="ma-0 py-1"
                                v-model="item.price"
                                hide-details
                                label="Cena EUR"
                                color="blue-grey"
                                dense
                        />
                    </v-col>
                    <v-col cols="6" md="2">
                        <v-text-field
                                type="number"
                                class="ma-0 pa-0 px-2"
                                v-model="item.quantity"
                                hide-details
                                label="Daudzums"
                                color="blue-grey"
                                dense
                        />
                    </v-col>
                    <v-col >
                        <v-radio-group v-model="item.vat_rate" row hide-details class="mt-0">
                            <v-radio color="blue-grey" name="vatRate" label="21 %" :value="21"  hide-details/>
                            <v-radio color="blue-grey" name="vatRate" label="0 %" :value="0"  hide-details/>
                        </v-radio-group>
                    </v-col>
                </v-row>
            </template>

        </v-col>



        <v-row row justify="center" justify-md="end" no-gutters class="pb-4 px-2">
            <v-btn  text @click="hideAddItemsForm" dark color="deep-orange lighten-1">Atcelt</v-btn>
            <v-btn  text @click="submit" dark color="green accent-3">Pievienot</v-btn>
        </v-row>


    </v-card>
</template>

<script>

    import {mapGetters} from "vuex";
    import AddOrderProduct from "../components/AddOrderProduct";

    export default {
        name: "add-order-items",
        components: {
            AddOrderProduct
        },
        data: () => ({
            selectedInvoiceItems: [],
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),
        mounted() {
        },
        computed: {
            ...mapGetters({
                invoice: 'invoice',
            })
        },
        methods: {

            hideAddItemsForm(){
                this.$store.dispatch('hideInvoiceEditForms')
            },
            submit(){
                this.$Progress.start()
                this.$store.dispatch('addInvoiceItems', {
                        invoice_items: this.selectedInvoiceItems
                    })
            },
        }
    }
</script>

<style scoped>

</style>