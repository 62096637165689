<template>
    <v-row justify="center" no-gutters class="px-2">
        <template v-if="inputData">
                <v-col cols="12">
                        <v-form ref="form">
                            <v-row no-gutters class="mt-2">
                                    <v-col cols="12" sm="6" md="4">
                                        <v-select
                                                label="Filiāle"
                                                v-model="order.branch"
                                                :items="inputData.branches"
                                                item-text="name"
                                                return-object
                                                color="blue-grey darken-1"
                                                class="px-4"
                                        >
                                        </v-select>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="4">
                                            <v-menu
                                                    v-model="showPicker"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"

                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            color="blue-grey darken-1"
                                                            class="px-4"
                                                            v-model="order.order_date"
                                                            label="Pasūtījuma datums"
                                                            hide-details
                                                            readonly
                                                            v-on="on"
                                                    />
                                                </template>
                                                <v-date-picker v-model="order.order_date" color="blue-grey lighten-1"  @input="showPicker = false"/>
                                            </v-menu>
                                        </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                                label="Aģentūras %"
                                                type="number"
                                                v-model="order.agency_rate"
                                                color="blue-grey darken-1"
                                                class="px-4"
                                        />
                                    </v-col>

                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                                label="Avanss EUR"
                                                type="number"
                                                v-model="order.advance_payment"
                                                color="blue-grey darken-1"
                                                class="px-4"
                                        />
                                    </v-col>

                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                                label="VSAA EUR"
                                                type="number"
                                                v-model="order.social_benefit"
                                                color="blue-grey darken-1"
                                                class="px-4"
                                        />
                                    </v-col>

                                    <v-col cols="12">
                                        <tiptap-vuetify
                                            class="pb-4 pt-2"
                                            v-model="order.notes"
                                            :extensions="extensions"
                                            placeholder="Piezīmes"
                                            flat
                                            :toolbar-attributes="{ color: '#f9f5ff' }"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row row justify="center" justify-md="end" no-gutters class="">
                                    <v-btn  text @click="hideEditForm" dark color="deep-orange lighten-1">Atcelt</v-btn>
                                    <v-btn  text @click="submit" dark color="blue accent-3">Labot</v-btn>
                                </v-row>
                        </v-form>
                </v-col>
        </template>
    </v-row>
</template>

<script>

    import {mapGetters} from "vuex";
    import {
        TiptapVuetify,
        Bold,
        Italic,
        HardBreak,
        History
    } from 'tiptap-vuetify'

    export default {
        name: "edit-order-details",
        components: {TiptapVuetify},
        data: () => ({
            showPicker: false,
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
            extensions: [
                History,
                Bold,
                Italic,
                HardBreak,
            ],
        }),
        mounted() {
            this.$store.dispatch('getEditOrderInputData')
        },
        computed: {
            ...mapGetters({
                inputData: 'editOrderInputData',
                order: 'order'
            })
        },
        methods: {
            hideEditForm(){
                this.$store.dispatch('hideEditForms')
            },
            submit(){
                this.$Progress.start()
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('updateOrderDetails')
                }  else {
                    this.$Progress.fail()
                }
            },
        }
    }
</script>

<style scoped>

</style>