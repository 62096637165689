<template>
    <v-container>
        <v-row align="center" justify="space-between" class="px-4 py-4">
            <h2 class="blue-grey--text ">Izveidot pasūtījumu</h2>
            <v-btn text class="blue-grey--text" @click="goBack">
                Atcelt
            </v-btn>
        </v-row>

        <v-row>
            <v-col cols="12" lg="8">

                <div style="max-height: 100vh"
                     class="overflow-y-auto">

                    <v-row no-gutters>

                        <v-col cols="12" class=" pb-4">
                            <v-card outlined class="pa-4">
                                <v-row no-gutters justify="center">
                                    <v-col cols="6" md="5">
                                        <v-select
                                            label="Filiāle"
                                            v-model="branchId"
                                            :items="branches"
                                            item-text="name"
                                            item-value="id"
                                            color="blue-grey darken-1"
                                        >
                                        </v-select>
                                    </v-col>

                                    <v-col cols="6" md="5">
                                        <v-menu
                                            v-model="showPicker"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    color="blue-grey darken-1"
                                                    class="px-4"
                                                    v-model="orderDate"
                                                    label="Pasūtījuma datums"
                                                    hide-details
                                                    readonly
                                                    v-on="on"
                                                />
                                            </template>
                                            <v-date-picker v-model="orderDate" color="blue-grey lighten-1"
                                                           @input="showPicker = false"/>
                                        </v-menu>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters justify-md="center" class="px-4 mt-2">
                                    <v-col cols="6" md="4">
                                        <v-switch label="Mirušais" color="cyan darken-3"
                                                  v-model="showDecasedPersonForm"/>
                                    </v-col>
                                    <v-col cols="6" md="4">
                                        <v-switch label="Bēres" color="teal lighten-1" v-model="showFuneralForm"/>
                                    </v-col>
                                    <v-col cols="6" md="4">
                                        <v-switch label="Paklapojumu sniedzēji" color="lime darken-3"
                                                  v-model="showProviderForm"/>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>

                        <v-col cols="12" class="py-4">
                            <v-card outlined>
                                <v-row class="px-4 py-2 indigo lighten-1" no-gutters>
                                    <v-col>
                                        <span class="font-weight-medium subtitle-1 white--text">Klients</span>
                                    </v-col>
                                </v-row>

                                <template v-if="!showAddCustomerForm">

                                    <v-row class="px-4 py-2" no-gutters align="center">
                                        <template v-if="!selectedCustomer">
                                            <v-row class="px-4" justify="space-between">

                                                <v-col cols="10" md="6">
                                                    <v-text-field
                                                        dense
                                                        hide-details
                                                        outlined
                                                        @input="searchCustomers"
                                                        v-model="search"
                                                        append-icon="mdi-magnify"
                                                        label="Meklēt klientu"
                                                        color="indigo lighten-1"
                                                        class=""
                                                    />
                                                </v-col>

                                                <v-col cols="2">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn v-on="on" small
                                                                   @click="showAddCustomerForm = !showAddCustomerForm"
                                                                   fab text color="indigo lighten-1" slot="activator">
                                                                <v-icon>mdi-plus</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Pievienot klientu</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-row>

                                    <template v-if="customers">
                                        <v-simple-table class="px-4" height="200px" fixed-header>
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">Nosaukums</th>
                                                    <th class="text-left">Reģ.nr. / PK</th>
                                                    <th class="text-left">Adrese</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="customer in customers.data" :key="customer.id"
                                                    @click="setCustomer(customer)">
                                                    <td>{{ customer.name }}</td>
                                                    <td>{{ customer.reg_nr }}</td>
                                                    <td>{{ customer.address }}</td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </template>

                                    <template v-if="selectedCustomer">
                                        <v-row class="px-4 pb-4" no-gutters justify="space-between">

                                            <v-col cols="11">
                                                <v-row no-gutters>
                                                    <v-col cols="12" md="6">
                                                        <div class="caption grey--text">Nosaukums</div>
                                                        <div class="font-weight-light">{{ selectedCustomer.name }}</div>
                                                    </v-col>
                                                    <v-col cols="12" md="6">
                                                        <div class="caption grey--text">Reģ.nr. / PK</div>
                                                        <div class="font-weight-light">{{ selectedCustomer.reg_nr }}
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div class="caption grey--text">Telefons</div>
                                                        <div class="font-weight-light">
                                                            {{ selectedCustomer.phone ? selectedCustomer.phone : "Nav norādīts" }}
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div class="caption grey--text">E-pasts</div>
                                                        <div class="font-weight-light">
                                                            {{ selectedCustomer.email ? selectedCustomer.email : "Nav norādīts" }}
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="caption grey--text">Adrese</div>
                                                        <div class="font-weight-light">
                                                            {{ selectedCustomer.address ? selectedCustomer.address : "Nav norādīts" }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>

                                            <v-col cols="1">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" @click="clearCustomer" small fab text
                                                               color="indigo lighten-1" slot="activator">
                                                            <v-icon>mdi-close</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Noņemt klientu</span>
                                                </v-tooltip>
                                            </v-col>

                                        </v-row>
                                    </template>

                                </template>

                                <template v-if="showAddCustomerForm">
                                    <v-row no-gutters justify="center">
                                        <v-col cols="12" md="5">
                                            <v-text-field v-model="newCustomer.name" label="Nosaukums" class="px-4"
                                                          color="indigo lighten-1"/>
                                        </v-col>
                                        <v-col cols="12" md="5">
                                            <v-text-field v-model="newCustomer.reg_nr" label="Reģ.nr / PK" class="px-4"
                                                          color="indigo lighten-1"/>
                                        </v-col>
                                        <v-col cols="12" md="10">
                                            <v-text-field v-model="newCustomer.address" label="Adrese" class="px-4"
                                                          color="indigo lighten-1"/>
                                        </v-col>
                                        <v-col cols="12" md="5">
                                            <v-text-field v-model="newCustomer.phone" label="Telefona nr" class="px-4"
                                                          color="indigo lighten-1"/>
                                        </v-col>
                                        <v-col cols="12" md="5">
                                            <v-text-field v-model="newCustomer.email" label="E-Pasts" class="px-4"
                                                          color="indigo lighten-1"/>
                                        </v-col>
                                    </v-row>

                                    <v-row row justify="center" justify-md="end" no-gutters class="mb-4">
                                        <v-btn text @click="showAddCustomerForm = !showAddCustomerForm" dark
                                               color="deep-orange lighten-1">Atcelt
                                        </v-btn>
                                        <v-btn text @click="createNewCustomer" dark color="green accent-4">Pievienot
                                        </v-btn>
                                    </v-row>

                                </template>

                            </v-card>
                        </v-col>

                        <template v-if="showDecasedPersonForm">
                            <v-col cols="12" class="py-4">
                                <v-card outlined>
                                    <v-row class="px-4 py-2 cyan darken-3" no-gutters>
                                        <v-col>
                                            <span class="font-weight-medium subtitle-1 white--text">Mirušais</span>
                                        </v-col>
                                    </v-row>
                                    <v-row class="px-4 py-2" no-gutters align="center">
                                        <v-col cols="6">
                                            <v-text-field v-model="decasedPerson.name" label="Vārds" class="px-4"
                                                          color="cyan darken-3"/>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="decasedPerson.surname" label="Uzvārds" class="px-4"
                                                          color="cyan darken-3"/>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="decasedPerson.address" label="Adrese" class="px-4"
                                                          color="cyan darken-3"/>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="decasedPerson.location" label="Lokācija" class="px-4"
                                                          color="cyan darken-3"/>
                                        </v-col>
                                    </v-row>

                                </v-card>
                            </v-col>
                        </template>

                        <template v-if="showFuneralForm">
                            <v-col cols="12" class="py-4">
                                <v-card outlined>
                                    <v-row class="px-4 py-2 teal lighten-1" no-gutters>
                                        <v-col>
                                            <span class="font-weight-medium subtitle-1 white--text">Bēres</span>
                                        </v-col>
                                    </v-row>
                                    <v-row class="px-4 py-2" no-gutters align="center">
                                        <v-col cols="6">
                                            <v-select
                                                label="Glabāšanas vieta"
                                                v-model="funeral.funeral_location_id"
                                                :items="funeralLocations"
                                                item-text="name"
                                                item-value="id"
                                                color="teal lighten-1"
                                                class="px-4"
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-select
                                                label="Ceremonijas vieta"
                                                v-model="funeral.funeral_locations_place_id"
                                                :items="funeralLocationsPlaces"
                                                item-text="name"
                                                item-value="id"
                                                color="teal lighten-1"
                                                class="px-4"
                                            >
                                            </v-select>
                                        </v-col>

                                        <v-row no-gutters>
                                            <v-col cols="6">
                                                <v-menu
                                                    v-model="showFuneralDatePicker"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            color="teal lighten-1"
                                                            class="px-4"
                                                            v-model="funeral.funeral_date"
                                                            label="Bēru datums"
                                                            hide-details
                                                            readonly
                                                            v-on="on"
                                                        />
                                                    </template>
                                                    <v-date-picker v-model="funeral.funeral_date" color="teal lighten-1"
                                                                   @input="showFuneralDatePicker = false"/>
                                                </v-menu>
                                            </v-col>

                                            <v-col cols="6">
                                                <v-text-field type="time" v-model="funeral.start_time"
                                                              label="Ceremonijas sākums" class="px-4"
                                                              color="teal lighten-1"/>
                                            </v-col>
                                        </v-row>
                                    </v-row>

                                </v-card>
                            </v-col>
                        </template>


                        <template v-if="showProviderForm">
                            <v-col cols="12" class="py-4">
                                <v-card outlined>
                                    <v-row class="px-4 py-2 lime darken-3" no-gutters>
                                        <v-col>
                                            <span class="font-weight-medium subtitle-1 white--text">Pakalpojumu sniedzēji</span>
                                        </v-col>
                                    </v-row>

                                    <template v-if="occupations">
                                        <v-row class="px-4 py-2" no-gutters align="center">
                                            <v-col cols="6" v-for="occupation in occupations" :key="occupation.id">
                                                <v-row class="px-4 py-1" no-gutters align="center">
                                                    <v-col class="pb-2" cols="12" md="5">
                                                        <v-checkbox
                                                            v-model="selectedOccupations"
                                                            :value="occupation"
                                                            hide-details
                                                            color="lime darken-3"
                                                            :label="occupation.name"
                                                            class="py-2"
                                                        />
                                                    </v-col>

                                                    <v-select
                                                        v-if="showServiceProviderSelect(occupation.id)"
                                                        @change="setOccupationServiceProvider"
                                                        :label="occupation.name"
                                                        :items="occupation.service_providers"
                                                        item-text="name"
                                                        return-object
                                                        color="lime darken-3"
                                                        :rules="formRules"
                                                        class="px-4"
                                                    >
                                                    </v-select>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </template>

                                </v-card>
                            </v-col>
                        </template>


                        <v-col cols="12" >
                            <div class="">
                                <tiptap-vuetify
                                    class="pb-4 pt-2 mx-4"
                                    v-model="notes"
                                    :extensions="extensions"
                                    placeholder="Piezīmes"
                                    :toolbar-attributes="{ color: '#f9f5ff' }"
                                />
                            </div>
                        </v-col>

                    </v-row>

                    <template v-if="categories">
                        <v-row no-gutters class="py-2">
                            <v-col cols="12" v-for="category in categories" :key="category.id" class="">
                                <template v-if="category.items.length > 0">
                                    <v-card outlined class="pb-4 mb-2">
                                        <v-row class="px-4 py-2 " :class="categoryTypeColor(category.category_type_id)"
                                               no-gutters>
                                            <v-col>
                                                <span
                                                    class="font-weight-medium subtitle-1 white--text">{{ category.name }}</span>
                                            </v-col>
                                        </v-row>

                                        <div class="py-2">
                                            <v-row v-for="item in category.items" :key="item.id" class="px-4 py-1"
                                                   no-gutters align="center">
                                                <v-col class="pb-2" cols="12" md="5">
                                                    <v-checkbox
                                                        v-model="selectedItems"
                                                        :value="item"
                                                        hide-details
                                                        :color="categoryTypeColor(category.category_type_id)"
                                                        :label="item.name"
                                                        class="py-2"
                                                    />
                                                </v-col>

                                                <template v-if="showItemDetailForm(item.id)">
                                                    <v-col cols="6" md="2">
                                                        <v-text-field
                                                            type="number"
                                                            class="ma-0 py-1"
                                                            v-model="item.price"
                                                            hide-details
                                                            label="Cena EUR"
                                                            :color="categoryTypeColor(category.category_type_id)"
                                                            :rules="formRules"
                                                            dense
                                                        />
                                                    </v-col>
                                                    <v-col cols="6" md="2">
                                                        <v-text-field
                                                            type="number"
                                                            class="ma-0 pa-0 px-2"
                                                            v-model="item.quantity"
                                                            hide-details
                                                            label="Daudzums"
                                                            :rules="formRules"
                                                            :color="categoryTypeColor(category.category_type_id)"
                                                            dense
                                                        />
                                                    </v-col>
                                                    <v-col>
                                                        <v-radio-group v-model="item.vat_rate" row hide-details
                                                                       class="mt-0">
                                                            <v-radio
                                                                :color="categoryTypeColor(category.category_type_id)"
                                                                name="vatRate" label="21 %" :value="21" hide-details/>
                                                            <v-radio
                                                                :color="categoryTypeColor(category.category_type_id)"
                                                                name="vatRate" label="0 %" :value="0" hide-details/>
                                                        </v-radio-group>
                                                    </v-col>
                                                </template>
                                            </v-row>
                                        </div>

                                    </v-card>
                                </template>
                            </v-col>
                        </v-row>
                    </template>

                    <v-row no-gutters>
                        <v-col cols="12" class="py-4">
                            <v-card outlined>
                                <v-row class="px-4 py-2 red darken-3" no-gutters>
                                    <v-col>
                                        <span class="font-weight-medium subtitle-1 white--text">Preces</span>
                                    </v-col>
                                    <v-spacer/>
                                    <v-tooltip top v-if="!showAddProductModal && selectedProducts.length > 0">
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" icon @click="showAddModal" text small slot="activator">
                                                <v-icon color="white">mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Pievienot preci</span>
                                    </v-tooltip>
                                </v-row>

                                <v-card-text v-if="selectedProducts.length > 0">
                                    <v-row v-for="item in selectedProducts" :key="item.id" class="px-4 py-1" no-gutters
                                           align="center">
                                        <v-col class="pb-2" cols="12" md="5">
                                            <v-checkbox
                                                v-model="selectedItems"
                                                :value="item"
                                                hide-details
                                                :color="categoryTypeColor(item.category.category_id)"
                                                :label="item.name"
                                                class="py-2"
                                            />
                                        </v-col>
                                        <template v-if="showItemDetailForm(item.id)">
                                            <v-col cols="6" md="2">
                                                <v-text-field
                                                    type="number"
                                                    class="ma-0 py-1"
                                                    v-model="item.price"
                                                    hide-details
                                                    label="Cena EUR"
                                                    :color="categoryTypeColor(item.category.category_id)"
                                                    :rules="formRules"
                                                    dense
                                                />
                                            </v-col>
                                            <v-col cols="6" md="2">
                                                <v-text-field
                                                    type="number"
                                                    class="ma-0 pa-0 px-2"
                                                    v-model="item.quantity"
                                                    hide-details
                                                    label="Daudzums"
                                                    :rules="formRules"
                                                    :color="categoryTypeColor(item.category.category_id)"
                                                    dense
                                                />
                                            </v-col>
                                            <v-col>
                                                <v-radio-group v-model="item.vat_rate" row hide-details class="mt-0">
                                                    <v-radio :color="categoryTypeColor(item.category.category_id)"
                                                             name="vatRate" label="21 %" :value="21" hide-details/>
                                                    <v-radio :color="categoryTypeColor(item.category.category_id)"
                                                             name="vatRate" label="0 %" :value="0" hide-details/>
                                                </v-radio-group>
                                            </v-col>
                                        </template>
                                    </v-row>
                                </v-card-text>

                                <template v-if="!showAddProductModal && selectedProducts.length < 1">
                                    <template v-if="!showAddProductModal">
                                        <v-row no-gutters justify="center" class="px-4 mt-3 font-weight-light">
                                            <v-col cols="12" class="text-center">
                                                Preces nav pievienotas
                                            </v-col>
                                            <v-btn text class="blue-grey--text" @click="showAddModal">
                                                Pievienot
                                            </v-btn>
                                        </v-row>
                                    </template>
                                </template>

                            </v-card>
                        </v-col>
                    </v-row>

                </div>
            </v-col>

            <v-col cols="12" lg="4">

                <v-card outlined>
                    <v-row class="px-4 py-2 blue-grey" no-gutters>
                        <v-col>
                            <span class="subtitle-1 font-weight-medium white--text">Kopā</span>
                        </v-col>
                    </v-row>

                    <template v-if="selectedItems.length > 0">
                        <div class="pa-4">
                            <v-row no-gutters class="px-2" v-for="selectedItem in selectedItems" :key="selectedItem.id">
                                <v-col>
                                    <span class="font-weight-light ">{{ selectedItem.name }}</span>
                                </v-col>
                                <v-col class="font-weight-medium ">
                                    {{ (selectedItem.price * (selectedItem.quantity ? selectedItem.quantity : 1) * (1 + (selectedItem.vat_rate > 0 ? selectedItem.vat_rate / 100 : 0))).toFixed(2) }}
                                    <span class="font-weight-light ">EUR</span>
                                </v-col>
                            </v-row>
                        </div>
                    </template>

                    <v-row justify="center" class="px-4">
                        <v-row no-gutters class="px-2" align="center">
                            <v-col cols="8" class="pl-3">
                                <span class="font-weight-light ">Aģentūras pakalpojumi %</span>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    v-model="agency_rate"
                                    hide-details
                                    type="number"
                                    color="grey"
                                    class="pb-4"
                                />
                            </v-col>
                        </v-row>

                        <v-row no-gutters class="px-2" align="center">
                            <v-col cols="8" class="pl-3">
                                <span class="font-weight-light ">Avanss EUR</span>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    v-model="advance_payment"
                                    hide-details
                                    type="number"
                                    color="grey"
                                    class="pb-4"
                                />
                            </v-col>
                        </v-row>

                        <v-row no-gutters class="px-2" align="center">
                            <v-col cols="8" class="pl-3">
                                <span class="font-weight-light ">VSAA pabalsts EUR</span>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    v-model="social_benefit"
                                    hide-details
                                    type="number"
                                    color="grey"
                                    class="pb-4"
                                />
                            </v-col>
                        </v-row>

                        <v-col cols="12">
                            <v-row no-gutters class="px-2">
                                <v-col>
                                    <span class="font-weight-light ">Aģentūras izmaksas</span>
                                </v-col>
                                <v-col class="font-weight-medium ">
                                    {{ agencyCosts }}
                                    <span class="font-weight-light ">EUR</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="px-2">
                                <v-col>
                                    <span class="font-weight-light ">Kopā</span>
                                </v-col>
                                <v-col class="font-weight-medium ">
                                    {{ total }}
                                    <span class="font-weight-light ">EUR</span>
                                </v-col>
                            </v-row>

                            <v-row no-gutters class="px-2">
                                <v-col>
                                    <span class="font-weight-light ">PVN</span>
                                </v-col>
                                <v-col class="font-weight-medium ">
                                    {{ totalVat }}
                                    <span class="font-weight-light ">EUR</span>
                                </v-col>
                            </v-row>

                            <v-row no-gutters class="px-2">
                                <v-col>
                                    <span class="font-weight-bold ">Summa</span>
                                </v-col>
                                <v-col class="font-weight-bold ">
                                    {{ totalWithVat }}
                                    <span class="font-weight-bold ">EUR</span>
                                </v-col>
                            </v-row>

                            <v-row no-gutters class="px-2" v-if="social_benefit > 0">
                                <v-col>
                                    <span class="font-weight-bold ">Summa pēc VSAA</span>
                                </v-col>
                                <v-col class="font-weight-bold ">
                                    {{ totalAfterBenefits }}
                                    <span class="font-weight-bold ">EUR</span>
                                </v-col>
                            </v-row>


                        </v-col>
                    </v-row>

                    <v-row row justify="center" justify-md="end" no-gutters class="mb-4">
                        <v-btn text @click="goBack" dark color="deep-orange lighten-1">Atcelt</v-btn>
                        <v-btn v-if="showSubmitButton" text @click="submit" dark color="green accent-4">Pievienot
                        </v-btn>
                    </v-row>

                </v-card>

            </v-col>
        </v-row>

        <v-dialog v-model="showAddProductModal" max-width="900" persistent>
            <add-order-product/>
        </v-dialog>

    </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import AddOrderProduct from "../components/AddOrderProduct";
import {
    TiptapVuetify,
    Bold,
    Italic,
    HardBreak,
    History
} from 'tiptap-vuetify'


export default {
    name: "Categories",
    components: {
        AddOrderProduct,
        TiptapVuetify
    },
    data: () => ({
        newCustomer: {},
        selectedItems: [],
        selectedOccupations: [],
        selectedServiceProviders: [],
        decasedPerson: {},
        funeral: {},
        showPicker: false,
        showFuneralDatePicker: false,
        showDecasedPersonForm: false,
        showAddCustomerForm: false,
        showFuneralForm: false,
        showProviderForm: false,
        orderDate: '',
        agency_rate: 5,
        advance_payment: 0,
        social_benefit: 0,
        branchId: 1,
        notes: '',
        formRules: [
            v => !!v || 'Šis lauks ir obligāts',
        ],
        extensions: [
            History,
            Bold,
            Italic,
            HardBreak,
        ],

    }),
    mounted() {
        this.$store.dispatch('clearCustomers')
        this.$store.dispatch('clearCreateOrderSelectedCustomer')
        this.$store.dispatch('clearCreateOrderCategories')
        this.$store.dispatch('changeSearch', '')
        this.$store.dispatch('clearProducts')
        this.$store.dispatch('clearSelectedProducts')
        this.$store.dispatch('getCreateOrderInputData')
        this.$store.dispatch('hideAddModal')
    },
    computed: {
        ...mapGetters({
            customers: 'customers',
            search: 'search',
            categories: 'orderCategories',
            branches: 'branches',
            occupations: 'occupations',
            funeralLocations: 'funeralLocations',
            funeralLocationsPlaces: 'funeralLocationsPlaces',
            selectedCustomer: 'selectedCustomer',
            showAddOrderProductsForm: 'showAddOrderProductsForm',
            selectedProducts: 'selectedProducts',
            showAddProductModal: 'showAddModal'
        }),
        agencyCosts() {
            const agencyRate = this.agency_rate
            const itemsWithAgencyCosts = this.selectedItems.filter(selectedItem => (selectedItem.agency_costs === 1))
            return itemsWithAgencyCosts.reduce(function (sum, item) {
                return sum + Number(
                    ((item.price * (item.quantity ? item.quantity : 1) * (agencyRate > 0 ? agencyRate / 100 : 0))) || 0)
            }, 0).toFixed(2)
        },
        total() {
            return this.selectedItems.reduce(function (sum, item) {
                return sum + Number(
                    (item.price * (item.quantity ? item.quantity : 1)) || 0)
            }, 0).toFixed(2)
        },
        totalWithAgencyCosts() {
            return +this.total + +this.agencyCosts
        },
        totalVat() {
            const itemsTotalVat = this.selectedItems.reduce(function (sum, item) {
                return sum + Number(
                    ((item.price * (item.quantity ? item.quantity : 1) * (item.vat_rate > 0 ? item.vat_rate / 100 : 0))) || 0)
            }, 0).toFixed(2)
            const agencyCostsVat = +this.agencyCosts * 0.21
            return (+itemsTotalVat + agencyCostsVat).toFixed(2)
        },
        totalWithVat() {
            var total = this.totalWithAgencyCosts * 1
            var totalVat = this.totalVat * 1
            return (total + totalVat).toFixed(2)
        },
        totalAfterBenefits() {
            return (+this.totalWithVat - +this.social_benefit).toFixed(2)
        },
        showSubmitButton() {
            return this.selectedCustomer && this.orderDate && this.selectedItems.length > 0;
        },

    },
    methods: {
        showAddModal() {
            this.$store.dispatch('showAddModal')
        },
        setOccupationServiceProvider(selectedServiceProvider) {
            if (this.selectedServiceProviders.some(serviceProvider => (serviceProvider.occupation_id === selectedServiceProvider.occupation_id)) === false) {
                this.selectedServiceProviders.push(selectedServiceProvider)
            } else {
                const filteredArray = this.selectedServiceProviders.filter(serviceProvider => (serviceProvider.occupation_id !== selectedServiceProvider.occupation_id))
                filteredArray.push(selectedServiceProvider)
                this.selectedServiceProviders = filteredArray
            }
        },
        setSelectedServiceProviders() {
            if (this.selectedServiceProviders.length > 0) {
                return this.selectedServiceProviders.filter((serviceProvider) => {
                    return this.selectedOccupations.some((selectedOccupation) => {
                        return serviceProvider.occupation_id === selectedOccupation.id
                    })
                })
            }
            return null
        },
        showItemDetailForm(itemId) {
            return this.selectedItems.some(item => (item.id === itemId))
        },
        showServiceProviderSelect(occupationId) {
            return this.selectedOccupations.some(occupation => (occupation.id === occupationId))
        },
        categoryTypeColor(categoryTypeId) {
            return categoryTypeId === 1 ? 'light-green darken-4' : 'teal darken-2'
        },
        goBack() {
            this.$router.push({path: '/orders'})
        },

        setCustomer(customer) {
            this.$store.dispatch('clearCustomers')
            this.$store.dispatch('changeSearch', '')
            this.$store.dispatch('setSelectedCustomer', customer)
        },
        clearCustomer() {
            this.$store.dispatch('clearCustomers')
            this.$store.dispatch('changeSearch', '')
            this.$store.dispatch('clearCreateOrderSelectedCustomer')
        },
        searchCustomers(value) {
            this.$store.dispatch('changeSearch', value)
            this.$store.dispatch('getCustomers')
        },
        createNewCustomer() {
            this.$store.dispatch('clearCustomers')
            this.$store.dispatch('createNewCustomerFromOrderView', this.newCustomer)
            this.showAddCustomerForm = false
        },
        submit() {
            this.$Progress.start()
            this.$store.dispatch('createNewOrder', {
                branchId: this.branchId,
                decasedPerson: this.decasedPerson,
                funeral: this.funeral,
                orderItems: this.selectedItems,
                serviceProviders: this.setSelectedServiceProviders(),
                orderDate: this.orderDate,
                agencyRate: this.agency_rate,
                agencyCosts: +this.agencyCosts,
                total: this.totalWithAgencyCosts,
                totalVat: this.totalVat,
                totalWithVat: this.totalWithVat,
                social_benefit: this.social_benefit,
                advance_payment: this.advance_payment,
                total_after_benefits: this.totalAfterBenefits,
                notes: this.notes
            })
        },
    }
}
</script>

<style scoped>

</style>