import axios from 'axios'
import {vm} from '@/main'

var baseUrl = process.env.VUE_APP_BASE_URL

const orders = {
    state: {
        orders: null,
        order: null,
        products: null,
        showEditOrderDetailsForm : false,
        showEditOrderCustomerForm : false,
        showEditOrderFuneralForm : false,
        showAddFuneralForm : false,
        showAddOrderServiceProviderForm : false,
        showEditDecasedPersonForm : false,
        showAddDecasedPersonForm : false,
        showAddOrderItemsForm : false,
        showAddOrderProductsForm : false,
        editOrderInputData: null,
        addOrderServiceProviderInputData: null
    },

    mutations: {
        setOrders(state, orders){
            state.orders = orders
        },
        setSingleOrder(state, order) {
            state.order = order
        },
        clearSingleOrder(state) {
            state.order = null
        },
        showEditOrderDetailsForm(state){
            state.showEditOrderDetailsForm = true
        },
        showEditOrderCustomerForm(state){
            state.showEditOrderCustomerForm = true
        },
        showEditOrderFuneralForm(state){
            state.showEditOrderFuneralForm = true
        },
        showAddFuneralForm(state){
            state.showAddFuneralForm = true
        },
        showEditDecasedPersonForm(state){
            state.showEditDecasedPersonForm = true
        },
        showAddDecasedPersonForm(state){
            state.showAddDecasedPersonForm = true
        },
        showAddOrderItemsForm(state){
            state.showAddOrderItemsForm = true
        },
        showAddOrderServiceProviderForm(state){
            state.showAddOrderServiceProviderForm = true
        },
        showAddOrderProductsForm(state){
            state.showAddOrderProductsForm = true
        },
        hideEditForms(state){
            state.showEditOrderFuneralForm = false
            state.showEditOrderDetailsForm = false
            state.showEditOrderCustomerForm = false
            state.showAddOrderServiceProviderForm = false
            state.showAddFuneralForm = false
            state.showEditDecasedPersonForm = false
            state.showAddDecasedPersonForm = false
            state.showAddOrderItemsForm = false
            state.showAddOrderProductsForm = false
        },
        setEditOrderInputData(state, payload){
            state.editOrderInputData = payload
        },
        clearOrderItemsInputData(state){
            state.editOrderInputData = null
        },
        addOrderServiceProviderInputData(state, payload){
            state.addOrderServiceProviderInputData = payload
        },
        setProducts(state, payload){
            state.products = payload
        },
        clearProducts(state){
            state.products = null
        },

    },

    actions: {
        getOrders({ commit, rootState, dispatch, state }, page){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/order?page='+page +'&q='+rootState.search)
                    .then(response => {
                        commit('setOrders', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        getSingleOrder({ commit, rootState, dispatch, state }, orderId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/order/' + orderId)
                    .then(response => {
                        commit('setSingleOrder', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        clearSingleOrder({commit}){
            commit('hideEditForms')
            commit('clearSingleOrder')
        },

        getEditOrderInputData({ commit, rootState, dispatch }){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/orders/edit')
                    .then(response => {
                        commit('setEditOrderInputData', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        getOrderItemsInputData({ commit, rootState, dispatch, state }){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/order_item/' + state.order.id + '/create')
                    .then(response => {
                        commit('setEditOrderInputData', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        getOrderServiceProviderInputData({ commit, rootState, dispatch, state }, orderId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/' + orderId + '/order_service_providers/create')
                    .then(response => {
                        commit('addOrderServiceProviderInputData', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        getProducts({ commit, rootState, dispatch }){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/products?&q='+rootState.search)
                    .then(response => {
                        commit('setProducts', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        clearProducts({commit}){
            commit('clearProducts')
        },

        addOrderServiceProvider({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/' + state.order.id + '/order_service_providers', payload)
                    .then(response => {
                        dispatch('hideEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Ieraksts izveidots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izveidot'
                            })
                        }
                        reject(error)
                    })
            })
        },

        createInvoice({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/' + state.order.id + '/invoices', payload)
                    .then(response => {
                        vm.$Progress.finish()
                        vm.$router.push({ path: '/orders/' + state.order.id })
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Rēķins izveidots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Rēķinu neizdevās izveidot'
                            })
                        }
                        reject(error)
                    })
            })
        },

        clearOrderItemsInputData({commit}){
            commit('clearOrderItemsInputData')
        },

        changeOrderCustomer({dispatch}, customerId){
            dispatch('updateOrder', {
                customer_id: customerId
            })
        },

        updateOrderDetails({dispatch, state}){
            dispatch('updateOrder', {
                branch_id: state.order.branch.id,
                order_date: state.order.order_date,
                agency_rate: state.order.agency_rate,
                advance_payment: state.order.advance_payment,
                social_benefit: state.order.social_benefit,
                notes: state.order.notes,
            })
        },

        updateOrder({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.patch(baseUrl +'/api/order/' + state.order.id, payload)
                    .then(response => {
                        dispatch('hideEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'blue',
                            text: 'Ieraksts izlabots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izlabot'
                            })
                        }
                        reject(error)
                    })
            })
        },

        addNewFuneralToOrder({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/order/' + state.order.id + '/funeral/', payload)
                    .then(response => {
                        dispatch('hideEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Ieraksts izveidots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izveidot'
                            })
                        }
                        reject(error)
                    })
            })
        },

        deleteFuneral({ commit, rootState, dispatch, state }, funeralId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.delete(baseUrl +'/api/funeral/' + funeralId )
                    .then(response => {
                        dispatch('hideEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Ieraksts dzēsts'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās dzēst'
                            })
                        }
                        reject(error)
                    })
            })
        },

        updateFuneral({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.patch(baseUrl +'/api/funeral/' + state.order.funeral.id, payload)
                    .then(response => {
                        dispatch('hideEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Ieraksts izlabots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izlabot'
                            })
                        }
                        reject(error)
                    })
            })
        },


        addNewDecasedPersonToOrder({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/order/' + state.order.id + '/decased_person/', payload)
                    .then(response => {
                        dispatch('hideEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Ieraksts izveidots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izveidot'
                            })
                        }
                        reject(error)
                    })
            })
        },

        updateDecasedPerson({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.patch(baseUrl +'/api/decased_person/' + state.order.decased_person.id, payload)
                    .then(response => {
                        dispatch('hideEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'blue',
                            text: 'Ieraksts izlabots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izlabot'
                            })
                        }
                        reject(error)
                    })
            })
        },

        updateOrderItem({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.patch(baseUrl +'/api/' + rootState.editableItem.order_id + '/order_item/' + rootState.editableItem.id, payload)
                    .then(response => {
                        dispatch('getSingleOrder', rootState.editableItem.order_id)
                        dispatch('hideEditModal')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Ieraksts izlabots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izlabot'
                            })
                        }
                        reject(error)
                    })
            })
        },

        deleteDecasedPerson({ commit, rootState, dispatch, state }, decasedPersonId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.delete(baseUrl +'/api/decased_person/' + decasedPersonId )
                    .then(response => {
                        dispatch('hideEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Ieraksts dzēsts'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās dzēst'
                            })
                        }
                        reject(error)
                    })
            })
        },


        updateOrderCustomer({ commit, rootState, dispatch, state }){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.patch(baseUrl +'/api/customer/' + state.order.customer.id, {
                    name: state.order.customer.name,
                    reg_nr: state.order.customer.reg_nr,
                    address: state.order.customer.address,
                    phone: state.order.customer.phone,
                    email: state.order.customer.email,
                })
                    .then(response => {
                        dispatch('hideEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'blue',
                            text: 'Ieraksts izlabots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izlabot'
                            })
                        }
                        reject(error)
                    })
            })
        },

        deleteOrder({commit, rootState, dispatch, state}, orderId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.delete(baseUrl +'/api/order/'+orderId)
                    .then(response => {
                        dispatch('getOrders')
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Pasūtījums dzēsts'
                        })
                        vm.$Progress.finish()
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        addOrderItems({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/order_item/' + state.order.id , payload)
                    .then(response => {
                        dispatch('hideEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Ieraksts izveidots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izveidot'
                            })
                        }
                        reject(error)
                    })
            })
        },

        deleteOrderItem({ commit, rootState, dispatch, state }, orderItemId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.delete(baseUrl +'/api/' + state.order.id + '/order_item/' + orderItemId )
                    .then(response => {
                        dispatch('hideEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Ieraksts dzēsts'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās dzēst'
                            })
                        }
                        reject(error)
                    })
            })
        },

        deleteOrderServiceProvider({ commit, rootState, dispatch, state }, orderServiceProviderId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.delete(baseUrl +'/api/order_service_providers/' + orderServiceProviderId )
                    .then(response => {
                        dispatch('hideEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Ieraksts dzēsts'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās dzēst'
                            })
                        }
                        reject(error)
                    })
            })
        },

        deleteOrderInvoice({ commit, rootState, dispatch, state }, orderInvoiceId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.delete(baseUrl +'/api/invoices/' + orderInvoiceId )
                    .then(response => {
                        dispatch('hideEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Ieraksts dzēsts'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās dzēst'
                            })
                        }
                        reject(error)
                    })
            })
        },

        showEditOrderDetailsForm({commit}){
            commit('showEditOrderDetailsForm')
        },
        showEditOrderCustomerForm({commit}){
            commit('showEditOrderCustomerForm')
        },
        showEditOrderFuneralForm({commit}){
            commit('showEditOrderFuneralForm')
        },
        showAddFuneralForm({commit}){
            commit('showAddFuneralForm')
        },
        showEditDecasedPersonForm({commit}){
            commit('showEditDecasedPersonForm')
        },
        showAddOrderServiceProviderForm({commit}){
            commit('showAddOrderServiceProviderForm')
        },
        showAddDecasedPersonForm({commit}){
            commit('showAddDecasedPersonForm')
        },
        showAddOrderItemsForm({commit}){
            commit('showAddOrderItemsForm')
        },
        showAddOrderProductsForm({commit}){
            commit('showAddOrderProductsForm')
        },
        hideEditForms({commit, dispatch, state}){
            dispatch('getSingleOrder', state.order.id)
            commit('hideEditForms')
        },

    },
    getters: {
        orders(state){
            return state.orders
        },
        order(state) {
            return state.order
        },
        showEditOrderDetailsForm(state){
            return state.showEditOrderDetailsForm
        },
        showEditOrderCustomerForm(state){
            return state.showEditOrderCustomerForm
        },
        showEditOrderFuneralForm(state){
            return state.showEditOrderFuneralForm
        },
        showAddFuneralForm(state){
            return state.showAddFuneralForm
        },
        showAddOrderServiceProviderForm(state){
            return state.showAddOrderServiceProviderForm
        },
        showEditDecasedPersonForm(state){
            return state.showEditDecasedPersonForm
        },
        showAddDecasedPersonForm(state){
            return state.showAddDecasedPersonForm
        },
        showAddOrderItemsForm(state){
            return state.showAddOrderItemsForm
        },
        showAddOrderProductsForm(state){
            return state.showAddOrderProductsForm
        },
        editOrderInputData(state){
            return state.editOrderInputData
        },
        addOrderServiceProviderInputData(state){
            return state.addOrderServiceProviderInputData
        },
        products(state){
            return state.products
        }
    }
}

export default orders