<template>
  <v-container>

    <v-row align="center">
      <v-col cols="12" sm="4">
        <h1 class="heading blue-grey--text ">Ceremoniju vietas</h1>
      </v-col>

      <v-spacer/>

      <v-col cols="10" sm="6" md="4">
        <v-text-field
                @input="searchFuneralLocations"
                v-model="search"
                append-icon="mdi-magnify"
                label="Meklēt"
                color="blue-grey darken-1"
                class=""
       />
      </v-col>

      <v-col v-if="!showForm" cols="2" sm="1" >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"  @click="showAddFuneralPlaceForm" small fab text color="blue-grey darken-1" slot="activator" >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Pievienot ceremonijas vietu</span>
        </v-tooltip>
      </v-col>

      <v-col v-if="showForm" cols="2" sm="1" >
        <v-btn  @click="hideAddFuneralForm" small outlined fab text color="blue-grey darken-1" slot="activator">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-col>

    </v-row>

    <add-funeral-locations-place v-if="showForm"/>

    <template v-if="funeralLocationsPlaces">
      <v-card v-for="funeralLocationsPlace in funeralLocationsPlaces.data" :key="funeralLocationsPlace.id" class="py-0 px-4 mb-2" outlined>
        <v-row >
          <v-col cols="6" sm="3">
            <div class="caption grey--text">Nosaukums</div>
            <div class="font-weight-light">{{funeralLocationsPlace.name}}</div>
          </v-col>


          <v-col cols="6" sm="3">

          </v-col>


            <v-row justify="center" justify-md="end" align-content="center">
              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                  <v-btn v-on="on" @click="editItem(funeralLocationsPlace)" text small slot="activator">
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  </template>
                  <span>Labot ceremonijas vietu</span>
                </v-tooltip>
              </v-col>

              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="deleteFuneralLocationsPlace(funeralLocationsPlace.id)" text small slot="activator" class="align-content-sm-center">
                    <v-icon color="grey">mdi-delete</v-icon>
                  </v-btn>
                  </template>
                  <span>Dzēst ceremonijas vietu</span>
                </v-tooltip>
              </v-col>
            </v-row>

        </v-row>
      </v-card>

      <v-row justify-content="center">
        <v-col v-if="funeralLocationsPlaces.meta.last_page > 1" >
          <v-pagination
                  v-model="funeralLocationsPlaces.meta.current_page"
                  :length="funeralLocationsPlaces.meta.last_page"
                  :total-visible="5"
                  color="blue-grey "
                  @input="onPageChange"
          />
        </v-col>
      </v-row>
    </template>

    <v-dialog v-model="showEditModal" max-width="700" persistent>
      <edit-funeral-locations-place/>
    </v-dialog>

  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AddFuneralLocationsPlace from "../components/AddFuneralLocationsPlace";
  import EditFuneralLocationsPlace from "../components/EditFuneralLocationsPlace";

  export default {
    name: "FuneralLocations",
    components: {EditFuneralLocationsPlace, AddFuneralLocationsPlace},
    data: () => ({
    }),
    mounted() {
      this.$store.dispatch('hideEditModal')
      this.$store.dispatch('hideAddFuneralLocationsPlaceForm')
      this.$store.dispatch('resetSearch')
      this.$store.dispatch('getFuneralLocationsPlaces')
    },
    computed: {
      ...mapGetters({
        funeralLocationsPlaces: 'allFuneralLocationsPlaces',
        search: 'search',
        showForm: 'showAddFuneralLocationsPlaceForm',
        showEditModal: 'showEditModal',
      })

    },
    methods: {
      editItem(item){
        this.$store.dispatch('showEditModal', item)
      },
      searchFuneralLocations(value){
        this.$store.dispatch('changeSearch', value)
        this.$store.dispatch('getFuneralLocationsPlaces')
      },
      onPageChange() {
        this.$store.dispatch('getFuneralLocationsPlaces', this.funeralLocationsPlaces.meta.current_page);
      },
      showAddFuneralPlaceForm(){
        this.$store.dispatch('showAddFuneralLocationsPlaceForm')
      },
      hideAddFuneralForm(){
        this.$store.dispatch('hideAddFuneralLocationsPlaceForm')
      },
      deleteFuneralLocationsPlace(funeralLocationsPlaceId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteFuneralLocationsPlace', funeralLocationsPlaceId)
        }
      }



    }
  }
</script>
