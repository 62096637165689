<template>
    <v-row justify="center" no-gutters class="px-2">
        <v-col cols="12">
            <v-form ref="form">
                <v-row no-gutters class="py-4" v-if="invoice" align="center">
                    <v-col cols="12" md="3">
                        <v-menu
                                v-model="showPicker"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        color="blue-grey darken-1"
                                        class="px-4"
                                        v-model="invoice.invoice_date"
                                        label="Pasūtījuma datums"
                                        hide-details
                                        readonly
                                        v-on="on"
                                />
                            </template>
                            <v-date-picker v-model="invoice.invoice_date" color="blue-grey lighten-1"  @input="showPicker = false"/>
                        </v-menu>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-text-field
                                v-model="invoice.number"
                                hide-details
                                label="Rēķina nr."
                                color="grey"
                                class=" px-4"
                        />
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-text-field
                                v-model="invoice.agency_costs"
                                hide-details
                                label="Aģentūras izmaksas"
                                color="grey"
                                class=" px-4"
                        />
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-select
                                color="blue-grey darken-1"
                                class="px-4"
                                label="Apmaksas veids"
                                v-model="invoice.payment_method"
                                :items="invoice.payment_methods"
                                item-text="display_name"
                                hide-details
                                return-object
                        />
                    </v-col>

                    <v-row row justify="center" justify-md="end" no-gutters class="py-2">
                        <v-btn  text @click="hideEditForm" dark color="deep-orange lighten-1">Atcelt</v-btn>
                        <v-btn  text @click="submit" dark color="blue accent-3">Labot</v-btn>
                    </v-row>
                </v-row>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>

    import {mapGetters} from "vuex";

    export default {
        name: "edit-invoice-details",
        data: () => ({
            showPicker: false,
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),
        mounted() {
        },
        computed: {
            ...mapGetters({
                invoice: 'invoice',
                inputData: 'invoiceEditInputData',
            })
        },
        methods: {
            hideEditForm(){
                this.$store.dispatch('hideInvoiceEditForms')
            },
            submit(){
                this.$Progress.start()
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('updateInvoice', {
                        invoice_date: this.invoice.invoice_date,
                        number: this.invoice.number,
                        payment_method_id: this.invoice.payment_method.id,
                        agency_costs: this.invoice.agency_costs,
                    })
                }  else {
                    this.$Progress.fail()
                }
            },
        }
    }
</script>

<style scoped>

</style>