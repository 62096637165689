<template>

  <v-container v-if="item">
    <v-row align="center">
      <v-col >
        <h1 class="heading blue-grey--text ">Labot preci {{item.name}}</h1>
      </v-col>
    </v-row>

      <v-row  v-if="inputData">
        <v-col cols="12" lg="6" >
          <v-card >
            <v-card-text >
              <v-form ref="form" v-if="item">
                <v-row no-gutters class="mt-10">
                  <v-col cols="12">
                    <v-select
                            class="px-3 py-1"
                            v-model="item.category"
                            color="blue-grey darken-1"
                            item-text="name"
                            :items="inputData.categories"
                            :rules="formRules"
                            label="Kategorija"
                            return-object
                            outlined
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                            class="px-3 py-1"
                            v-model="item.name"
                            label="Nosaukums"
                            color="blue-grey darken-1"
                            :rules="formRules"
                            outlined
                            required>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                            class="px-3 py-1"
                            v-model="item.price"
                            label="Bāzes cena EUR"
                            color="blue-grey darken-1"
                            outlined
                    >
                    </v-text-field>
                  </v-col>

                  <template v-if="item.itemDetail">
                    <v-col cols="12" sm="6" >
                      <v-text-field
                              class="pa-3"
                              v-model="item.itemDetail.model"
                              label="Modelis"
                              color="blue-grey darken-1"
                              outlined>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field
                              class="pa-3"
                              v-model="item.itemDetail.size"
                              label="Izmērs"
                              color="blue-grey darken-1"
                              outlined>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                              class="pa-3"
                              v-model="item.itemDetail.description"
                              label="Apraksts"
                              color="blue-grey darken-1"
                              outlined>
                      </v-text-field>
                    </v-col>
                  </template>
                </v-row>

                <v-row row justify="center" justify-md="end" no-gutters class="mb-4">
                  <v-btn  text @click="goBack" dark color="deep-orange lighten-1">Atcelt</v-btn>
                  <v-btn  text @click="submit" dark color="blue">Labot</v-btn>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="6">
          <v-card>
            <template v-if="item.image">
              <v-img :src="item.image.url"/>
              <v-card-actions>
                <v-spacer/>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="deleteImage" text small slot="activator" class="align-content-sm-center">
                      <v-icon color="grey">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Dzēst bildi</span>
                </v-tooltip>
              </v-card-actions>
            </template>

            <v-card-text v-else>
              <h2 class="blue-grey--text">Pievienot bildi</h2>
              <v-file-input v-model="image" class="pt-10" chips label="Izvēlies failu"/>
            </v-card-text>
            <v-row row justify="center" justify-md="end" no-gutters class="mb-4" v-if="!item.image">
              <v-btn class="mb-2"  text @click="uploadImage" dark color="green" :disabled="!image">Pievienot</v-btn>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "edit-product",
    data: () => ({
      'categoryTypeId': 2,
      image: null,
      formRules: [
        v => !!v || 'Šis lauks ir obligāts',
      ],
    }),
    mounted() {
      this.$store.dispatch('getEditableItem', {
        apiLink: 'products',
        itemId: this.$route.params.productId
      })
      this.$store.dispatch('getItemInputData', this.categoryTypeId)
    },
    computed: {
      ...mapGetters({
        item: 'editableItem',
        inputData: 'itemInputData',
      }),
    },
    methods: {

      goBack(){
        this.$store.dispatch('hideEditModal')
        this.$router.push({ path: '/products' })
      },
      uploadImage(){
        this.$store.dispatch('uploadItemImage', {
          image: this.image,
          itemId: this.item.id,
          apiLink: 'products',
        })
      },
      deleteImage(){
        if(confirm('Vai esi drošs?')) {
          this.image = null
          this.$store.dispatch('deleteImage', {
            imageId: this.item.image.id,
            itemId: this.item.id,
            apiLink: 'products',
          })
        }
      },
      submit(){
        this.$Progress.start()
        if (this.$refs.form.validate()) {
          this.$store.dispatch('updateProduct', {
            data: {
              item: {
                name: this.item.name,
                price: this.item.price,
                category_id: this.item.category.id,
              },
              itemDetail: {
                model: this.item.itemDetail.model,
                size: this.item.itemDetail.size,
                description: this.item.itemDetail.description,
              }
            },
            categoryTypeId: this.categoryTypeId
          })
        }  else {
          this.$Progress.fail()
        }
      },
    }
  }
</script>
