<template>
  <v-container>

    <v-row align="center">
      <v-col sm="2">
        <h1 class="heading blue-grey--text ">Pasūtījumi</h1>
      </v-col>

      <v-spacer/>

      <v-col cols="10" sm="6" md="4">
        <v-text-field
                @input="searchOrders"
                v-model="search"
                append-icon="mdi-magnify"
                label="Meklēt"
                color="blue-grey darken-1"
                class=""
        />
      </v-col>

      <v-col cols="2" sm="1" >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :to="{ name: 'orders.create' }" small fab text color="blue-grey darken-1" slot="activator" >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Pievienot pasūtījumu</span>
        </v-tooltip>
      </v-col>

    </v-row>
    <template v-if="orders">
      <v-card v-for="order in orders.data" :key="order.id" class="py-0 px-4 mb-2" outlined>
        <v-row >
          <v-col cols="6" sm="2">
            <div class="caption grey--text">Datums</div>
            <div class="font-weight-light">{{order.date}}</div>
          </v-col>

          <v-col cols="6" sm="2">
            <div class="caption grey--text">Mirušais</div>
            <div class="font-weight-light">{{order.decased_person}}</div>
          </v-col>

          <v-col cols="6" sm="2">
            <div class="caption grey--text">Summa EUR</div>
            <div class="font-weight-light">{{order.total}}</div>
          </v-col>

          <v-col cols="6" sm="2">
            <div class="caption grey--text">Filiāle</div>
            <div class="font-weight-light">{{order.branch}}</div>
          </v-col>

            <v-col cols="6" sm="2" >
                <div v-if="order.user" class="caption grey--text">Izveidoja</div>
                <div v-if="order.user" class="font-weight-light">{{order.user.name}}</div>
            </v-col>

          <v-row justify="center" align-content="center">
            <v-col cols="2">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon :to="`/orders/${order.id}`" text small slot="activator">
                    <v-icon color="grey">mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span>Skatīt pasūtījumu</span>
              </v-tooltip>
            </v-col>

            <v-col cols="2">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="deleteOrder(order.id)" text small slot="activator" class="align-content-sm-center">
                    <v-icon color="grey">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Dzēst kategoriju</span>
              </v-tooltip>
            </v-col>
          </v-row>

        </v-row>
      </v-card>

      <v-row justify-content="center">
        <v-col v-if="orders.meta.last_page > 1" >
          <v-pagination
                  v-model="orders.meta.current_page"
                  :length="orders.meta.last_page"
                  :total-visible="5"
                  color="blue-grey "
                  @input="onPageChange"
          />
        </v-col>
      </v-row>
    </template>


  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "Orders",
    data: () => ({
    }),
    mounted() {
      this.$store.dispatch('resetSearch')
      this.$store.dispatch('getOrders')
    },
    computed: {
      ...mapGetters({
        orders: 'orders',
        search: 'search',
      })
    },
    methods: {
      searchOrders(value){
        this.$store.dispatch('changeSearch', value)
        this.$store.dispatch('getOrders')
      },
      onPageChange() {
        this.$store.dispatch('getOrders', this.orders.meta.current_page);
      },
      deleteOrder(orderId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteOrder', orderId)
        }
      }



    }
  }
</script>
