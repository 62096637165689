<template>
    <v-card>
        <v-card-title class="headline blue-grey--text">Labot {{item.name}}</v-card-title>

        <v-card-text v-if="inputData">

            <v-form ref="form" v-if="item">
                <v-row no-gutters class="mt-10">
                    <v-col cols="12">
                        <v-select
                                class="px-3 py-1"
                                v-model="item.category"
                                color="blue-grey darken-1"
                                item-text="name"
                                :items="inputData.categories"
                                :rules="formRules"
                                label="Kategorija"
                                return-object
                                outlined
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                                class="px-3 py-1"
                                v-model="item.name"
                                label="Nosaukums"
                                color="blue-grey darken-1"
                                :rules="formRules"
                                outlined
                                required>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-text-field
                                class="px-3 py-1"
                                v-model="item.price"
                                label="Bāzes cena EUR"
                                color="blue-grey darken-1"
                                outlined
                                required>
                        </v-text-field>
                    </v-col>

                </v-row>

                <v-row row justify="center" justify-md="end" no-gutters class="mb-4">
                    <v-btn  text @click="hideEditModal" dark color="deep-orange lighten-1">Atcelt</v-btn>
                    <v-btn  text @click="submit" dark color="blue">Labot</v-btn>
                </v-row>
            </v-form>


        </v-card-text>

    </v-card>

</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "edit-category-item",
        props: ['categoryTypeId'],
        data: () => ({
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),
        mounted() {
            this.$store.dispatch('getItemInputData', this.categoryTypeId)
        },
        computed: {
            ...mapGetters({
                item: 'editableItem',
                inputData: 'itemInputData',
            })
        },
        methods: {
            hideEditModal(){
                this.$store.dispatch('hideEditModal')
            },
            submit(){
                this.$Progress.start()
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('updateCategoryItem', {
                        data: {
                            name: this.item.name,
                            category_id: this.item.category.id,
                            price: this.item.price
                        },
                        categoryTypeId: this.categoryTypeId

                    })
                }  else {
                    this.$Progress.fail()
                }
            },
        }
    }
</script>

<style scoped>

</style>