<template>
    <v-card flat class="mb-3" outlined>
        <v-row justify="center" no-gutters>
            <v-col cols="12" sm="8">
                <v-card flat class="mt-3 px-2" v-if="inputData">
                    <v-form ref="form">
                        <v-row no-gutters class="mt-10">
                            <v-col cols="12" sm="6">
                                <v-text-field
                                        dense
                                        class="px-3 py-1"
                                        v-model="name"
                                        label="Lietotāja vārds"
                                        color="blue-grey darken-1"
                                        :rules="formRules"
                                        outlined
                                        required>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-text-field
                                        dense
                                        class="px-3 py-1"
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="showPassword ? 'text' : 'password'"
                                        @click:append="showPassword = !showPassword"
                                        v-model="password"
                                        label="Parole"
                                        color="blue-grey darken-1"
                                        :rules="formRules"
                                        outlined
                                        required
                                        :error-messages="errorMessages.password"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-text-field
                                        dense
                                        class="px-3 py-1"
                                        v-model="email"
                                        label="E-pasts"
                                        color="blue-grey darken-1"
                                        :rules="formRules"
                                        outlined
                                        required
                                        :error-messages="errorMessages.email"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-select
                                        dense
                                        class="px-3 py-1"
                                        v-model="role"
                                        color="blue-grey darken-1"
                                        item-text="display_name"
                                        item-value="id"
                                        :items="inputData.roles"
                                        :rules="formRules"
                                        label="Loma"
                                        outlined
                                />
                            </v-col>

                            <v-col cols="12" >
                                <v-select
                                    dense
                                    class="px-3 py-1"
                                    v-model="branches"
                                    color="blue-grey darken-1"
                                    item-text="name"
                                    item-value="id"
                                    :items="inputData.branches"
                                    label="Filiāles"
                                    multiple
                                    :error-messages="errorMessages.branches"
                                    small-chips
                                    deletable-chips
                                    outlined
                                />
                            </v-col>
                        </v-row>

                        <v-row row justify="center" justify-md="end" no-gutters class="mb-4">
                            <v-btn  text @click="hideAddUserForm" dark color="deep-orange lighten-1">Atcelt</v-btn>
                            <v-btn  text @click="submit" dark color="green accent-4">Pievienot</v-btn>
                        </v-row>

                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "add-user",
        data: () => ({
            showPassword: false,
            name: '',
            email: '',
            password: '',
            role: 2,
            branches: [],
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),
        mounted() {
            this.$store.dispatch('getUserInputData')
        },
        computed: {
            ...mapGetters({
                inputData: 'userInputData',
                errorMessages: 'userErrorMessages'
            })
        },
        methods: {
            hideAddUserForm(){
                this.$store.dispatch('clearUserErrorMessages')
                this.$store.dispatch('hideAddUserForm')
            },
            submit(){
                this.$Progress.start()
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('createNewUser', {
                        name: this.name,
                        password: this.password,
                        email: this.email,
                        role_id: this.role,
                        branches: this.branches
                    })
                }  else {
                    this.$Progress.fail()
                }
            },
        }
    }
</script>

<style scoped>

</style>