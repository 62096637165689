<template>
  <v-container>

    <v-row align="center">
      <v-col cols="12" sm="4">
        <h1 class="heading blue-grey--text ">Apglabāšanas vietas</h1>
      </v-col>

      <v-spacer/>

      <v-col cols="10" sm="6" md="4">
        <v-text-field
                @input="searchFuneralLocations"
                v-model="search"
                append-icon="mdi-magnify"
                label="Meklēt"
                color="blue-grey darken-1"
                class=""
       />
      </v-col>

      <v-col v-if="!showForm" cols="2" sm="1" >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"  @click="showAddFuneralForm" small fab text color="blue-grey darken-1" slot="activator" >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Pievienot apglabāšanas vietu</span>
        </v-tooltip>
      </v-col>

      <v-col v-if="showForm" cols="2" sm="1" >
        <v-btn  @click="hideAddFuneralForm" small outlined fab text color="blue-grey darken-1" slot="activator">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-col>

    </v-row>

    <add-funeral-location v-if="showForm"/>

    <template v-if="funeralLocations">
      <v-card v-for="funeralLocation in funeralLocations.data" :key="funeralLocation.id" class="py-0 px-4 mb-2" outlined>
        <v-row >
          <v-col cols="6" sm="3">
            <div class="caption grey--text">Nosaukums</div>
            <div class="font-weight-light">{{funeralLocation.name}}</div>
          </v-col>


          <v-col cols="6" sm="3">
            <div class="caption grey--text">Adrese</div>
            <div class="font-weight-light">{{funeralLocation.address}}</div>
          </v-col>


            <v-row justify="center" justify-md="end" align-content="center">
              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                  <v-btn v-on="on" @click="editItem(funeralLocation)" text small slot="activator">
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  </template>
                  <span>Labot apglabāšanas vietu</span>
                </v-tooltip>
              </v-col>

              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="deleteFuneralLocation(funeralLocation.id)" text small slot="activator" class="align-content-sm-center">
                    <v-icon color="grey">mdi-delete</v-icon>
                  </v-btn>
                  </template>
                  <span>Dzēst apglabāšanas vietu</span>
                </v-tooltip>
              </v-col>
            </v-row>

        </v-row>
      </v-card>

      <v-row justify-content="center">
        <v-col v-if="funeralLocations.meta.last_page > 1" >
          <v-pagination
                  v-model="funeralLocations.meta.current_page"
                  :length="funeralLocations.meta.last_page"
                  :total-visible="5"
                  color="blue-grey "
                  @input="onPageChange"
          />
        </v-col>
      </v-row>
    </template>

    <v-dialog v-model="showEditModal" max-width="700" persistent>
      <edit-funeral-location/>
    </v-dialog>

  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AddFuneralLocation from "../components/AddFuneralLocation";
  import EditFuneralLocation from "../components/EditFuneralLocation";

  export default {
    name: "FuneralLocations",
    components: {EditFuneralLocation, AddFuneralLocation},
    data: () => ({
    }),
    mounted() {
      this.$store.dispatch('hideEditModal')
      this.$store.dispatch('hideAddFuneralLocationForm')
      this.$store.dispatch('resetSearch')
      this.$store.dispatch('getFuneralLocations')
    },
    computed: {
      ...mapGetters({
        funeralLocations: 'allFuneralLocations',
        search: 'search',
        showForm: 'showAddFuneralLocationForm',
        showEditModal: 'showEditModal',
      })

    },
    methods: {
      editItem(item){
        this.$store.dispatch('showEditModal', item)
      },
      searchFuneralLocations(value){
        this.$store.dispatch('changeSearch', value)
        this.$store.dispatch('getFuneralLocations')
      },
      onPageChange() {
        this.$store.dispatch('getFuneralLocations', this.funeralLocations.meta.current_page);
      },
      showAddFuneralForm(){
        this.$store.dispatch('showAddFuneralLocationForm')
      },
      hideAddFuneralForm(){
        this.$store.dispatch('hideAddFuneralLocationForm')
      },
      deleteFuneralLocation(funeralLocationId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteFuneralLocation', funeralLocationId)
        }
      }



    }
  }
</script>
