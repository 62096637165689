import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import categories from "./categories";
import customers from "./customers";
import serviceProviders from "./serviceProviders";
import orders from "./orders";
import orderCreate from "./orderCreate";
import users from "./users";
import items from "./items";
import funeralLocations from "./funeralLocations";
import funeralLocationsPlaces from "./funeralLocationsPlaces";
import invoices from "./invoices";

Vue.use(Vuex)

var baseUrl = process.env.VUE_APP_BASE_URL

export default new Vuex.Store({
  modules: {
    auth,
    categories,
    customers,
    orders,
    orderCreate,
    serviceProviders,
    users,
    items,
    funeralLocations,
    funeralLocationsPlaces,
    invoices
  },
  state: {
    search:'',
    errorStatus: false,
    errorMessage: null,
    snackbar: false,
    snackbarColor: '',
    snackbarTimeout: 5000,
    snackbarText: '',
    showEditModal: false,
    showAddModal: false,
    editableItem: {}
  },

  mutations: {
    setSearch(state, payload){
      state.search = payload
    },
    setSnackbar(state, payload){
      state.snackbar = true
      state.snackbarColor = payload.color
      state.snackbarText = payload.text
    },
    setErrorMessage(state, message){
      state.errorStatus = true
      state.errorMessage = message
    },
    resetErrors(state){
      state.errorStatus = false
      state.errorMessage = null
    },
    clearSnackbar(state){
      state.snackbar = false
      state.snackbarColor = ''
      state.snackbarText = ''
    },
    showAddModal(state){
      state.showAddModal = true
    },
    hideAddModal(state){
      state.showAddModal = false
    },
    showEditModal(state, item){
      state.editableItem = item
      state.showEditModal = true
    },
    hideEditModal(state){
      state.showEditModal = false
      state.editableItem = {}
    },
    setEditableItem(state, item){
      state.editableItem = item
    }
  },

  actions: {
    resetSearch(context){
      const defaultSearch = ''
      context.commit('setSearch', defaultSearch)
    },
    changeSearch(context, payload){
      context.commit('setSearch', payload)
    },
    resetErrors({commit}){
      commit('resetErrors')
    },
    setSnackbar({commit}, payload){
      commit('setSnackbar', payload)
      setTimeout(() => { commit('clearSnackbar') }, 5000);
    },
    clearSnackbar({commit}){
      commit('clearSnackbar')
    },
    showEditModal({commit} , item){
      commit('showEditModal', item)
    },
    showAddModal({commit}){
      commit('showAddModal')
    },
    hideAddModal({commit}){
      commit('hideAddModal')
    },
    hideEditModal({commit}){
      commit('hideEditModal')
    },
    clearEditableItem({commit}){
      commit('clearEditableItem')
    },
    setEditableItem({commit}, item){
      commit('setEditableItem', item)
    }


  },
  getters: {
    search(state){
      return state.search
    },
    errorStatus(state){
      return state.errorStatus
    },
    errorMessage(state){
      return state.errorMessage
    },
    snackbar(state){
      return state.snackbar
    },
    snackbarColor(state){
      return state.snackbarColor
    },
    snackbarTimeout(state){
      return state.snackbarTimeout
    },
    snackbarText(state){
      return state.snackbarText
    },
    showEditModal(state){
      return state.showEditModal
    },
    showAddModal(state){
      return state.showAddModal
    },
    editableItem(state){
      return state.editableItem
    },
  }
})