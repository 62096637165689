<template>
  <v-container>

    <template v-if="order">
      <v-row align="center" justify="space-between" class="px-4 py-4">
        <h1 class="blue-grey--text ">Pasūtījums nr. {{order.id}}</h1>
        <v-btn text class="blue-grey--text" @click="goBack">
          Atpakaļ
        </v-btn>
      </v-row>

        <v-row no-gutters>
          <!--  Order details        -->
          <v-col cols="12" class="pa-4">
            <v-card height="100%">
              <div class="px-2 pt-2">
                <v-row no-gutters class="mb-2 px-2">
                  <div class=" px-2">
                    <span class="title font-weight-light ">Pasūtījuma detaļas</span>
                  </div>
                  <v-spacer/>
                  <v-tooltip top v-if="!showEditOrderDetailsForm">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="showEditForm" text small slot="activator">
                        <v-icon color="grey">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Labot pasūtījuma detaļas</span>
                  </v-tooltip>

                  <v-tooltip top v-if="showEditOrderDetailsForm">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="hideEditForm" text small slot="activator">
                        <v-icon color="grey">mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span>Atcelt</span>
                  </v-tooltip>
                </v-row>
                <hr>
              </div>
            <v-row class="px-4" no-gutters v-if="!showEditOrderDetailsForm">
              <v-col cols="6" md="4" lg="3" class="pa-2">
                <div class="caption grey--text">Pasūtījuma nr</div>
                <div class="font-weight-light">{{order.id}}</div>
              </v-col>
              <v-col cols="6" md="4" lg="3" class="pa-2">
                <div class="caption grey--text">Filiāle</div>
                <div class="font-weight-light">{{order.branch.name}}</div>
              </v-col>
              <v-col cols="6" md="4" lg="3" class="pa-2">
                <div class="caption grey--text">Pasūtījuma datums</div>
                <div class="font-weight-light">{{order.order_date}}</div>
              </v-col>
              <v-col cols="6" md="3" class="pa-2">
                <div class="caption grey--text">Aģentūras %</div>
                <div class="font-weight-light">{{order.agency_rate}}</div>
              </v-col>
              <v-col cols="6" md="3" class="pa-2">
                <div class="caption grey--text">Kopā EUR</div>
                <div class="font-weight-light">{{order.total}}</div>
              </v-col>
              <v-col cols="6" md="3" class="pa-2">
                <div class="caption grey--text">PVN EUR</div>
                <div class="font-weight-light">{{order.vat}}</div>
              </v-col>
              <v-col cols="6" md="3" class="pa-2">
                <div class="caption grey--text">Summa EUR</div>
                <div class="font-weight-light">{{order.total_with_vat}}</div>
              </v-col>
              <v-col cols="6" md="3" class="pa-2" v-if="order.advance_payment > 0">
                <div class="caption grey--text">Ananss EUR</div>
                <div class="font-weight-light">{{order.advance_payment}}</div>
              </v-col>
              <template v-if="order.social_benefit > 0">
                <v-col cols="6" md="3" class="pa-2">
                  <div class="caption grey--text">VSAA EUR</div>
                  <div class="font-weight-light">{{order.social_benefit}}</div>
                </v-col>
                <v-col cols="6" md="4" lg="3" class="pa-2">
                  <div class="caption grey--text">Apmaksai pēc VSAA EUR</div>
                  <div class="font-weight-light">{{order.total_after_benefits}}</div>
                </v-col>
              </template>

                <v-col cols="6" md="3" class="pa-2" v-if="order.user">
                    <div class="caption grey--text">Izveidoja</div>
                    <div class="font-weight-light">{{order.user.name}}</div>
                </v-col>

                <v-col cols="12" v-if="order.notes">
                    <v-card outlined flat  class="mb-2">
                        <v-row no-gutters class="px-2">
                            <div class=" px-2">
                                <span class="subtitle-1 font-weight-light ">Piezīmes:</span>
                            </div>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row no-gutters >
                            <v-col cols="12" class="px-4 py-2">
                                <div v-html="order.notes"/>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

            </v-row>

            <edit-order-details v-if="showEditOrderDetailsForm"/>
            </v-card>
          </v-col>
          <!--  Customer        -->
          <v-col cols="12" class="pa-4">
            <v-card height="100%">
              <div class="px-2 pt-2">
                <v-row no-gutters class="mb-2 px-2">
                  <div class=" px-2">
                    <span class="title font-weight-light indigo--text text--lighten-1">Pasūtītājs</span>
                  </div>

                  <v-spacer/>
              <v-tooltip top v-if="!showEditOrderCustomerForm">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="showCustomerForm" text small slot="activator">
                    <v-icon color="indigo lighten-1">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Labot pasūtītāju</span>
              </v-tooltip>

              <v-tooltip top v-if="showEditOrderCustomerForm">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="hideEditForm" text small slot="activator">
                    <v-icon color="indigo lighten-1">mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Atcelt</span>
              </v-tooltip>
            </v-row>
                <v-divider class="indigo lighten-1"/>
              </div>
            <v-row class="px-4" no-gutters v-if="!showEditOrderCustomerForm">
              <v-col cols="6" md="4" lg="3" class="pa-2">
                <div class="caption grey--text">Nosaukums</div>
                <div class="font-weight-light">{{order.customer.name}}</div>
              </v-col>
              <v-col cols="6" md="4" lg="3" class="pa-2">
                <div class="caption grey--text">Reģ. nr / PK</div>
                <div class="font-weight-light">{{order.customer.reg_nr ? order.customer.reg_nr : "Nav norādīts"}}</div>
              </v-col>
              <v-col cols="6" md="4" lg="3" class="pa-2">
                <div class="caption grey--text">Telefons</div>
                <div class="font-weight-light">{{order.customer.phone ? order.customer.phone : "Nav norādīts"}}</div>
              </v-col>
              <v-col cols="6" md="4" lg="3" class="pa-2">
                <div class="caption grey--text">E-pasts</div>
                <div class="font-weight-light">{{order.customer.email ? order.customer.email : "Nav norādīts"}}</div>
              </v-col>
              <v-col cols="12" md="8" class="pa-2">
                <div class="caption grey--text">Adrese</div>
                <div class="font-weight-light">{{order.customer.address ? order.customer.address : "Nav norādīts"}}</div>
              </v-col>
            </v-row>

            <edit-order-customer v-if="showEditOrderCustomerForm"/>
            </v-card>
          </v-col>
        </v-row>

        <!--  Invoice        -->
        <v-row no-gutters>
          <v-col cols="12" class="pa-4" >
            <v-card>
              <div class="px-2 pt-2">
                <v-row no-gutters class="mb-2 px-2">
                  <div class=" px-2">
                    <span class="title font-weight-light teal--text text--darken-1">Rēķins</span>
                  </div>
                  <v-spacer/>
                  <v-tooltip top v-if="order.invoice">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="streamInvoicePDF(order.uuid)" text small slot="activator">
                        <v-icon color="grey">mdi-printer</v-icon>
                      </v-btn>
                    </template>
                    <span>Drukāt rēķinu</span>
                  </v-tooltip>

                  <v-tooltip top v-if="order.invoice">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="viewInvoice" text small slot="activator">
                        <v-icon color="teal darken-1">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Labot rēķinu</span>
                  </v-tooltip>

                  <v-tooltip top v-if="order.invoice">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="deleteOrderInvoice(order.invoice.id)" text small slot="activator">
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Dzēst rēķinu</span>
                  </v-tooltip>
                </v-row>
                <hr>
              </div>
              <v-row class="px-4" no-gutters v-if="order.invoice">
                <v-col cols="6" md="3" class="pa-2">
                  <div class="caption grey--text">Rēķina nr</div>
                  <div class="font-weight-light">{{order.invoice.number ? order.invoice.number : order.invoice.id}}</div>
                </v-col>
                <v-col cols="6" md="3" class="pa-2">
                  <div class="caption grey--text">Izrakstīts</div>
                  <div class="font-weight-light">{{order.invoice.invoice_date}}</div>
                </v-col>
                <v-col cols="4" md="2" class="pa-2">
                  <div class="caption grey--text">Kopā EUR</div>
                  <div class="font-weight-light">{{(+order.invoice.total + +order.invoice.agency_costs)}}</div>
                </v-col>
                <v-col cols="4" md="2" class="pa-2">
                  <div class="caption grey--text">PVN EUR</div>
                  <div class="font-weight-light">{{order.invoice.vat}}</div>
                </v-col>
                <v-col cols="4" md="2" class="pa-2">
                  <div class="caption grey--text">Summa EUR</div>
                  <div class="font-weight-light">{{order.invoice.total_with_vat}}</div>
                </v-col>
              </v-row>

              <v-row v-else no-gutters justify="center" class="px-4 mt-3 font-weight-light" >
                <v-col cols="12" class="text-center" >
                  Rēķins nav izraksttīts
                </v-col>
                <v-btn text class="blue-grey--text" @click="createInvoice">
                  Izrakstīt rēķinu
                </v-btn>
              </v-row>
            </v-card>
          </v-col>
        </v-row>


        <v-row no-gutters>
          <!--  Funeral        -->
          <v-col cols="12" md="6" class="pa-4" >
            <v-card height="100%">
              <div class="px-2 pt-2">
                <v-row no-gutters class="mb-2 px-2">
                  <div class=" px-2">
                    <span class="title font-weight-light teal--text text--lighten-1">Bēres</span>
                  </div>

                  <v-spacer/>

                <template v-if="order.funeral">
                <v-tooltip top v-if="!showEditOrderFuneralForm">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="showFuneralForm" text small slot="activator">
                      <v-icon color="teal lighten-1">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Labot bēres</span>
                </v-tooltip>

                <v-tooltip top v-if="showEditOrderFuneralForm">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="hideEditForm" text small slot="activator">
                      <v-icon color="teal lighten-1">mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Atcelt</span>
                </v-tooltip>

              <v-tooltip top >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="deleteFuneral(order.funeral.id)" text small slot="activator">
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Dzēst bēres</span>
              </v-tooltip>
              </template>
              </v-row>
              <v-divider class="teal lighten-1"/>
              </div>

              <template v-if="!showEditOrderFuneralForm && order.funeral">
                <v-row class="px-4" no-gutters>
                  <v-col cols="6" class="pa-2" >
                    <div class="caption grey--text">Datums</div>
                    <div class="font-weight-light">{{order.funeral.funeral_date ? order.funeral.funeral_date : "Nav norādīts"}}</div>
                  </v-col>
                  <v-col cols="6"class="pa-2">
                    <div class="caption grey--text">Ceremonijas sākums</div>
                    <div class="font-weight-light">{{order.funeral.start_time ? order.funeral.start_time : "Nav norādīts"}}</div>
                  </v-col>
                  <v-col cols="6" class="pa-2">
                    <div class="caption grey--text">Glabāšanas vieta</div>
                    <div class="font-weight-light">{{order.funeral.funeral_location ? order.funeral.funeral_location.name : "Nav norādīts"}}</div>
                  </v-col>
                  <v-col cols="6"class="pa-2">
                    <div class="caption grey--text">Ceremonijas vieta</div>
                    <div class="font-weight-light">{{order.funeral.funeral_locations_place ? order.funeral.funeral_locations_place.name : "Nav norādīts"}}</div>
                  </v-col>
                </v-row>
              </template>

              <edit-funeral-details v-if="showEditOrderFuneralForm"/>

              <template v-if="!showEditOrderFuneralForm && !order.funeral ">
                <template v-if="!showAddFuneralForm">
                  <v-row no-gutters justify="center" class="px-4 mt-3 font-weight-light">
                    <v-col cols="12" class="text-center" >
                      Bēres nav pievienotas
                    </v-col>
                    <v-btn text class="blue-grey--text" @click="showNewFuneralForm">
                      Pievienot
                    </v-btn>
                  </v-row>
                </template>
              </template>

              <add-funeral v-if="showAddFuneralForm"/>
            </v-card>
          </v-col>
          <!--  Decased person        -->
          <v-col cols="12" md="6" class="pa-4" >
            <v-card height="100%">
              <div class="px-2 pt-2">
                <v-row no-gutters class="mb-2 px-2">
                  <div class=" px-2">
                    <span class="title font-weight-light cyan--text text--darken-3">Mirušais</span>
                  </div>

                  <v-spacer/>

                  <template v-if="order.decased_person">
                    <v-tooltip top v-if="!showEditDecasedPersonForm">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="showDecasedPersonForm" text small slot="activator">
                          <v-icon color="cyan darken-3">mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Labot mirušo</span>
                    </v-tooltip>

                    <v-tooltip top v-if="showEditDecasedPersonForm">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="hideEditForm" text small slot="activator">
                          <v-icon color="cyan darken-3">mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Atcelt</span>
                    </v-tooltip>

                    <v-tooltip top >
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="deleteDecasedPerson(order.decased_person.id)" text small slot="activator">
                          <v-icon color="red">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Dzēst mirušo</span>
                    </v-tooltip>
                  </template>
                </v-row>
                <v-divider class="cyan darken-3"/>
              </div>

              <template v-if="!showEditDecasedPersonForm && order.decased_person">
                <v-row class="px-4" no-gutters>
                  <v-col cols="6" class="pa-2" >
                    <div class="caption grey--text">Vārds</div>
                    <div class="font-weight-light">{{order.decased_person.name ? order.decased_person.name : "Nav norādīts"}}</div>
                  </v-col>
                  <v-col cols="6"class="pa-2">
                    <div class="caption grey--text">Uzvārds</div>
                    <div class="font-weight-light">{{order.decased_person.surname ? order.decased_person.surname : "Nav norādīts"}}</div>
                  </v-col>
                  <v-col cols="12" md="6" class="pa-2">
                    <div class="caption grey--text">Adrese</div>
                    <div class="font-weight-light">{{order.decased_person.address ? order.decased_person.address : "Nav norādīts"}}</div>
                  </v-col>
                  <v-col cols="6" class="pa-2">
                    <div class="caption grey--text">Vieta</div>
                    <div class="font-weight-light">{{order.decased_person.location ? order.decased_person.location : "Nav norādīts"}}</div>
                  </v-col>
                </v-row>
              </template>

              <edit-decased-person-details v-if="showEditDecasedPersonForm"/>

              <template v-if="!showEditDecasedPersonForm && !order.decased_person ">
                <template v-if="!showAddDecasedPersonForm">
                  <v-row no-gutters justify="center" class="px-4 mt-3 font-weight-light">
                    <v-col cols="12" class="text-center" >
                      Mirušais nav pievoienots
                    </v-col>
                    <v-btn text class="blue-grey--text" @click="showNewDecasedPersonForm">
                      Pievienot
                    </v-btn>
                  </v-row>
                </template>
              </template>

              <add-decased-person v-if="showAddDecasedPersonForm"/>

            </v-card>
          </v-col>
        </v-row>
        <!--  Service providers        -->
        <v-row no-gutters>
          <v-col cols="12" class="pa-4" >
            <v-card>
              <div class="px-2 pt-2">
                <v-row no-gutters class="mb-2 px-2">
                  <div class=" px-2">
                    <span class="title font-weight-light teal--text text--lighten-1">Pakalpojumu sniedzēji</span>
                  </div>

                  <v-spacer/>

                  <v-tooltip top v-if="!showAddOrderServiceProviderForm && order.service_providers.length > 0">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="showServiceProviderForm" text small slot="activator">
                        <v-icon color="teal lighten-1">mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Pievienot pakalpojumu sniedzēju</span>
                  </v-tooltip>

                  <v-tooltip top v-if="showAddOrderServiceProviderForm">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="hideEditForm" text small slot="activator">
                        <v-icon color="teal lighten-1">mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span>Atcelt</span>
                  </v-tooltip>

                </v-row>
                <v-divider class="teal lighten-1"/>
              </div>

              <template v-if="!showAddOrderServiceProviderForm && order.service_providers.length > 0">
                <v-row class="px-4" align="center" no-gutters v-for="provider in order.service_providers" :key="provider.id" >
                  <v-col cols="6" lg="3" class="pa-2" >
                    <div class="caption grey--text">Pakalpojums</div>
                    <div class="font-weight-light">{{provider.service_provider.occupation.name}}</div>
                  </v-col>
                  <v-col cols="6" lg="3"class="pa-2">
                    <div class="caption grey--text">Nosaukums</div>
                    <div class="font-weight-light">{{provider.service_provider.name ? provider.service_provider.name : "Nav norādīts"}}</div>
                  </v-col>
                  <v-col cols="6" lg="2" class="pa-2">
                    <div class="caption grey--text">Tel. nr</div>
                    <div class="font-weight-light">{{provider.service_provider.phone ? provider.service_provider.phone : "Nav norādīts"}}</div>
                  </v-col>
                  <v-col cols="6" lg="2" class="pa-2">
                    <div class="caption grey--text">E-pasts</div>
                    <div class="font-weight-light">{{provider.service_provider.email ? provider.service_provider.email : "Nav norādīts"}}</div>
                  </v-col>
                  <v-col cols="6" lg="2">
                    <v-tooltip top >
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on"  icon @click="deleteOrderServiceProvider(provider.id)" text small slot="activator">
                          <v-icon color="red">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Dzēst pakalpojuma sniedzēju</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </template>

              <add-order-service-provider :orderId="order.id" v-if="showAddOrderServiceProviderForm"/>

              <template v-if="!showAddOrderServiceProviderForm && order.service_providers.length < 1">
                <template v-if="!showAddOrderServiceProviderForm">
                  <v-row no-gutters justify="center" class="px-4 mt-3 font-weight-light">
                    <v-col cols="12" class="text-center" >
                      Pakalpojuma sniedzēji nav pievienoti
                    </v-col>
                    <v-btn text class="blue-grey--text" @click="showServiceProviderForm">
                      Pievienot
                    </v-btn>
                  </v-row>
                </template>
              </template>

            </v-card>
          </v-col>
        </v-row>

        <!--  Order items        -->
        <v-row no-gutters class="px-4">
          <v-col >
            <v-card>
              <v-col cols="12"  class="pa-2" v-if="order.order_items.length > 0">
                <div class="mb-2 px-2">
                  <span class="title font-weight-light teal--text text--darken-3">Izvēlētās pozīcijas</span>
                </div>
                <v-divider class="teal darken-3"/>
              </v-col>

              <v-col cols="12" class="px-8">
                <div v-for="orderItem in order.order_items" :key="orderItem.id">
                  <v-row no-gutters class="py-2" align="center">

                    <v-col cols="12" lg="4" class="px-2" v-if="orderItem.item">
                      <div class="caption grey--text">Pozīcija</div>
                      <div class="font-weight-light">{{orderItem.item.name}}</div>
                    </v-col>
                    <v-col cols="4" lg="1" class="px-2" >
                      <div class="caption grey--text">Cena EUR</div>
                      <div class="font-weight-light">{{orderItem.price}}</div>
                    </v-col>
                    <v-col cols="5" lg="1" class="px-2" >
                      <div class="caption grey--text">Skaits</div>
                      <div class="font-weight-light">{{orderItem.quantity}}</div>
                    </v-col>
                    <v-col cols="3" lg="1" class="px-2" >
                      <div class="caption grey--text">PVN % </div>
                      <div class="font-weight-light">{{orderItem.vat_rate}}</div>
                    </v-col>
                    <v-col cols="4" lg="1" class="px-2" >
                      <div class="caption grey--text">PVN EUR </div>
                      <div class="font-weight-light">{{orderItem.vat}}</div>
                    </v-col>
                    <v-col cols="5" lg="2" class="px-2" >
                      <div class="caption grey--text">SUMMA EUR </div>
                      <div class="font-weight-light">{{orderItem.total_with_vat}}</div>
                    </v-col>
                    <v-col cols="6" sm="1" class="px-2 pt-2" >
                        <v-tooltip top >
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon @click="editItem(orderItem)" text small slot="activator" class="pb-2">
                              <v-icon color="teal darken-3">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Labot pozīciju</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="6" sm="1" class="px-2 pt-2" >
                      <template v-if="order.order_items.length > 1">
                        <v-tooltip top >
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon @click="deleteOrderItem(orderItem.id)" text small slot="activator" class="pb-2">
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Dzēst pozīciju</span>
                        </v-tooltip>
                      </template>
                    </v-col>
                  </v-row>
                  <div v-if="order.order_items.length > 1">
                    <v-divider/>
                  </div>
                </div>
              </v-col>
            </v-card>
          </v-col>
        </v-row>

        <!--  Order actions        -->
        <v-row no-gutters>
          <v-col cols="12"  class="pa-4">
            <v-card>
            <div class="mb-2 px-2 pt-2">
              <div class="pb-2">
                <span class="title font-weight-light px-2 pb-2">Darbības</span>
              </div>
              <hr>
            </div>

            <v-row class="pa-2" justify="center">
              <v-btn class="blue-grey--text  mx-4" text @click="streamPDF(order.uuid)">Drukāt pasūtījumu</v-btn>
              <v-btn text class="blue-grey--text mx-4" @click="showAddItemsForm" v-if="!showAddOrderItemsForm">Pievienot papildus pozīcījas</v-btn>
              <v-btn text class="blue-grey--text mx-4" @click="createInvoice" v-if="!order.invoice" >Izrakstīt rēķinu</v-btn>
              <v-btn text class="blue-grey--text mx-4" @click="streamInvoicePDF(order.uuid)" v-if="order.invoice" >Drukāt rēķinu</v-btn>
            </v-row>

            </v-card>

          </v-col>
        </v-row>

      <add-order-items v-if="showAddOrderItemsForm"/>

      <v-dialog v-model="showEditModal" max-width="700" persistent>
        <edit-order-item/>
      </v-dialog>

    </template>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import EditOrderDetails from "../components/EditOrderDetails";
  import EditOrderCustomer from "../components/EditOrderCustomer";
  import EditFuneralDetails from "../components/EditFuneralDetails";
  import AddFuneral from "../components/AddFuneral";
  import EditDecasedPersonDetails from "../components/EditDecasedPersonDetails";
  import AddDecasedPerson from "../components/AddDecasedPerson";
  import AddOrderItems from "../components/AddOrderItems";
  import AddOrderServiceProvider from "../components/AddOrderServiceProvider";
  import EditOrderItem from "../components/EditOrderItem";

  export default {
    name: "Order",
    components: {
      EditOrderDetails,
      EditOrderCustomer,
      EditFuneralDetails,
      AddFuneral,
      EditDecasedPersonDetails,
      AddDecasedPerson,
      AddOrderItems,
      AddOrderServiceProvider,
      EditOrderItem
    },
    data: () => ({

    }),
    mounted() {
      this.$store.dispatch('clearSingleOrder')
      this.$store.dispatch('hideEditModal')
      this.$store.dispatch('getSingleOrder', this.$route.params.orderId)
    },
    computed: {
      ...mapGetters({
        order: 'order',
        showEditOrderDetailsForm: 'showEditOrderDetailsForm',
        showEditOrderCustomerForm: 'showEditOrderCustomerForm',
        showEditOrderFuneralForm: 'showEditOrderFuneralForm',
        showAddFuneralForm: 'showAddFuneralForm',
        showAddOrderServiceProviderForm: 'showAddOrderServiceProviderForm',
        showEditDecasedPersonForm: 'showEditDecasedPersonForm',
        showAddDecasedPersonForm: 'showAddDecasedPersonForm',
        showAddOrderItemsForm: 'showAddOrderItemsForm',
        showEditModal: 'showEditModal',
      }),
    },
    methods: {
      goBack(){
        this.$store.dispatch('hideEditForms')
        this.$router.push({ path: '/orders' })
      },
      createInvoice(){
        this.$router.push({ path: '/orders/' + this.order.id + '/create_invoice',  })
      },
      viewInvoice(){
        this.$router.push({ path: '/orders/' + this.order.id + '/invoice/' + this.order.invoice.id,  })
      },
      editItem(item){
        this.$store.dispatch('showEditModal', item)
      },
      showEditForm(){
        this.$store.dispatch('showEditOrderDetailsForm')
      },
      showServiceProviderForm(){
        this.$store.dispatch('showAddOrderServiceProviderForm')
      },
      showCustomerForm(){
        this.$store.dispatch('showEditOrderCustomerForm')
      },
      showFuneralForm(){
        this.$store.dispatch('showEditOrderFuneralForm')
      },
      showNewFuneralForm(){
        this.$store.dispatch('showAddFuneralForm')
      },
      showDecasedPersonForm(){
        this.$store.dispatch('showEditDecasedPersonForm')
      },
      showNewDecasedPersonForm(){
        this.$store.dispatch('showAddDecasedPersonForm')
      },
      showAddItemsForm(){
        this.$store.dispatch('showAddOrderItemsForm')
      },
      hideEditForm(){
        this.$store.dispatch('hideEditForms')
      },
      deleteOrder(orderId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteOrder', orderId)
        }
      },
      deleteFuneral(funeralId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteFuneral', funeralId)
        }
      },
      deleteDecasedPerson(decasedPersonId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteDecasedPerson', decasedPersonId)
        }
      },
      deleteOrderItem(orderItemId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteOrderItem', orderItemId)
        }
      },
      deleteOrderServiceProvider(orderServiceProviderId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteOrderServiceProvider', orderServiceProviderId)
        }
      },
      deleteOrderInvoice(orderInvoiceId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteOrderInvoice', orderInvoiceId)
        }
      },
      streamPDF (orderUUID) {
        var baseUrl = process.env.VUE_APP_BASE_URL
        window.open( baseUrl + "/api/order/pdf/" + orderUUID, "_blank");
      },
      streamInvoicePDF (orderUUID) {
        var baseUrl = process.env.VUE_APP_BASE_URL
        window.open( baseUrl + '/api/' + orderUUID + '/pdf/invoice' , "_blank");
      }



    }
  }
</script>
