import axios from 'axios'
import {vm} from '@/main'

var baseUrl = process.env.VUE_APP_BASE_URL

const serviceProviders = {
    state: {
        serviceProviders: null,
        serviceProviderInputData: null,
        showAddServiceProviderForm: false
    },

    mutations: {
        setServiceProviders(state, serviceProviders){
            state.serviceProviders = serviceProviders
        },
        setInputData(state, payload){
            state.serviceProviderInputData = payload
        },
        showAddServiceProviderForm(state){
            state.showAddServiceProviderForm = true
        },
        hideAddServiceProviderForm(state){
            state.showAddServiceProviderForm = false
        }
    },

    actions: {
        getServiceProviders({ commit, rootState, dispatch, state }, page){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/service_providers?page='+page +'&q='+rootState.search)
                    .then(response => {
                        commit('setServiceProviders', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },
        getServiceProviderInputData({ commit, rootState, dispatch }){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/service_providers/create')
                    .then(response => {
                        commit('setInputData', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },
        createNewServiceProvider({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/service_providers', payload)
                    .then(response => {
                        dispatch('getServiceProviders')
                        commit('hideAddServiceProviderForm')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Pakalpojuma sniedzējs izveidots'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Kategoriju neizdevās izveidot'
                        })
                        vm.$Progress.fail()
                        reject(error)
                    })
            })
        },

        updateServiceProvider({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.patch(baseUrl +'/api/service_providers/' + rootState.editableItem.id, payload)
                    .then(response => {
                        dispatch('getServiceProviders')
                        dispatch('hideEditModal')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'blue',
                            text: 'Ieraksts izlabots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izlabot'
                            })
                        }
                        reject(error)
                    })
            })
        },

        deleteServiceProvider({commit, rootState, dispatch, state},  serviceProviderId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.delete(baseUrl +'/api/service_providers/'+ serviceProviderId)
                    .then(response => {
                        dispatch('getServiceProviders')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Pakalpojuma sniedzējs dzēsts'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        showAddServiceProviderForm({ commit }){
            commit('showAddServiceProviderForm')
        },
        hideAddServiceProviderForm({ commit }){
            commit('hideAddServiceProviderForm')
        },
    },
    getters: {
        serviceProviders(state){
            return state.serviceProviders
        },
        serviceProviderInputData(state){
            return state.serviceProviderInputData
        },
        showAddServiceProviderForm(state){
            return state.showAddServiceProviderForm
        }
    }
}

export default serviceProviders