<template>
  <v-container>

    <v-row align="center">
      <v-col cols="12" sm="4">
        <h1 class="heading blue-grey--text ">Pakalpojumu sniedzēji</h1>
      </v-col>

      <v-spacer/>

      <v-col cols="8" sm="6" md="4">
        <v-text-field
                @input="searchServiceProviders"
                v-model="search"
                append-icon="mdi-magnify"
                label="Meklēt"
                color="blue-grey darken-1"
                class=""
       />
      </v-col>

      <v-col v-if="!showForm" cols="2" sm="1" >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"  @click="showAddServiceProviderForm" small fab text color="blue-grey darken-1" slot="activator" >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Pievienot pakalpojumu sniedzēju</span>
        </v-tooltip>
      </v-col>

      <v-col v-if="showForm" cols="2" sm="1" >
        <v-btn  @click="hideAddServiceProviderForm" small outlined fab text color="blue-grey darken-1" slot="activator">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-col>

    </v-row>

    <add-service-provider v-if="showForm"/>

    <template v-if="serviceProviders">
      <v-card v-for="serviceProvider in serviceProviders.data" :key="serviceProvider.id" class="py-0 px-4 mb-2" outlined>
        <v-row >
          <v-col cols="6" sm="2">
            <div class="caption grey--text">Nosaukums</div>
            <div class="font-weight-light">{{serviceProvider.name}}</div>
          </v-col>

          <v-col cols="6" sm="2">
            <div class="caption grey--text">Nodarbošanās</div>
            <div class="font-weight-light">{{serviceProvider.occupation.name}}</div>
          </v-col>

          <v-col cols="6" sm="2">
            <div class="caption grey--text">Tel. nr</div>
            <div class="font-weight-light">{{serviceProvider.phone ? serviceProvider.phone : 'Nav norādīts'}}</div>
          </v-col>

          <v-col cols="6" sm="2">
            <div class="caption grey--text">E-pasts</div>
            <div class="font-weight-light">{{serviceProvider.email ? serviceProvider.email : 'Nav norādīts'}}</div>
          </v-col>



            <v-row justify="center" justify-md="end" align-content="center">
              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="editItem(serviceProvider)" text small slot="activator">
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  </template>
                  <span>Labot pakalpojumu sniedzēju</span>
                </v-tooltip>
              </v-col>

              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="deleteServiceProvider(serviceProvider.id)" text small slot="activator" class="align-content-sm-center">
                    <v-icon color="grey">mdi-delete</v-icon>
                  </v-btn>
                  </template>
                  <span>Dzēst pakalpojumu sniedzēju</span>
                </v-tooltip>
              </v-col>
            </v-row>

        </v-row>
      </v-card>

      <v-row justify-content="center">
        <v-col v-if="serviceProviders.meta.last_page > 1" >
          <v-pagination
                  v-model="serviceProviders.meta.current_page"
                  :length="serviceProviders.meta.last_page"
                  :total-visible="5"
                  color="blue-grey "
                  @input="onPageChange"
          />
        </v-col>
      </v-row>
    </template>

    <v-dialog v-model="showEditModal" max-width="700" persistent>
      <edit-service-provider/>
    </v-dialog>

  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AddServiceProvider from "../components/AddServiceProvider";
  import EditServiceProvider from "../components/EditServiceProvider";

  export default {
    name: "Customers",
    components: {AddServiceProvider, EditServiceProvider},
    data: () => ({
    }),
    mounted() {
      this.$store.dispatch('hideEditModal')
      this.$store.dispatch('hideAddServiceProviderForm')
      this.$store.dispatch('resetSearch')
      this.$store.dispatch('getServiceProviders')
    },
    computed: {
      ...mapGetters({
        serviceProviders: 'serviceProviders',
        search: 'search',
        showForm: 'showAddServiceProviderForm',
        showEditModal: 'showEditModal',
      })
    },
    methods: {
      editItem(item){
        this.$store.dispatch('showEditModal', item)
      },
      searchServiceProviders(value){
        this.$store.dispatch('changeSearch', value)
        this.$store.dispatch('getServiceProviders')
      },
      onPageChange() {
        this.$store.dispatch('getServiceProviders', this.serviceProviders.meta.current_page);
      },
      showAddServiceProviderForm(){
        this.$store.dispatch('showAddServiceProviderForm')
      },
      hideAddServiceProviderForm(){
        this.$store.dispatch('hideAddServiceProviderForm')
      },
      deleteServiceProvider(serviceProviderId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteServiceProvider', serviceProviderId)
        }
      }



    }
  }
</script>
