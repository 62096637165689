import axios from 'axios'
import {vm} from '@/main'

var baseUrl = process.env.VUE_APP_BASE_URL

const funeralLocationsPlaces = {
    state: {
        funeralLocationsPlaces: null,
        funeralLocationsPlacesInputData: null,
        showAddFuneralLocationsPlaceForm: false
    },

    mutations: {
        setFuneralLocationsPlaces(state, funeralLocationsPlaces){
            state.funeralLocationsPlaces = funeralLocationsPlaces
        },
        setInputData(state, payload){
            state.funeralLocationsPlacesInputData = payload
        },
        showAddFuneralLocationsPlaceForm(state){
            state.showAddFuneralLocationsPlaceForm = true
        },
        hideAddFuneralLocationsPlaceForm(state){
            state.showAddFuneralLocationsPlaceForm = false
        }
    },

    actions: {
        getFuneralLocationsPlaces({ commit, rootState, dispatch, state }, page){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/funeral_locations_place?page='+page +'&q='+rootState.search)
                    .then(response => {
                        commit('setFuneralLocationsPlaces', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        createNewFuneralLocationsPlace({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/funeral_locations_place', payload

                )
                    .then(response => {
                        dispatch('getFuneralLocationsPlaces')
                        commit('hideAddFuneralLocationsPlaceForm')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Ceremonijas vieta izveidota'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Ierakstu neizdevās izveidot'
                        })
                        vm.$Progress.fail()
                        reject(error)
                    })
            })
        },
        updateFuneralLocationsPlace({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.patch(baseUrl +'/api/funeral_locations_place/' + rootState.editableItem.id, payload)
                    .then(response => {
                        dispatch('hideEditModal')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'blue',
                            text: 'Ieraksts izlabots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izlabot'
                            })
                        }
                        reject(error)
                    })
            })
        },


        deleteFuneralLocationsPlace({commit, rootState, dispatch, state}, funeralLocationsPlaceId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.delete(baseUrl +'/api/funeral_locations_place/' + funeralLocationsPlaceId)
                    .then(response => {
                        dispatch('getFuneralLocationsPlaces')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Ceremonijas vieta dzēsta'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        showAddFuneralLocationsPlaceForm({ commit }){
            commit('showAddFuneralLocationsPlaceForm')
        },
        hideAddFuneralLocationsPlaceForm({ commit }){
            commit('hideAddFuneralLocationsPlaceForm')
        },
    },
    getters: {
        allFuneralLocationsPlaces(state){
            return state.funeralLocationsPlaces
        },
        funeralLocationsPlacesInputData(state){
            return state.funeralLocationsPlacesInputData
        },
        showAddFuneralLocationsPlaceForm(state){
            return state.showAddFuneralLocationsPlaceForm
        }
    }
}

export default funeralLocationsPlaces