<template>
  <v-container>

    <v-row align="center">
      <v-col sm="2">
        <h1 class="heading blue-grey--text ">Preces</h1>
      </v-col>

      <v-spacer/>

      <v-col cols="10" sm="6" md="4">
        <v-text-field
                @input="searchProducts"
                v-model="search"
                append-icon="mdi-magnify"
                label="Meklēt"
                color="blue-grey darken-1"
                class=""
        />
      </v-col>

      <v-col v-if="!showForm" cols="2" sm="1" >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"  @click="showAddItemForm" small fab text color="blue-grey darken-1" slot="activator" >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Pievienot preci</span>
        </v-tooltip>
      </v-col>

      <v-col v-if="showForm" cols="2" sm="1" >
        <v-btn  @click="hideAddItemForm" small outlined fab text color="blue-grey darken-1" slot="activator">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-col>

    </v-row>

    <add-product v-if="showForm" :categoryTypeId="categoryTypeId"/>

    <template v-if="products">
      <v-card v-for="product in products.data" :key="product.id" class="py-0 px-4 mb-2" outlined>
        <v-row >
          <v-col cols="6" sm="2">
            <div class="caption grey--text">Nosaukums</div>
            <div class="font-weight-light">{{product.name}}</div>
          </v-col>

          <v-col cols="6" sm="2">
            <div class="caption grey--text">Kategorija</div>
            <div class="font-weight-light">{{product.category.name}}</div>
          </v-col>

          <v-col cols="6" sm="2">
            <div class="caption grey--text">Modelis</div>
            <div class="font-weight-light">{{product.itemDetail.model ?  product.itemDetail.model : 'Nav norādīts'}}</div>
          </v-col>

          <v-col cols="6" sm="2">
            <div class="caption grey--text">Izmērs</div>
            <div class="font-weight-light">{{product.itemDetail.size ?  product.itemDetail.size : 'Nav norādīts'}}</div>
          </v-col>

          <v-col cols="12" sm="2" >
            <v-img v-if="product.image" :src="product.image.url" max-height="80" contain/>
          </v-col>


          <v-row justify="center" align-content="center">
              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="editItem(product)" text small slot="activator">
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  </template>
                  <span>Labot preci</span>
                </v-tooltip>
              </v-col>

              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="deleteMaterial(product.id)" text small slot="activator" class="align-content-sm-center">
                    <v-icon color="grey">mdi-delete</v-icon>
                  </v-btn>
                  </template>
                  <span>Dzēst preci</span>
                </v-tooltip>
              </v-col>
            </v-row>

        </v-row>
      </v-card>

      <v-row justify-content="center">
        <v-col v-if="products.meta.last_page > 1" >
          <v-pagination
                  v-model="products.meta.current_page"
                  :length="products.meta.last_page"
                  :total-visible="5"
                  color="blue-grey "
                  @input="onPageChange"
          />
        </v-col>
      </v-row>
    </template>

  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AddProduct from "../components/AddProduct";

  export default {
    name: "Products",
    components: {AddProduct},
    data: () => ({
      'categoryTypeId': 2,
    }),
    mounted() {
      this.$store.dispatch('hideAddItemForm')
      this.$store.dispatch('resetSearch')
      this.$store.dispatch('getItems', {
        categoryTypeId: this.categoryTypeId
      })
    },
    computed: {
      ...mapGetters({
        products: 'items',
        search: 'search',
        showForm: 'showAddItemForm',
        showEditModal: 'showEditModal',
      })
    },
    methods: {
      editItem(item){
        this.$router.push( 'products/edit/' + item.id)
      },
      searchProducts(value){
        this.$store.dispatch('changeSearch', value)
        this.$store.dispatch('getItems', {
          categoryTypeId: this.categoryTypeId,
        })
      },
      onPageChange() {
        this.$store.dispatch('getItems', {
                  categoryTypeId: this.categoryTypeId,
                  currentPage: this.products.meta.current_page
                }
        );
      },
      showAddItemForm(){
        this.$store.dispatch('showAddItemForm')
      },
      hideAddItemForm(){
        this.$store.dispatch('hideAddItemForm')
      },
      deleteMaterial(productId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteItem', {
            itemId : productId,
            categoryTypeId: this.categoryTypeId
          })
        }
      }



    }
  }
</script>
