import axios from 'axios'
import {vm} from '@/main'

var baseUrl = process.env.VUE_APP_BASE_URL

const orderCreate = {
    state: {
        categories: null,
        occupations: null,
        branches: null,
        funeralLocations: null,
        funeralLocationsPlaces: null,
        selectedCustomer: null,
        selectedProducts: []
    },

    mutations: {
        setCreateOrderInputData(state, payload){
            state.categories = payload.categories
            state.branches = payload.branches
            state.occupations = payload.occupations
            state.funeralLocations = payload.funeralLocations
            state.funeralLocationsPlaces = payload.funeralLocationsPlaces
        },
        setClearCreateOrderCategories(state){
            state.categories = null
        },
        setSelectedCustomer(state, selectedCustomer) {
            state.selectedCustomer = selectedCustomer
        },
        setClearCreateOrderSelectedCustomer(state) {
            state.selectedCustomer = null
        },
        addSelectedProduct(state, selectedProduct) {
            if(state.selectedProducts.some(existingProduct => (existingProduct.id === selectedProduct.id)) === false) {
                state.selectedProducts.push(selectedProduct)
            }
        },
        clearSelectedProducts(state) {
            state.selectedProducts = []
        },

    },

    actions: {
        getCreateOrderInputData({ commit, rootState, dispatch }){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/order/create')
                    .then(response => {
                        commit('setCreateOrderInputData', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },
        clearCreateOrderCategories({commit}){
            commit('setClearCreateOrderCategories')
        },
        createNewOrder({ commit, rootState, dispatch, state }, formData){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/order', {
                    customer_id: state.selectedCustomer.id,
                    branch_id: formData.branchId,
                    decased_person: formData.decasedPerson,
                    funeral: formData.funeral,
                    order_date: formData.orderDate,
                    agency_rate: formData.agencyRate,
                    agency_costs: formData.agencyCosts,
                    total: formData.total,
                    vat: formData.totalVat,
                    total_with_vat: formData.totalWithVat,
                    order_items: formData.orderItems,
                    service_providers: formData.serviceProviders,
                    social_benefit: formData.social_benefit,
                    advance_payment: formData.advance_payment,
                    total_after_benefits: formData.total_after_benefits,
                    notes: formData.notes,
                })
                    .then(response => {
                        vm.$router.push('/orders')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Pasūtījums izveidots'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        vm.$Progress.fail()
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Pasūtījumu izveidot neizdevās'
                        })
                        reject(error)
                    })
            })
        },
        createNewCustomerFromOrderView({ commit, rootState, dispatch, state }, newCustomer){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/customer', {
                    name: newCustomer.name,
                    reg_nr: newCustomer.reg_nr,
                    address: newCustomer.address,
                    phone: newCustomer.phone,
                    email: newCustomer.email,
                })
                    .then(response => {
                        commit('setSelectedCustomer', response.data.customer)
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Klients izveidots'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Klientu izveidot neizdevās'
                        })
                        vm.$Progress.fail()
                        reject(error)
                    })
            })
        },
        setSelectedCustomer({commit}, customer){
            commit('setSelectedCustomer', customer)
        },
        clearCreateOrderSelectedCustomer({commit}){
            commit('setClearCreateOrderSelectedCustomer')
        },
        addSelectedProduct({commit}, selectedProduct){
            commit('addSelectedProduct', selectedProduct)
        },
        clearSelectedProducts({commit}){
            commit('clearSelectedProducts')
        },

    },
    getters: {
        orderCategories(state){
            return state.categories
        },
        branches(state){
            return state.branches
        },
        occupations(state){
            return state.occupations
        },
        funeralLocations(state) {
            return state.funeralLocations
        },
        funeralLocationsPlaces(state) {
            return state.funeralLocationsPlaces
        },
        selectedCustomer(state){
            return state.selectedCustomer
        },
        selectedProducts(state){
            return state.selectedProducts
        },

    }
}

export default orderCreate