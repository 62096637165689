<template>

    <v-card justify="center" class="my-3 mx-4 px-4">

        <v-row no-gutters class="mb-2">
            <div class=" px-2 pt-2">
                <span class="title font-weight-light ">Pievienot papildus pozīcijas</span>
            </div>
            <v-spacer/>
            <v-btn right text class="py-2" @click="hideEditForm">X</v-btn>
        </v-row>
        <hr>

        <template  v-if="inputData">
                    <v-row no-gutters class="px-4 pt-4">
                        <v-col cols="12" v-for="category in inputData.categories" :key="category.id" class="">
                            <template v-if="category.items.length > 0">
                                <v-card outlined class="pb-4 mb-2">
                                    <v-row class="px-4 py-2 " :class="categoryTypeColor(category.category_type_id)" no-gutters>
                                        <v-col>
                                            <span class="font-weight-medium subtitle-1 white--text py-1">{{category.name}}</span>
                                        </v-col>
                                    </v-row>

                                    <div class="py-2">
                                        <v-row v-for="item in category.items" :key="item.id" class="px-4 py-1" no-gutters align="center">
                                            <v-col class="pb-2" cols="12" md="5">
                                                <v-checkbox
                                                        v-model="selectedItems"
                                                        :value="item"
                                                        hide-details
                                                        :color="categoryTypeColor(category.category_type_id)"
                                                        :label="item.name"
                                                        class="py-2"
                                                />
                                            </v-col>
                                            <template v-if="showItemDetailForm(item.id)">
                                                <v-col cols="6" md="2">
                                                    <v-text-field
                                                            type="number"
                                                            class="ma-0 py-1"
                                                            v-model="item.price"
                                                            hide-details
                                                            label="Cena EUR"
                                                            :color="categoryTypeColor(category.category_type_id)"
                                                            dense
                                                    />
                                                </v-col>
                                                <v-col cols="6" md="2">
                                                    <v-text-field
                                                            type="number"
                                                            class="ma-0 pa-0 px-2"
                                                            v-model="item.quantity"
                                                            hide-details
                                                            label="Daudzums"
                                                            :color="categoryTypeColor(category.category_type_id)"
                                                            dense
                                                    />
                                                </v-col>
                                                <v-col >
                                                    <v-radio-group v-model="item.vat_rate" row hide-details class="mt-0">
                                                        <v-radio :color="categoryTypeColor(category.category_type_id)" name="vatRate" label="21 %" :value="21"  hide-details/>
                                                        <v-radio :color="categoryTypeColor(category.category_type_id)" name="vatRate" label="0 %" :value="0"  hide-details/>
                                                    </v-radio-group>
                                                </v-col>
                                            </template>
                                        </v-row>
                                    </div>

                                </v-card>
                            </template>
                        </v-col>
                    </v-row>
        </template>

        <v-row no-gutters class="px-4 py-4">
            <v-row no-gutters>
                <v-col cols="12">
                    <h3 class="blue-grey--text py-1">Preces</h3>
                </v-col>
            </v-row>
            <v-col cols="12" class="pb-4">
                <v-card outlined >
                    <v-row class="px-4 py-2 red darken-3" no-gutters>
                        <v-col>
                            <span class="font-weight-medium subtitle-1 white--text">Preces</span>
                        </v-col>
                        <v-spacer/>
                        <v-tooltip top v-if="!showAddProductModal && selectedProducts.length > 0">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon @click="showAddModal" text small slot="activator">
                                    <v-icon color="white">mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Pievienot preci</span>
                        </v-tooltip>
                    </v-row>

                    <v-card-text v-if="selectedProducts.length > 0">
                        <v-row v-for="item in selectedProducts" :key="item.id" class="px-4 py-1" no-gutters align="center">
                            <v-col class="pb-2" cols="12" md="5">
                                <v-checkbox
                                        v-model="selectedItems"
                                        :value="item"
                                        hide-details
                                        :color="categoryTypeColor(item.category.category_id)"
                                        :label="item.name"
                                        class="py-2"
                                />
                            </v-col>
                            <template v-if="showItemDetailForm(item.id)">
                                <v-col cols="6" md="2">
                                    <v-text-field
                                            type="number"
                                            class="ma-0 py-1"
                                            v-model="item.price"
                                            hide-details
                                            label="Cena EUR"
                                            :color="categoryTypeColor(item.category.category_id)"
                                            :rules="formRules"
                                            dense
                                    />
                                </v-col>
                                <v-col cols="6" md="2">
                                    <v-text-field
                                            type="number"
                                            class="ma-0 pa-0 px-2"
                                            v-model="item.quantity"
                                            hide-details
                                            label="Daudzums"
                                            :rules="formRules"
                                            :color="categoryTypeColor(item.category.category_id)"
                                            dense
                                    />
                                </v-col>
                                <v-col >
                                    <v-radio-group v-model="item.vat_rate" row hide-details class="mt-0">
                                        <v-radio :color="categoryTypeColor(item.category.category_id)" name="vatRate" label="21 %" :value="21"  hide-details/>
                                        <v-radio :color="categoryTypeColor(item.category.category_id)" name="vatRate" label="0 %" :value="0"  hide-details/>
                                    </v-radio-group>
                                </v-col>
                            </template>
                        </v-row>
                    </v-card-text>

                    <template v-if="!showAddProductModal && selectedProducts.length < 1">
                        <template v-if="!showAddProductModal">
                            <v-row no-gutters justify="center" class="px-4 mt-3 font-weight-light">
                                <v-col cols="12" class="text-center" >
                                    Preces nav pievienotas
                                </v-col>
                                <v-btn text class="blue-grey--text" @click="showAddModal">
                                    Pievienot
                                </v-btn>
                            </v-row>
                        </template>
                    </template>

                </v-card>
            </v-col>
        </v-row>

        <v-row row justify="center" justify-md="end" no-gutters class="pb-4 px-2">
            <v-btn  text @click="hideEditForm" dark color="deep-orange lighten-1">Atcelt</v-btn>
            <v-btn  text @click="submit" dark color="green accent-3">Pievienot</v-btn>
        </v-row>

        <v-dialog v-model="showAddProductModal" max-width="900" persistent>
            <add-order-product/>
        </v-dialog>

    </v-card>
</template>

<script>

    import {mapGetters} from "vuex";
    import AddOrderProduct from "../components/AddOrderProduct";

    export default {
        name: "add-order-items",
        components: {
            AddOrderProduct
        },
        data: () => ({
            selectedItems: [],
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),
        mounted() {
            this.$store.dispatch('hideAddModal')
            this.$store.dispatch('clearProducts')
            this.$store.dispatch('clearSelectedProducts')
            this.$store.dispatch('clearOrderItemsInputData')
            this.$store.dispatch('getOrderItemsInputData')
        },
        computed: {
            ...mapGetters({
                inputData: 'editOrderInputData',
                order: 'order',
                selectedProducts: 'selectedProducts',
                showAddProductModal: 'showAddModal'
            })
        },
        methods: {
            showAddModal(){
                this.$store.dispatch('showAddModal')
            },
            showItemDetailForm(itemId){
                return this.selectedItems.some(item => (item.id === itemId))
            },
            categoryTypeColor(categoryTypeId){
                return categoryTypeId === 1 ? 'light-green darken-4' : 'teal darken-2'
            },
            hideEditForm(){
                this.$store.dispatch('hideEditForms')
            },
            submit(){
                this.$Progress.start()
                this.$store.dispatch('addOrderItems', {
                        order_items: this.selectedItems
                    })
            },
        }
    }
</script>

<style scoped>

</style>