import axios from 'axios'
import {vm} from '@/main'

var baseUrl = process.env.VUE_APP_BASE_URL

const users = {
    state: {
        users: null,
        userInputData: null,
        showAddUserForm: false,
        selectedUserForEdit: null,
        userErrorMessages: {
            email: null,
            password: null,
            branches: null
        }
    },

    mutations: {
        setUsers(state, users){
            state.users = users
            state.selectedUserForEdit = null
        },
        selectedUserForEdit(state, user){
            state.selectedUserForEdit = user
        },
        setInputData(state, payload){
            state.userInputData = payload
        },
        showAddUserForm(state){
            state.showAddUserForm = true
        },
        hideAddUserForm(state){
            state.showAddUserForm = false
        },
        setUserErrorMessages(state, error){
            state.userErrorMessages = error
        },
        clearUserErrorMessages(state){
            state.userErrorMessages = {
                email: null,
                password: null,
                branches: null
            }
        }
    },

    actions: {
        getUsers({ commit, rootState, dispatch, state }, page){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/users?page='+page +'&q='+rootState.search)
                    .then(response => {
                        commit('setUsers', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },
        getUserInputData({ commit, rootState, dispatch }){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/users/create')
                    .then(response => {
                        commit('setInputData', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },
        createNewUser({ commit, rootState, dispatch, state }, formData){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/register', formData)
                    .then(response => {
                        dispatch('getUsers')
                        commit('hideAddUserForm')
                        vm.$Progress.finish()
                        commit('clearUserErrorMessages')
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Lietotājs izveidots'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                        }
                        reject(error)
                    })
            })
        },
        updateUser({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.patch(baseUrl +'/api/users/' + state.selectedUserForEdit.id, {
                    name: state.selectedUserForEdit.name,
                    email: state.selectedUserForEdit.email,
                    role_id: state.selectedUserForEdit.role.id,
                    branches: payload.branches,
                    password: payload.password = '' ? null : payload.password
                })
                    .then(response => {
                        dispatch('getUser')
                        vm.$router.push({ path: '/users' })
                        vm.$Progress.finish()
                        commit('clearUserErrorMessages')
                        dispatch('setSnackbar', {
                            color: 'blue',
                            text: 'Lietotājs izlabots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Lietotāju neizdevās izlabot'
                            })
                        }
                        reject(error)
                    })
            })
        },
        deleteUser({commit, rootState, dispatch, state}, userId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.delete(baseUrl +'/api/users/'+userId)
                    .then(response => {
                        dispatch('getUsers')
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Lietotājs dzēsts'
                        })
                        vm.$Progress.finish()
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        getUserForEdit({commit, rootState, dispatch, state}, userId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/users/edit/' + userId)
                    .then(response => {
                        commit('selectedUserForEdit', response.data.user)
                        commit('setInputData', response.data.inputData)
                        vm.$Progress.finish()
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        showAddUserForm({ commit }){
            commit('showAddUserForm')
        },
        hideAddUserForm({ commit }){
            commit('hideAddUserForm')
        },
        clearUserErrorMessages({commit}) {
            commit('clearUserErrorMessages')
        }
    },
    getters: {
        users(state){
            return state.users
        },
        userInputData(state){
            return state.userInputData
        },
        showAddUserForm(state){
            return state.showAddUserForm
        },
        selectedUserForEdit(state){
            return state.selectedUserForEdit
        },
        userErrorMessages(state){
            return state.userErrorMessages
        }
    }
}

export default users