<template>
  <v-container>

    <v-row align="center">
      <v-col sm="2">
        <h1 class="heading blue-grey--text ">Materiāli</h1>
      </v-col>

      <v-spacer/>

      <v-col cols="10" sm="6" md="4">
        <v-text-field
                @input="searchMaterials"
                v-model="search"
                append-icon="mdi-magnify"
                label="Meklēt"
                color="blue-grey darken-1"
                class=""
        />
      </v-col>

      <v-col v-if="!showForm" cols="2" sm="1" >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"  @click="showAddItemForm" small fab text color="blue-grey darken-1" slot="activator" >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Pievienot materiālu</span>
        </v-tooltip>
      </v-col>

      <v-col v-if="showForm" cols="2" sm="1" >
        <v-btn  @click="hideAddItemForm" small outlined fab text color="blue-grey darken-1" slot="activator">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-col>

    </v-row>

    <add-item v-if="showForm" :categoryTypeId="categoryTypeId"/>

    <template v-if="materials">
      <v-card v-for="material in materials.data" :key="material.name" class="py-0 px-4 mb-2" outlined>
        <v-row >
          <v-col cols="6" sm="4">
            <div class="caption grey--text">Nosaukums</div>
            <div class="font-weight-light">{{material.name}}</div>
          </v-col>

          <v-col cols="6" sm="4">
            <div class="caption grey--text">Kategorija</div>
            <div class="font-weight-light">{{material.category.name}}</div>
          </v-col>

            <v-row justify="center" justify-md="end" align-content="center">
              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="editItem(material)" text small slot="activator">
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  </template>
                  <span>Labot materiālu</span>
                </v-tooltip>
              </v-col>

              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="deleteMaterial(material.id)" text small slot="activator" class="align-content-sm-center">
                    <v-icon color="grey">mdi-delete</v-icon>
                  </v-btn>
                  </template>
                  <span>Dzēst materiālu</span>
                </v-tooltip>
              </v-col>
            </v-row>

        </v-row>
      </v-card>

      <v-row justify-content="center">
        <v-col v-if="materials.meta.last_page > 1" >
          <v-pagination
                  v-model="materials.meta.current_page"
                  :length="materials.meta.last_page"
                  :total-visible="5"
                  color="blue-grey "
                  @input="onPageChange"
          />
        </v-col>
      </v-row>
    </template>

    <v-dialog v-model="showEditModal" max-width="700" persistent>
      <edit-category-item :categoryTypeId="categoryTypeId"/>
    </v-dialog>

  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AddItem from "../components/AddItem";
  import EditCategoryItem from "../components/EditCategoryItem";

  export default {
    name: "Materials",
    components: {AddItem, EditCategoryItem},
    data: () => ({
      'categoryTypeId': 3
    }),
    mounted() {
      this.$store.dispatch('hideAddItemForm')
      this.$store.dispatch('resetSearch')
      this.$store.dispatch('getItems', {
        categoryTypeId: this.categoryTypeId
      })
    },
    computed: {
      ...mapGetters({
        materials: 'items',
        search: 'search',
        showForm: 'showAddItemForm',
        showEditModal: 'showEditModal',
      })
    },
    methods: {
      editItem(item){
        this.$store.dispatch('showEditModal', item)
      },
      searchMaterials(value){
        this.$store.dispatch('changeSearch', value)
        this.$store.dispatch('getItems', {
          categoryTypeId: this.categoryTypeId,
        })
      },
      onPageChange() {
        this.$store.dispatch('getItems', {
                  categoryTypeId: this.categoryTypeId,
                  currentPage: this.materials.meta.current_page
                }
        );
      },
      showAddItemForm(){
        this.$store.dispatch('showAddItemForm')
      },
      hideAddItemForm(){
        this.$store.dispatch('hideAddItemForm')
      },
      deleteMaterial(materialId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteItem', {
            itemId : materialId,
            categoryTypeId: this.categoryTypeId
          })
        }
      }



    }
  }
</script>
