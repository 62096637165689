<template>
    <v-card flat class="mb-3" outlined>
            <v-row justify="center" no-gutters>
                <v-col cols="12" sm="8">
                    <v-card flat class="mt-3 px-2" v-if="inputData">
                        <v-form ref="form">
                            <v-row no-gutters class="mt-10">
                                <v-col cols="12" md="6">
                                    <v-select
                                            class="pa-3"
                                            v-model="occupation"
                                            color="blue-grey darken-1"
                                            item-text="name"
                                            return-object
                                            :items="inputData.occupations"
                                            :rules="formRules"
                                            label="Nodarbošanās"
                                            outlined
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                            class="pa-3"
                                            outlined
                                            v-model="name"
                                            label="Nosaukums"
                                            color="blue-grey darken-1"
                                            :rules="formRules"
                                            required>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                            class="pa-3"
                                            outlined
                                            v-model="phone"
                                            label="Tel. nr"
                                            color="blue-grey darken-1"
                                            >
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                            class="pa-3"
                                            outlined
                                            v-model="email"
                                            label="E-pasts"
                                            color="blue-grey darken-1"
                                            >
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" >
                                    <v-text-field
                                            class="pa-3"
                                            outlined
                                            v-model="description"
                                            label="Piezīmes"
                                            color="blue-grey darken-1"
                                            >
                                    </v-text-field>
                                </v-col>
                            </v-row>


                            <v-row row justify="center" justify-md="end" no-gutters class="mb-4">
                                <v-btn  text @click="hideAddServiceProviderForm" dark color="deep-orange lighten-1">Atcelt</v-btn>
                                <v-btn  text @click="submit" dark color="green accent-4">Pievienot</v-btn>
                            </v-row>

                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
    </v-card>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "add-service-provider",
        data: () => ({
            name: '',
            occupation: '',
            phone: '',
            email: '',
            description: '',
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),
        mounted() {
            this.$store.dispatch('getServiceProviderInputData')
        },
        computed: {
            ...mapGetters({
                inputData: 'serviceProviderInputData'
            })
        },
        methods: {
            hideAddServiceProviderForm(){
                this.$store.dispatch('hideAddServiceProviderForm')
            },
            submit(){
                this.$Progress.start()
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('createNewServiceProvider', {
                        name: this.name,
                        occupation_id: this.occupation.id,
                        phone: this.phone,
                        email: this.email,
                        description: this.description,
                    })
                }  else {
                    this.$Progress.fail()
                }
            },
        }
    }
</script>

<style scoped>

</style>