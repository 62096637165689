<template>
  <v-container>

   <h1>Not found</h1>

  </v-container>
</template>

<script>


  export default {
    name: "NotFound",
  }
</script>
