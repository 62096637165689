<template>
    <v-card flat class="mb-3" outlined>
        <v-row justify="center" no-gutters>
            <v-col cols="12" sm="8">
                <v-card flat class="mt-3 px-2" >
                    <v-form ref="form">
                        <v-row no-gutters class="mt-10">
                            <v-col cols="12" >
                                <v-text-field
                                        dense
                                        class="px-3 py-1"
                                        v-model="name"
                                        label="Nosaukums"
                                        color="blue-grey darken-1"
                                        :rules="formRules"
                                        outlined
                                        required>
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row row justify="center" justify-md="end" no-gutters class="mb-4">
                            <v-btn  text @click="hideAddFuneralLocationsPlaceForm" dark color="deep-orange lighten-1">Atcelt</v-btn>
                            <v-btn  text @click="submit" dark color="green accent-4">Pievienot</v-btn>
                        </v-row>

                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "add-funeral-locations-place",
        data: () => ({
            showPassword: false,
            name: '',
            address: '',
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),
        mounted() {
        },
        computed: {
            ...mapGetters({
            })
        },
        methods: {
            hideAddFuneralLocationsPlaceForm(){
                this.$store.dispatch('hideAddFuneralLocationsPlaceForm')
            },
            submit(){
                this.$Progress.start()
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('createNewFuneralLocationsPlace', {
                        name: this.name,
                    })
                }  else {
                    this.$Progress.fail()
                }
            },
        }
    }
</script>

<style scoped>

</style>