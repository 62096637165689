import axios from 'axios'
import {vm} from '@/main'

var baseUrl = process.env.VUE_APP_BASE_URL

const auth = {
    state: {
        token: localStorage.getItem('access_token') || null,
        user: null,
        authErrorStatus: false,
        authErrorMessage: null
    },
    mutations: {
        setToken (state, token){
            state.token = token
        },
        setUser(state, user){
            state.user = user
        },
        destroyToken(state){
            state.token = null
            state.user = null
        },
        setAuthErrorMessage(state, message){
            state.authErrorMessage = message
            state.authErrorStatus = true
        },
        resetErrors(state){
            state.authErrorStatus = false
            state.authErrorMessage = null
        }
    },
    actions: {
        getToken({ commit, dispatch }, credentials) {

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/login', {
                    email: credentials.email,
                    password: credentials.password
                })
                    .then(response => {
                        const token = response.data.access_token

                        localStorage.setItem('access_token', token)
                        commit('resetErrors')
                        commit('setToken', token)
                        dispatch('getUser')
                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 403) {
                            commit('setAuthErrorMessage', error.response.data.message)
                        }
                        reject(error)
                    })
            })
        },
        destroyToken({ state, getters, commit }){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token

            if (getters.loggedIn) {

                return new Promise((resolve, reject) => {

                    axios.get(baseUrl +'/api/logout')
                        .then(response => {
                            localStorage.removeItem('access_token')
                            commit('destroyToken')
                            vm.$router.push("/login")
                            resolve(response)
                        })
                        .catch(error => {
                            localStorage.removeItem('access_token')
                            commit('destroyToken')
                            vm.$router.push("/login")
                            reject(error)
                        })
                })
            }
        },
        getUser({ commit, state, dispatch }){
            if(state.token == null){
                return
            }

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/user')
                    .then(response => {
                        commit('setUser', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        }
    },
    getters: {
        loggedIn(state) {
            return state.token !== null
        },
        user(state){
            return state.user
        },
        userFunction(state){
            return function(){
                return state.user;
            }
        },
        authErrorStatus(state){
            return state.authErrorStatus
        },
        authErrorMessage(state){
            return state.authErrorMessage
        }
    }
}

export default auth