<template>
    <v-card flat class="mb-3" outlined>
            <v-row justify="center" no-gutters>
                <v-col cols="12" sm="8">
                    <v-card flat class="mt-3 px-2" >
                        <v-form ref="form">
                            <v-row no-gutters class="mt-10">
                                    <v-col cols="12" sm="8">
                                        <v-text-field
                                                class="pa-3"
                                                v-model="name"
                                                label="Nosaukums"
                                                color="blue-grey darken-1"
                                                :rules="formRules"
                                                outlined
                                                required>
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                                class="pa-3"
                                                v-model="regNr"
                                                label="Reģ. nr. / PK"
                                                color="blue-grey darken-1"
                                                outlined
                                        >
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                                class="pa-3"
                                                v-model="phone"
                                                label="Tel. nr"
                                                color="blue-grey darken-1"
                                                outlined
                                        >
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                                class="pa-3"
                                                v-model="email"
                                                label="E-pasts"
                                                color="blue-grey darken-1"
                                                outlined
                                        >
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-text-field
                                                class="pa-3"
                                                v-model="address"
                                                label="Adrese"
                                                color="blue-grey darken-1"
                                                outlined
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row row justify="center" justify-md="end" no-gutters class="mb-4">
                                    <v-btn  text @click="hideAddCustomerForm" dark color="deep-orange lighten-1">Atcelt</v-btn>
                                    <v-btn  text @click="submit" dark color="green accent-4">Pievienot</v-btn>
                                </v-row>

                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
    </v-card>
</template>

<script>

    export default {
        name: "add-customer",
        data: () => ({
            name: '',
            regNr: '',
            address: '',
            phone: '',
            email: '',
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),

        methods: {
            hideAddCustomerForm(){
                this.$store.dispatch('hideAddCustomerForm')
            },
            submit(){
                this.$Progress.start()
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('createNewCustomer', {
                        name: this.name,
                        reg_nr: this.regNr,
                        address: this.address,
                        phone: this.phone,
                        email: this.email,
                    })
                }  else {
                    this.$Progress.fail()
                }
            },
        }
    }
</script>

<style scoped>

</style>