import axios from 'axios'
import {vm} from '@/main'

var baseUrl = process.env.VUE_APP_BASE_URL

const funeralLocations = {
    state: {
        funeralLocations: null,
        funeralLocationsInputData: null,
        showAddFuneralLocationForm: false
    },

    mutations: {
        setFuneralLocations(state, funeralLocations){
            state.funeralLocations = funeralLocations
        },
        setInputData(state, payload){
            state.funeralLocationsInputData = payload
        },
        showAddFuneralLocationForm(state){
            state.showAddFuneralLocationForm = true
        },
        hideAddFuneralLocationForm(state){
            state.showAddFuneralLocationForm = false
        }
    },

    actions: {
        getFuneralLocations({ commit, rootState, dispatch, state }, page){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/funeral_location?page='+page +'&q='+rootState.search)
                    .then(response => {
                        commit('setFuneralLocations', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        createNewFuneralLocation({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/funeral_location', payload

                )
                    .then(response => {
                        dispatch('getFuneralLocations')
                        commit('hideAddFuneralLocationForm')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Apbedīšanas vieta izveidota'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Ierakstu neizdevās izveidot'
                        })
                        vm.$Progress.fail()
                        reject(error)
                    })
            })
        },
        updateFuneralLocation({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.patch(baseUrl +'/api/funeral_location/' + rootState.editableItem.id, payload)
                    .then(response => {
                        dispatch('getFuneralLocations')
                        dispatch('hideEditModal')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'blue',
                            text: 'Ieraksts izlabots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izlabot'
                            })
                        }
                        reject(error)
                    })
            })
        },

        deleteFuneralLocation({commit, rootState, dispatch, state}, funeralLocationId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.delete(baseUrl +'/api/funeral_location/'+funeralLocationId)
                    .then(response => {
                        dispatch('getFuneralLocations')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Apbedīšanas vieta dzēsta'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        showAddFuneralLocationForm({ commit }){
            commit('showAddFuneralLocationForm')
        },
        hideAddFuneralLocationForm({ commit }){
            commit('hideAddFuneralLocationForm')
        },
    },
    getters: {
        allFuneralLocations(state){
            return state.funeralLocations
        },
        funeralLocationsInputData(state){
            return state.funeralLocationsInputData
        },
        showAddFuneralLocationForm(state){
            return state.showAddFuneralLocationForm
        }
    }
}

export default funeralLocations