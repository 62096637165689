<template>

  <v-container v-if="selectedUserForEdit">
    <v-row align="center">
      <v-col >
        <h1 class="heading blue-grey--text ">Labot lietotāju {{selectedUserForEdit.name}}</h1>
      </v-col>
    </v-row>

    <v-card flat outlined v-if="inputData">
      <v-row no-gutters class="mt-10" justify="center">
        <v-col cols="12" sm="8" md="5" class="">
          <v-form ref="form">
            <v-col cols="12" class="py-1">
              <v-text-field
                      dense
                      class="px-3"
                      v-model="selectedUserForEdit.name"
                      label="Lietotāja vārds"
                      color="blue-grey darken-1"
                      :rules="formRules"
                      outlined
                      required>
              </v-text-field>
            </v-col>

            <v-col cols="12" class="py-1">
              <v-text-field
                      dense
                      class="px-3"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      v-model="password"
                      label="Parole"
                      color="blue-grey darken-1"
                      outlined
                      :error-messages="errorMessages.password"
                      required>
              </v-text-field>
            </v-col>

            <v-col cols="12" class="py-1">
              <v-text-field
                      dense
                      class="px-3"
                      v-model="selectedUserForEdit.email"
                      label="E-pasts"
                      color="blue-grey darken-1"
                      :rules="formRules"
                      outlined
                      required
                      :error-messages="errorMessages.email"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" class="py-1">
              <v-select
                      dense
                      class="px-3"
                      v-model="selectedUserForEdit.role"
                      color="blue-grey darken-1"
                      item-text="display_name"
                      return-object
                      :items="inputData.roles"
                      :rules="formRules"
                      label="Loma"
                      outlined
              />
            </v-col>

              <v-col cols="12" >
                  <v-select
                      dense
                      class="px-3 py-1"
                      v-model="branches"
                      color="blue-grey darken-1"
                      item-text="name"
                      item-value="id"
                      :items="inputData.branches"
                      label="Filiāles"
                      multiple
                      :error-messages="errorMessages.branches"
                      small-chips
                      deletable-chips
                      outlined
                  />
              </v-col>

            <v-row row justify="center" justify-md="end" no-gutters class="mb-4">
              <v-btn  text @click="goBack" dark color="deep-orange lighten-1">Atcelt</v-btn>
              <v-btn  text @click="submit" dark color="blue">Labot</v-btn>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "edit-user",
    data: () => ({
      password: '',
        branches: [],
      showPassword: false,
      formRules: [
        v => !!v || 'Šis lauks ir obligāts',
      ],
    }),
    created() {
      this.$store.dispatch('getUserForEdit', this.$route.params.userId)
          .then(response =>{
              this.branches = this.selectedUserForEdit.branches.map(branch => {
                  return branch.id
              })
          })
    },
    computed: {
      ...mapGetters({
        inputData: 'userInputData',
        selectedUserForEdit: 'selectedUserForEdit',
        errorMessages: 'userErrorMessages'
      })
    },
    methods: {
      goBack(){
        this.$store.dispatch('clearUserErrorMessages')
        this.$router.push({ path: '/users' })
      },
      submit(){
        this.$Progress.start()
        if (this.$refs.form.validate()) {
          this.$store.dispatch('updateUser', {
              password: this.password,
              branches: this.branches
          })
        }  else {
          this.$Progress.fail()
        }
      },
    }
  }
</script>

<style scoped>

</style>