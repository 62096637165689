<template>
  <v-container>

    <v-row align="center">
      <v-col sm="2">
        <h1 class="heading blue-grey--text ">Klienti</h1>
      </v-col>

      <v-spacer/>

      <v-col cols="10" sm="6" md="4">
        <v-text-field
                @input="searchCustomers"
                v-model="search"
                append-icon="mdi-magnify"
                label="Meklēt"
                color="blue-grey darken-1"
                class=""
       />
      </v-col>

      <v-col v-if="!showForm" cols="2" sm="1" >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"  @click="showAddCustomerForm" small fab text color="blue-grey darken-1" slot="activator" >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Pievienot klientu</span>
        </v-tooltip>
      </v-col>

      <v-col v-if="showForm" cols="2" sm="1" >
        <v-btn  @click="hideAddCustomerForm" small outlined fab text color="blue-grey darken-1" slot="activator">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-col>

    </v-row>

    <add-customer v-if="showForm"/>

    <template v-if="customers">
      <v-card v-for="customer in customers.data" :key="customer.id" class="py-0 px-4 mb-2" outlined>
        <v-row >
          <v-col cols="6" sm="2">
            <div class="caption grey--text">Nosaukums</div>
            <div class="font-weight-light">{{customer.name}}</div>
          </v-col>

          <v-col cols="6" sm="2">
            <div class="caption grey--text">Tel. nr</div>
            <div class="font-weight-light">{{customer.phone}}</div>
          </v-col>

          <v-col cols="6" sm="2">
            <div class="caption grey--text">E-pasts</div>
            <div class="font-weight-light">{{customer.email}}</div>
          </v-col>

          <v-col cols="6" sm="2">
            <div class="caption grey--text">Adrese</div>
            <div class="font-weight-light">{{customer.address}}</div>
          </v-col>


            <v-row justify="center" justify-md="end" align-content="center">
              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="editItem(customer)" text small slot="activator">
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  </template>
                  <span>Labot klientu</span>
                </v-tooltip>
              </v-col>

              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="deleteCustomer(customer.id)" text small slot="activator" class="align-content-sm-center">
                    <v-icon color="grey">mdi-delete</v-icon>
                  </v-btn>
                  </template>
                  <span>Dzēst klientu</span>
                </v-tooltip>
              </v-col>
            </v-row>

        </v-row>
      </v-card>

      <v-row justify-content="center">
        <v-col v-if="customers.meta.last_page > 1" >
          <v-pagination
                  v-model="customers.meta.current_page"
                  :length="customers.meta.last_page"
                  :total-visible="5"
                  color="blue-grey "
                  @input="onPageChange"
          />
        </v-col>
      </v-row>
    </template>

    <v-dialog v-model="showEditModal" max-width="700" persistent>
      <edit-customer/>
    </v-dialog>

  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AddCustomer from "../components/AddCustomer";
  import EditCustomer from "../components/EditCustomer";

  export default {
    name: "Customers",
    components: {AddCustomer, EditCustomer},
    data: () => ({
    }),
    mounted() {
      this.$store.dispatch('hideEditModal')
      this.$store.dispatch('hideAddCustomerForm')
      this.$store.dispatch('resetSearch')
      this.$store.dispatch('getCustomers')
    },
    computed: {
      ...mapGetters({
        customers: 'customers',
        search: 'search',
        showForm: 'showAddCustomerForm',
        showEditModal: 'showEditModal',
      })
    },
    methods: {
      editItem(item){
        this.$store.dispatch('showEditModal', item)
      },
      searchCustomers(value){
        this.$store.dispatch('changeSearch', value)
        this.$store.dispatch('getCustomers')
      },
      onPageChange() {
        this.$store.dispatch('getCustomers', this.customers.meta.current_page);
      },
      showAddCustomerForm(){
        this.$store.dispatch('showAddCustomerForm')
      },
      hideAddCustomerForm(){
        this.$store.dispatch('hideAddCustomerForm')
      },
      deleteCustomer(customerId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteCustomer', customerId)
        }
      }



    }
  }
</script>
