<template>
  <v-container>

    <v-row align="center">
      <v-col sm="2">
        <h1 class="heading blue-grey--text ">Lietotāji</h1>
      </v-col>

      <v-spacer/>

      <v-col cols="10" sm="6" md="4">
        <v-text-field
                @input="searchMaterials"
                v-model="search"
                append-icon="mdi-magnify"
                label="Meklēt"
                color="blue-grey darken-1"
                class=""
        />
      </v-col>

      <v-col v-if="!showForm" cols="2" sm="1" >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"  @click="showAddUserForm" small fab text color="blue-grey darken-1" slot="activator" >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Pievienot lietotāju</span>
        </v-tooltip>
      </v-col>

      <v-col v-if="showForm" cols="2" sm="1" >
        <v-btn  @click="hideAddUserForm" small outlined fab text color="blue-grey darken-1" slot="activator">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-col>

    </v-row>

    <add-user v-if="showForm"/>

    <template v-if="users">
      <v-card v-for="user in users.data" :key="user.id" class="py-0 px-4 mb-2" outlined>
        <v-row >
          <v-col cols="6" sm="3">
            <div class="caption grey--text">Lietotājs</div>
            <div class="font-weight-light">{{user.name}}</div>
          </v-col>

          <v-col cols="6" sm="3">
            <div class="caption grey--text">E-pasts</div>
            <div class="font-weight-light">{{user.email}}</div>
          </v-col>

          <v-col cols="6" sm="2">
            <div class="caption grey--text">Loma</div>
            <div class="font-weight-light">{{user.role.display_name}}</div>
          </v-col>

          <v-row justify="center" justify-md="end" align-content="center">
            <v-col cols="2">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="editUser(user.id)" text small slot="activator">
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Rediģēt lietotāju</span>
              </v-tooltip>
            </v-col>

            <v-col cols="2">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="deleteUser(user.id)" text small slot="activator" class="align-content-sm-center">
                    <v-icon color="grey">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Dzēst lietotāju</span>
              </v-tooltip>
            </v-col>
          </v-row>

        </v-row>
      </v-card>

      <v-row justify-content="center">
        <v-col v-if="users.meta.last_page > 1" >
          <v-pagination
                  v-model="users.meta.current_page"
                  :length="users.meta.last_page"
                  :total-visible="5"
                  color="blue-grey "
                  @input="onPageChange"
          />
        </v-col>
      </v-row>
    </template>

  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AddUser from "../components/AddUser";
  import EditUser from "../views/EditUser";

  export default {
    name: "Users",
    components: {
      AddUser,
      EditUser
    },
    data: () => ({}),
    mounted() {
      this.$store.dispatch('hideAddUserForm')
      this.$store.dispatch('resetSearch')
      this.$store.dispatch('getUsers')
    },
    computed: {
      ...mapGetters({
        users: 'users',
        search: 'search',
        showForm: 'showAddUserForm'
      })
    },
    methods: {
      searchMaterials(value){
        this.$store.dispatch('changeSearch', value)
        this.$store.dispatch('getUsers')
      },
      onPageChange() {
        this.$store.dispatch('getUsers', this.users.meta.current_page);
      },
      showAddUserForm(){
        this.$store.dispatch('showAddUserForm')
      },
      hideAddUserForm(){
        this.$store.dispatch('hideAddUserForm')
      },
      editUser(userId){
        this.$router.push({ path: '/users/edit/' + userId })
      },
      deleteUser(userId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteUser', userId)

        }
      }
    }
  }
</script>
