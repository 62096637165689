import axios from 'axios'
import {vm} from '@/main'

var baseUrl = process.env.VUE_APP_BASE_URL

const invoices = {
    state: {
        invoice: null,
        showEditInvoiceDetailsForm : false,
        showAddInvoiceItemsForm : false,
    },

    mutations: {
        setSingleInvoice(state, invoice) {
            state.invoice = invoice
        },
        clearInvoice(state) {
            state.invoice = null
        },
        showEditInvoiceDetailsForm(state){
            state.showEditInvoiceDetailsForm = true
        },
        showAddInvoiceItemsForm(state){
            state.showAddInvoiceItemsForm = true
        },
        hideInvoiceEditForms(state){
            state.showEditInvoiceDetailsForm = false
            state.showAddInvoiceItemsForm = false
        }

    },

    actions: {

        getSingleInvoice({ commit, rootState, dispatch, state }, invoiceId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/invoices/' + invoiceId)
                    .then(response => {
                        commit('setSingleInvoice', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        clearSingleInvoice({commit}){
            commit('hideInvoiceEditForms')
            commit('clearInvoice')
        },

        updateInvoiceDetails({dispatch, state}){
            dispatch('updateInvoice', {
                branch_id: state.order.branch.id,
                order_date: state.order.order_date,
                agency_rate: state.order.agency_rate,
                advance_payment: state.order.advance_payment,
                social_benefit: state.order.social_benefit
            })
        },

        updateInvoice({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.patch(baseUrl +'/api/invoices/' + state.invoice.id, payload)
                    .then(response => {
                        dispatch('hideInvoiceEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'blue',
                            text: 'Ieraksts izlabots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izlabot'
                            })
                        }
                        reject(error)
                    })
            })
        },



        updateInvoiceItem({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.patch(baseUrl +'/api/' + state.invoice.id + '/invoice_items/' + rootState.editableItem.id, payload)
                    .then(response => {
                        dispatch('getSingleInvoice', state.invoice.id)
                        dispatch('hideEditModal')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Ieraksts izlabots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izlabot'
                            })
                        }
                        reject(error)
                    })
            })
        },


        addInvoiceItems({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/' + state.invoice.id + '/invoice_items/' , payload)
                    .then(response => {
                        dispatch('hideInvoiceEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Ieraksts izveidots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izveidot'
                            })
                        }
                        reject(error)
                    })
            })
        },

        deleteInvoiceItem({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.delete(baseUrl +'/api/' + payload.invoiceId +'/invoice_items/' + payload.invoiceItemId )
                    .then(response => {
                        dispatch('hideInvoiceEditForms')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Ieraksts dzēsts'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās dzēst'
                            })
                        }
                        reject(error)
                    })
            })
        },

        showAddInvoiceItemsForm({commit}){
            commit('showAddInvoiceItemsForm')
        },
        showEditInvoiceDetailsForm({commit}){
            commit('showEditInvoiceDetailsForm')
        },
        hideInvoiceEditForms({commit, dispatch, state}){
            dispatch('getSingleInvoice', state.invoice.id)
            commit('hideInvoiceEditForms')
        },

    },
    getters: {

        invoice(state) {
            return state.invoice
        },
        showEditInvoiceDetailsForm(state){
            return state.showEditInvoiceDetailsForm
        },
        showAddInvoiceItemsForm(state){
            return state.showAddInvoiceItemsForm
        },

    }
}

export default invoices