<template>
    <v-row justify="center" no-gutters class="px-2">
        <template v-if="inputData">
                <v-col cols="12">
                        <v-form ref="form">
                            <v-row no-gutters class="mt-2">
                                <v-col cols="6">
                                    <v-select
                                            label="Glabāšanas vieta"
                                            v-model="funeral.funeral_location_id"
                                            :items="inputData.funeralLocations"
                                            item-text="name"
                                            item-value="id"
                                            color="blue-grey darken-1"
                                            class="px-4"
                                    >
                                    </v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                            label="Ceremonijas vieta"
                                            v-model="funeral.funeral_locations_place_id"
                                            :items="inputData.funeralLocationsPlaces"
                                            item-text="name"
                                            item-value="id"
                                            color="blue-grey darken-1"
                                            class="px-4"
                                    >
                                    </v-select>
                                </v-col>

                                <v-row no-gutters>
                                    <v-col cols="6" >
                                        <v-menu
                                                v-model="showPicker"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        color="blue-grey darken-1"
                                                        class="px-4"
                                                        v-model="funeral.funeral_date"
                                                        label="Bēru datums"
                                                        hide-details
                                                        readonly
                                                        v-on="on"
                                                />
                                            </template>
                                            <v-date-picker v-model="funeral.funeral_date" color="blue-grey lighten-1"  @input="showPicker = false"/>
                                        </v-menu>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-text-field type="time" v-model="funeral.start_time" label="Ceremonijas sākums" class="px-4" color="blue-grey darken-1"/>
                                    </v-col>
                                </v-row>
                            </v-row>


                                <v-row row justify="center" justify-md="end" no-gutters class="">
                                    <v-btn  text @click="hideEditForm" dark color="deep-orange lighten-1">Atcelt</v-btn>
                                    <v-btn  text @click="submit" dark color="green accent-3">Pievienot</v-btn>
                                </v-row>
                        </v-form>
                </v-col>
        </template>
    </v-row>
</template>

<script>

    import {mapGetters} from "vuex";

    export default {
        name: "add-funeral",
        data: () => ({
            showPicker: false,
            funeral: {},
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),
        mounted() {
            this.$store.dispatch('getEditOrderInputData')
        },
        computed: {
            ...mapGetters({
                inputData: 'editOrderInputData',
                order: 'order'
            })
        },
        methods: {
            hideEditForm(){
                this.$store.dispatch('hideEditForms')
            },
            submit(){
                this.$Progress.start()
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('addNewFuneralToOrder', this.funeral)
                }  else {
                    this.$Progress.fail()
                }
            },
        }
    }
</script>

<style scoped>

</style>