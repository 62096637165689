<template>
    <v-card>

        <v-card-text v-if="inputData">
            <v-form ref="form" >
                <v-row no-gutters class="mt-2">
                    <v-col cols="12" sm="6">
                        <v-select
                                dense
                                class="px-3 py-1"
                                @change="clearSelectedServiceProvider"
                                v-model="selectedOccupation"
                                color="blue-grey darken-1"
                                item-text="name"
                                :items="inputData.occupations"
                                :rules="formRules"
                                label="Kategorija"
                                return-object
                                outlined
                        />
                    </v-col>
                    <v-col cols="12" sm="6" v-if="selectedOccupation">
                        <v-select
                                dense
                                class="px-3 py-1"
                                v-model="selectedServiceProvider"
                                color="blue-grey darken-1"
                                item-text="name"
                                :items="selectedOccupation.service_providers"
                                :rules="formRules"
                                label="Pakalpojumu sniedzējs"
                                return-object
                                outlined
                        />
                    </v-col>

                </v-row>

                <v-row row justify="center" justify-md="end" no-gutters class="">
                    <v-btn  text @click="hideEditForm" dark color="deep-orange lighten-1">Atcelt</v-btn>
                    <v-btn v-if="selectedServiceProvider"  text @click="submit" dark color="green">Pievienot</v-btn>
                </v-row>
            </v-form>


        </v-card-text>

    </v-card>

</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "add-order-service-provider",
        props: ['orderId'],
        data: () => ({
            selectedOccupation: null,
            selectedServiceProvider: null,
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),
        mounted() {
            this.$store.dispatch('getOrderServiceProviderInputData', this.orderId)
        },
        computed: {
            ...mapGetters({
                inputData: 'addOrderServiceProviderInputData',
            })
        },
        methods: {
            clearSelectedServiceProvider(){
              this.selectedServiceProvider = null
            },
            hideEditForm(){
                this.$store.dispatch('hideEditForms')
            },
            submit(){
                this.$Progress.start()
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('addOrderServiceProvider', {
                      service_provider_id: this.selectedServiceProvider.id,
                    })
                }  else {
                    this.$Progress.fail()
                }
            },
        }
    }
</script>

<style scoped>

</style>