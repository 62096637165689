<template>
  <v-app>
    <navigation v-if="loggedIn" :links="links" :user="user"/>

    <v-content class="blue-grey lighten-5">
      <v-container fluid >
          <router-view/>
      </v-container>
      <vue-progress-bar/>

      <v-snackbar
              v-model="snackbar"
              :color="color"
              :timeout="timeout"
              right
      >
        {{ text }}
        <v-btn dark
               text
               @click="clearSnackbar"
        >X</v-btn>
      </v-snackbar>

    </v-content>
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation";
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
    Navigation
  },
  created(){
    this.$store.dispatch('getUser')
  },
  data: () => ({
    links: [
      { icon: 'mdi-currency-usd', text: 'Pasūtījumi', route: '/orders', permission: ''},
      { icon: 'mdi-toolbox-outline', text: 'Pakalpojumi', route: '/services', permission: ''},
      { icon: 'mdi-apps-box', text: 'Materiāli', route: '/materials', permission: '' },
      { icon: 'mdi-apps-box', text: 'Preces', route: '/products', permission: '' },
      { icon: 'mdi-contacts', text: 'Klienti', route: '/customers', permission: '' },
      { icon: 'mdi-folder-plus-outline', text: 'Kategorijas', route: '/categories', permission: '' },
      { icon: 'mdi-folder-plus-outline', text: 'Pakalpojumu sniedzēji', route: '/service_providers', permission: '' },
      { icon: 'mdi-map-marker', text: 'Apglabāšanas vietas', route: '/funeral_locations', permission: '' },
      { icon: 'mdi-map-marker', text: 'Ceremoniju vietas', route: '/funeral_locations_places', permission: '' },
      { icon: 'mdi-account-multiple', text: 'Lietotāji', route: '/users', permission: 'manage users' },
    ],
  }),
  computed: {
    ...mapGetters({
      loggedIn: 'loggedIn',
      user: 'user',
      snackbar: 'snackbar',
      color: 'snackbarColor',
      timeout: 'snackbarTimeout',
      text: 'snackbarText',
    }),
  },
  methods:{
    clearSnackbar(){
      this.$store.dispatch('clearSnackbar')
    }
  }
};
</script>
