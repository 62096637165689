<template>
    <v-card flat class="mb-3" outlined>
            <v-row justify="center" no-gutters>
                <v-col cols="12">
                    <v-card flat class="mt-3 px-2" v-if="inputData">
                        <v-form ref="form">
                            <v-row no-gutters class="mt-10">
                                <v-col cols="12" md="4">
                                    <v-select
                                            class="pa-3"
                                            v-model="categoryType"
                                            color="blue-grey darken-1"
                                            item-text="display_name"
                                            item-value="id"
                                            :items="inputData.categoryTypes"
                                            :rules="formRules"
                                            label="Kategorijas veids"
                                            outlined
                                    />
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-text-field
                                            class="pa-3"
                                            outlined
                                            v-model="name"
                                            label="Nosaukums"
                                            color="blue-grey darken-1"
                                            :rules="formRules"
                                            required>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" md="4" class="pa-3">
                                    <v-radio-group v-model="agency_costs" row label="Aģentūras izdevumi" class="pa-0">
                                        <v-radio label="Jā" :value="1"/>
                                        <v-radio label="Nē" :value="0"/>
                                    </v-radio-group>
                                </v-col>
                            </v-row>


                            <v-row row justify="center" justify-md="end" no-gutters class="mb-4">
                                <v-btn  text @click="hideAddCategoryForm" dark color="deep-orange lighten-1">Atcelt</v-btn>
                                <v-btn  text @click="submit" dark color="green accent-4">Pievienot</v-btn>
                            </v-row>

                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
    </v-card>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "add-service",
        data: () => ({
            name: '',
            categoryType: '',
            agency_costs: 1,
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),
        mounted() {
            this.$store.dispatch('getCategoryInputData')
        },
        computed: {
            ...mapGetters({
                inputData: 'categoryInputData'
            })
        },
        methods: {
            hideAddCategoryForm(){
                this.$store.dispatch('hideAddCategoryForm')
            },
            submit(){
                this.$Progress.start()
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('createNewCategory', {
                        name: this.name,
                        category_type_id: this.categoryType,
                        agency_costs: this.agency_costs,
                    })
                }  else {
                    this.$Progress.fail()
                }
            },
        }
    }
</script>

<style scoped>

</style>