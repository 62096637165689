<template>
  <v-container>

    <v-row align="center">
      <v-col sm="2">
        <h1 class="heading blue-grey--text ">Kategorijas</h1>
      </v-col>

      <v-spacer/>

      <v-col cols="10" sm="6" md="4">
        <v-text-field
                @input="searchCategories"
                v-model="search"
                append-icon="mdi-magnify"
                label="Meklēt"
                color="blue-grey darken-1"
                class=""
        />
      </v-col>

      <v-col v-if="!showForm" cols="2" sm="1" >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"  @click="showAddCategoryForm" small fab text color="blue-grey darken-1" slot="activator" >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Pievienot kategoriju</span>
        </v-tooltip>
      </v-col>

      <v-col v-if="showForm" cols="2" sm="1" >
        <v-btn  @click="hideAddCategoryForm" small outlined fab text color="blue-grey darken-1" slot="activator">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-col>

    </v-row>

    <add-category v-if="showForm"/>

    <template v-if="categories">
      <v-card v-for="category in categories.data" :key="category.id" class="py-0 px-4 mb-2" outlined>
        <v-row >
          <v-col cols="6" sm="3">
            <div class="caption grey--text">Nosaukums</div>
            <div class="font-weight-light">{{category.name}}</div>
          </v-col>

          <v-col cols="6" sm="3">
            <div class="caption grey--text">Tips</div>
            <div class="font-weight-light">{{category.category_type.display_name}}</div>
          </v-col>

          <v-col cols="6" sm="2">
            <div class="caption grey--text">Aģentūras izdevumi</div>
            <div class="font-weight-light">{{category.agency_costs ? 'Jā' : 'Nē'}}</div>
          </v-col>

            <v-row justify="center" justify-md="end" align-content="center">
              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="editItem(category)" text small slot="activator">
                    <v-icon color="grey">mdi-pencil</v-icon>
                  </v-btn>
                  </template>
                  <span>Labot kategoriju</span>
                </v-tooltip>
              </v-col>

              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="deleteCategory(category.id)" text small slot="activator" class="align-content-sm-center">
                    <v-icon color="grey">mdi-delete</v-icon>
                  </v-btn>
                  </template>
                  <span>Dzēst kategoriju</span>
                </v-tooltip>
              </v-col>
            </v-row>

        </v-row>
      </v-card>

      <v-row justify-content="center">
        <v-col v-if="categories.meta.last_page > 1" >
          <v-pagination
                  v-model="categories.meta.current_page"
                  :length="categories.meta.last_page"
                  :total-visible="5"
                  color="blue-grey "
                  @input="onPageChange"
          />
        </v-col>
      </v-row>
    </template>

    <v-dialog v-model="showEditModal" max-width="700" persistent>
      <edit-category/>
    </v-dialog>

  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AddCategory from "../components/AddCategory";
  import EditCategory from "../components/EditCategory";

  export default {
    name: "Categories",
    components: {AddCategory, EditCategory},
    data: () => ({
    }),
    mounted() {
      this.$store.dispatch('hideEditModal')
      this.$store.dispatch('hideAddCategoryForm')
      this.$store.dispatch('resetSearch')
      this.$store.dispatch('getCategories')
    },
    computed: {
      ...mapGetters({
        categories: 'categories',
        search: 'search',
        showForm: 'showAddCategoryForm',
        showEditModal: 'showEditModal',
      })
    },
    methods: {
      editItem(item){
        this.$store.dispatch('showEditModal', item)
      },
      searchCategories(value){
        this.$store.dispatch('changeSearch', value)
        this.$store.dispatch('getCategories')
      },
      onPageChange() {
        this.$store.dispatch('getCategories', this.categories.meta.current_page);
      },
      showAddCategoryForm(){
        this.$store.dispatch('showAddCategoryForm')
      },
      hideAddCategoryForm(){
        this.$store.dispatch('hideAddCategoryForm')
      },
      deleteCategory(categoryId){
        if(confirm('Vai esi drošs?')){
          this.$Progress.start()
          this.$store.dispatch('deleteCategory', categoryId)
        }
      }



    }
  }
</script>
