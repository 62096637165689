<template>
    <v-card flat class="mb-3" outlined>
            <v-row justify="center" no-gutters>
                <v-col cols="12" sm="8">
                    <v-card flat class="mt-3 px-2" v-if="inputData">
                        <v-form ref="form">
                            <v-row no-gutters class="mt-10 ">
                                    <v-autocomplete
                                            clearable
                                            class="pa-3"
                                            v-model="category"
                                            color="blue-grey darken-1"
                                            item-text="name"
                                            return-object
                                            :items="inputData.categories"
                                            :rules="formRules"
                                            label="Kategorija"
                                            outlined
                                    />
                            </v-row>
                            <v-row no-gutters class="">
                                    <v-col cols="12" sm="8">
                                        <v-text-field
                                                class="pa-3"
                                                v-model="name"
                                                label="Nosaukums"
                                                color="blue-grey darken-1"
                                                :rules="formRules"
                                                outlined
                                                required>
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                                class="pa-3"
                                                v-model="price"
                                                label="Bāzes cena"
                                                color="blue-grey darken-1"
                                                outlined
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>



                            <v-row row justify="center" justify-md="end" no-gutters class="mb-4">
                                <v-btn  text @click="hideAddItemForm" dark color="deep-orange lighten-1">Atcelt</v-btn>
                                <v-btn  text @click="submit" dark color="green accent-4">Pievienot</v-btn>
                            </v-row>

                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
    </v-card>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "add-item",
        props: ['categoryTypeId'],
        data: () => ({
            name: '',
            category: null,
            price: '',
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),
        mounted() {
            this.$store.dispatch('getItemInputData', this.categoryTypeId)
        },
        computed: {
            ...mapGetters({
                inputData: 'itemInputData'
            })
        },
        methods: {
            hideAddItemForm(){
                this.$store.dispatch('hideAddItemForm')
            },
            submit(){
                this.$Progress.start()
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('createNewItem', {
                        name: this.name,
                        category: this.category,
                        price: this.price,
                    })
                }  else {
                    this.$Progress.fail()
                }
            },
        }
    }
</script>

<style scoped>

</style>