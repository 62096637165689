<template>
    <v-row justify="center" no-gutters class="px-2">
                <v-col cols="12">
                        <v-form ref="form">
                            <v-row no-gutters class="mt-2">
                                <v-col cols="6">
                                    <v-text-field
                                            v-model="decasedPerson.name"
                                            label="Vārds"
                                            class="px-4"
                                            color="blue-grey darken-1"/>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                            v-model="decasedPerson.surname"
                                            label="Uzvārds"
                                            class="px-4"
                                            color="blue-grey darken-1"/>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                            v-model="decasedPerson.address"
                                            label="Adrese"
                                            class="px-4"
                                            color="blue-grey darken-1"/>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                            v-model="decasedPerson.location"
                                            label="Lokācija"
                                            class="px-4"
                                            color="blue-grey darken-1"/>
                                </v-col>

                            </v-row>

                                <v-row row justify="center" justify-md="end" no-gutters class="">
                                    <v-btn  text @click="hideEditForm" dark color="deep-orange lighten-1">Atcelt</v-btn>
                                    <v-btn  text @click="submit" dark color="green accent-3">Pievienot</v-btn>
                                </v-row>
                        </v-form>
                </v-col>
    </v-row>
</template>

<script>

    import {mapGetters} from "vuex";

    export default {
        name: "add-decased-person",
        data: () => ({
            decasedPerson: {},
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),
        mounted() {

        },
        computed: {
            ...mapGetters({
                order: 'order'
            })
        },
        methods: {
            hideEditForm(){
                this.$store.dispatch('hideEditForms')
            },
            submit(){
                this.$Progress.start()
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('addNewDecasedPersonToOrder', this.decasedPerson)
                }  else {
                    this.$Progress.fail()
                }
            },
        }
    }
</script>

<style scoped>

</style>