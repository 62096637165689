<template>
    <v-container
            class="fill-height"
            fluid
    >
        <v-row
                align="center"
                justify="center"
        >
            <v-col
                    cols="12"
                    sm="8"
                    md="4"
            >
                <v-card class="elevation-2">
                    <v-toolbar
                            color="blue-grey darken-2"
                            dark
                            flat
                    >
                        <v-toolbar-title>Login form</v-toolbar-title>
                        <v-spacer />
                    </v-toolbar>
                    <v-card-text>
                        <v-form @submit.prevent="login" id="login-form">
                            <v-text-field name="login" :rules="emailRules" label="Email" id="username" type="email" v-model="email" required prepend-icon="mdi-email"/>

                            <v-text-field name="password" :rules="passwordRules" label="Password" id="password" type="password" v-model="password" required prepend-icon="mdi-lock"/>
                        </v-form>
                    </v-card-text>

                    <v-flex class="ma-3">
                        <v-alert
                                :value="errorStatus"
                                type="error"
                        >
                            {{ errorMessage }}
                        </v-alert>
                    </v-flex>

                    <v-card-actions>
                        <v-spacer />
                        <v-btn type="submit" dark color="blue-grey darken-2" form="login-form">Login</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "Login",
        data: () => ({
            email: '',
            password: '',
            valid: false,
            passwordRules: [
                v => !!v || 'Password is required',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid'
            ]
        }),
        computed: {
            ...mapGetters({
                errorStatus: 'authErrorStatus',
                errorMessage: 'authErrorMessage'
            })
        },
        methods: {
            login(){
                this.$store.dispatch('getToken', {
                    email: this.email,
                    password: this.password
                })
                    .then(response => {
                        this.$router.push({ name: 'orders'})
                    })
            }
        }
    }
</script>

<style scoped>

</style>