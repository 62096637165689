import axios from 'axios'
import {vm} from '@/main'

var baseUrl = process.env.VUE_APP_BASE_URL

const customers = {
    state: {
        customers: null,
        showAddCustomerForm: false
    },

    mutations: {
        setCustomers(state, customers){
            state.customers = customers
        },
        showAddCustomerForm(state){
            state.showAddCustomerForm = true
        },
        hideAddCustomerForm(state){
            state.showAddCustomerForm = false
        },
        clearCustomers(state){
            state.customers = null
        }
    },

    actions: {
        getCustomers({ commit, rootState, dispatch, state }, page){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/search_customers?page='+page +'&q='+rootState.search)
                    .then(response => {
                        commit('setCustomers', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },
        createNewCustomer({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/customer', payload)
                    .then(response => {
                        dispatch('getCustomers')
                        commit('hideAddCustomerForm')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Klients izveidots'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Klientu izveidot neizdevās'
                        })
                        vm.$Progress.fail()
                        reject(error)
                    })
            })
        },

        updateCustomer({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.patch(baseUrl +'/api/customer/' + rootState.editableItem.id, payload)
                    .then(response => {
                        dispatch('getCustomers')
                        dispatch('hideEditModal')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'blue',
                            text: 'Ieraksts izlabots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izlabot'
                            })
                        }
                        reject(error)
                    })
            })
        },

        deleteCustomer({commit, rootState, dispatch, state}, customerId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.delete(baseUrl +'/api/customer/'+customerId)
                    .then(response => {
                        dispatch('getCustomers')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Klients dzēsts'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        vm.$Progress.fail()
                        reject(error)
                    })
            })
        },

        showAddCustomerForm({ commit }){
            commit('showAddCustomerForm')
        },
        hideAddCustomerForm({ commit }){
            commit('hideAddCustomerForm')
        },
        clearCustomers({ commit }){
            commit('clearCustomers')
        }
    },
    getters: {
        customers(state){
            return state.customers
        },
        showAddCustomerForm(state){
            return state.showAddCustomerForm
        }
    }
}

export default customers