import axios from 'axios'
import {vm} from '@/main'

var baseUrl = process.env.VUE_APP_BASE_URL

const items = {
    state: {
        items: null,
        itemInputData: null,
        showAddItemForm: false
    },

    mutations: {
        setItems(state, items){
            state.items = items
        },
        setInputData(state, payload){
            state.itemInputData = payload
        },
        showAddItemForm(state){
            state.showAddItemForm = true
        },
        hideAddItemForm(state){
            state.showAddItemForm = false
        }
    },

    actions: {
        getItems({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/' + payload.categoryTypeId + '/items?page=' + payload.currentPage + '&q=' + rootState.search)
                    .then(response => {
                        commit('setItems', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },
        getItemInputData({ commit, rootState, dispatch }, categoryTypeId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/items/' + categoryTypeId + '/create')
                    .then(response => {
                        commit('setInputData', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },
        createNewItem({ commit, rootState, dispatch, state }, formData){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/items/' + formData.category.id, {
                    name: formData.name,
                    price: formData.price
                })
                    .then(response => {
                        dispatch('getItems', {
                            categoryTypeId: formData.category.category_type_id
                        })
                        commit('hideAddItemForm')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Ieraksts izveidots'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Pakalpojumu neizdevās pievienot'
                        })
                        vm.$Progress.fail()
                        reject(error)
                    })
            })
        },
        createNewProduct({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token


            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/products/' + payload.category.id, payload )
                    .then(response => {
                        dispatch('getItems', {
                            categoryTypeId: payload.category.category_type_id
                        })
                        commit('hideAddItemForm')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Ieraksts izveidots'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Pakalpojumu neizdevās pievienot'
                        })
                        vm.$Progress.fail()
                        reject(error)
                    })
            })
        },
        updateProduct({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            let editableItem = rootState.editableItem

            return new Promise((resolve, reject) => {

                axios.patch(baseUrl +'/api/products/' + editableItem.id, payload.data)
                    .then(response => {
                        dispatch('hideEditModal')
                        vm.$Progress.finish()
                        vm.$router.push({ path: '/products' })
                        dispatch('setSnackbar', {
                            color: 'blue',
                            text: 'Ieraksts izlabots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izlabot'
                            })
                        }
                        reject(error)
                    })
            })
        },
        getEditableItem({ commit, rootState, dispatch, state }, payload){

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/' + payload.apiLink + '/' + payload.itemId)
                    .then(response => {
                        dispatch('setEditableItem', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },
        uploadItemImage({ commit, rootState, dispatch, state }, payload){
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
                const formData = new FormData
                formData.append('image', payload.image)

                return new Promise((resolve, reject) => {

                    axios.post(baseUrl +'/api/images/' + payload.itemId, formData)
                        .then(response => {
                            vm.$Progress.finish()
                            dispatch('getEditableItem', payload)
                            dispatch('setSnackbar', {
                                color: 'green',
                                text: 'Bilde pievienota'
                            })
                            resolve(response)
                        })
                        .catch(error => {
                            if (error.response.status === 401) {
                                dispatch('destroyToken')
                                location.reload();
                            }
                            if (error.response.status === 422) {
                                vm.$Progress.fail()
                                commit('setUserErrorMessages', error.response.data.errors)
                                dispatch('setSnackbar', {
                                    color: 'red',
                                    text: 'Ierakstu neizdevās izlabot'
                                })
                            }
                            reject(error)
                        })
                })

        },
        updateCategoryItem({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.patch(baseUrl +'/api/items/' + rootState.editableItem.id, payload.data)
                    .then(response => {
                        dispatch('getItems', {
                            categoryTypeId: payload.categoryTypeId
                        })
                        dispatch('hideEditModal')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'blue',
                            text: 'Ieraksts izlabots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izlabot'
                            })
                        }
                        reject(error)
                    })
            })
        },
        deleteItem({commit, rootState, dispatch, state}, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.delete(baseUrl +'/api/items/'+payload.itemId)
                    .then(response => {
                        dispatch('getItems', {
                            categoryTypeId: payload.categoryTypeId
                        })
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Ieraksts dzēsts'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        vm.$Progress.fail()
                        reject(error)
                    })
            })
        },
        deleteImage({commit, rootState, dispatch, state}, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.delete(baseUrl +'/api/images/' + payload.imageId)
                    .then(response => {
                        dispatch('getEditableItem', payload)
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Ieraksts dzēsts'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        vm.$Progress.fail()
                        reject(error)
                    })
            })
        },

        showAddItemForm({ commit }){
            commit('showAddItemForm')
        },
        hideAddItemForm({ commit }){
            commit('hideAddItemForm')
        },
    },
    getters: {
        items(state){
            return state.items
        },
        itemInputData(state){
            return state.itemInputData
        },
        showAddItemForm(state){
            return state.showAddItemForm
        }
    }
}

export default items