<template>
    <v-card flat>
        <v-row class="px-4 pt-2" align="center">
            <v-col cols="8" md="6">
                <v-text-field
                        dense
                        hide-details
                        outlined
                        @input="searchProducts"
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Meklēt preci"
                        color="indigo lighten-1"
                        class=""
                />
            </v-col>
            <v-spacer/>
            <v-btn right text color="grey darken-4" @click="hideAddModal">X</v-btn>
        </v-row>

        <v-card-text v-if="products">
                <v-row no-gutters class="mt-2">
                    <v-col cols="12" sm="6" xl="4" v-for="product in products" :key="product.id">
                        <v-card outlined flat class="ma-1" height="250" @click="selectProduct(product)" hover>

                            <v-img height="150" v-if="product.image" cover :src="product.image.url"/>
                            <v-card tile v-else flat height="150" color="grey lighten-2 text--white text-center pt-10" >
                                <div class="display-2 font-weight-light white--text">{{product.category.name}}</div>
                            </v-card>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="6">
                                        <div class="font-weight-medium">
                                            {{product.name}}
                                        </div>
                                        <div>
                                            <template v-if="product.price">
                                                {{product.price }} EUR
                                            </template>
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        <div>
                                            <template v-if="product.itemDetail">
                                                <span v-if="product.itemDetail.model">Modelis: {{product.itemDetail.model }}</span>
                                            </template>
                                        </div>
                                        <div>
                                            <template v-if="product.itemDetail">
                                                <span v-if="product.itemDetail.size">Izmērs: {{product.itemDetail.model }}</span>
                                            </template>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <template v-if="products">
                    <v-row row justify="center" justify-md="end" no-gutters class="" v-if="search !== '' && products.length > 0">
                        <v-btn  text @click="hideAddModal" dark color="deep-orange lighten-1">Atcelt</v-btn>
                    </v-row>
                </template>

        </v-card-text>

        <template v-if="products">
            <v-card-text v-if="search !== '' && products.length === 0">
                <v-col cols="12">
                    <v-card tile flat class="text-center" >
                        <div class="headline font-weight-light">Nav rezultātu</div>
                    </v-card>
                </v-col>
            </v-card-text>
        </template>

    </v-card>

</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "add-order-product",
        data: () => ({

        }),
        mounted() {
            this.$store.dispatch('changeSearch', '')
        },
        computed: {
            ...mapGetters({
                search: 'search',
                products: 'products'
            })
        },
        methods: {
            selectProduct(product){
                this.$store.dispatch('addSelectedProduct', product)
                this.hideAddModal()
            },
            searchProducts(value){
                this.$store.dispatch('changeSearch', value)
                this.$store.dispatch('getProducts')
            },
            hideAddModal(){
                this.$store.dispatch('changeSearch', '')
                this.$store.dispatch('clearProducts')
                this.$store.dispatch('hideAddModal')
            },
        }
    }
</script>

<style scoped>

</style>