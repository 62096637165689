<template>
    <v-row justify="center" no-gutters class="px-2">
        <template v-if="order">
                <v-col cols="12">

                    <v-row no-gutters class="my-10" v-if="showMenu" justify="center">
                        <v-btn text @click="showEditForm" class="blue-grey--text">
                            Labot esošā pasūtītāja datus
                        </v-btn>
                        <v-btn text @click="showChangeForm" class="blue-grey--text">
                            Mainīt pasūtītāju
                        </v-btn>
                    </v-row>

                    <template v-if="showEditCustomerForm">
                        <v-form ref="form">
                            <v-row no-gutters class="mt-2">
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                            class="pa-3"
                                            v-model="order.customer.name"
                                            label="Nosaukums"
                                            color="blue-grey darken-1"
                                            :rules="formRules"
                                            hide-details
                                            required>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <v-text-field
                                            class="pa-3"
                                            v-model="order.customer.reg_nr"
                                            label="Reģ. nr. / PK"
                                            color="blue-grey darken-1"
                                            hide-details
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <v-text-field
                                            class="pa-3"
                                            v-model="order.customer.email"
                                            label="E-pasts"
                                            color="blue-grey darken-1"
                                            hide-details
                                            required>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <v-text-field
                                            class="pa-3"
                                            v-model="order.customer.phone"
                                            label="Telefona nr."
                                            color="blue-grey darken-1"
                                            hide-details
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field
                                            class="pa-3"
                                            v-model="order.customer.address"
                                            label="Adrese"
                                            color="blue-grey darken-1"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row row justify="center" justify-md="end" no-gutters class="">
                                <v-btn  text @click="hideEditForm" dark color="deep-orange lighten-1">Atcelt</v-btn>
                                <v-btn  text @click="submit" dark color="blue accent-3">Labot</v-btn>
                            </v-row>

                        </v-form>
                    </template>

                    <template v-if="showChangeCustomerForm">
                        <v-row class="px-4 mt-2">
                            <v-col cols="10" md="6">
                                <v-text-field
                                        dense
                                        hide-details
                                        @input="searchCustomers"
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        label="Meklēt klientu"
                                        color="blue-grey darken-1"
                                        class=""
                                />
                            </v-col>

                            <v-col cols="12">
                                <template v-if="customers">
                                    <v-simple-table class="px-4" height="200px" fixed-header>
                                        <template v-slot:default>
                                            <thead>
                                            <tr>
                                                <th class="text-left">Nosaukums</th>
                                                <th class="text-left">Reģ.nr. / PK</th>
                                                <th class="text-left">Adrese</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="customer in customers.data" :key="customer.id" @click="changeOrderCustomer(customer.id)">
                                                <td>{{ customer.name }}</td>
                                                <td>{{ customer.reg_nr }}</td>
                                                <td>{{ customer.address }}</td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </template>
                            </v-col>

                        </v-row>
                    </template>

                </v-col>
        </template>
    </v-row>
</template>

<script>

    import {mapGetters} from "vuex";

    export default {
        name: "edit-order-customer",
        data: () => ({
            showMenu: true,
            showEditCustomerForm: false,
            showChangeCustomerForm: false,
            formRules: [
                v => !!v || 'Šis lauks ir obligāts',
            ],
        }),
        mounted() {
            this.$store.dispatch('getEditOrderInputData')
            this.$store.dispatch('changeSearch', '')
            this.$store.dispatch('clearCustomers')
        },
        computed: {
            ...mapGetters({
                inputData: 'editOrderInputData',
                order: 'order',
                customers: 'customers',
                search: 'search',
            })
        },
        methods: {
            showEditForm(){
                this.showMenu = false
                this.showEditCustomerForm = true
            },
            showChangeForm(){
                this.showMenu = false
                this.showChangeCustomerForm = true
            },
            hideEditForm(){
                this.$store.dispatch('clearCustomers')
                this.$store.dispatch('changeSearch', '')
                this.$store.dispatch('hideEditForms')
            },
            searchCustomers(value){
                this.$store.dispatch('changeSearch', value)
                this.$store.dispatch('getCustomers')
            },
            submit(){
                this.$Progress.start()
                if (this.$refs.form.validate()) {
                    this.$store.dispatch('updateOrderCustomer')
                }  else {
                    this.$Progress.fail()
                }
            },
            changeOrderCustomer(customerId){
                this.$Progress.start()
                this.$store.dispatch('changeOrderCustomer', customerId)
            }
        }
    }
</script>

<style scoped>

</style>