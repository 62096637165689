import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login";
import Services from "../views/Services"
import Products from "../views/Products";
import Categories from "../views/Categories";
import Materials from "../views/Materials";
import Customers from "../views/Customers";
import Orders from "../views/Orders";
import Order from "../views/Order";
import OrderCreate from "../views/OrderCreate";
import NotFound from "../views/NotFound";
import store from "../store";
import Users from "../views/Users";
import ServiceProviders from "../views/ServiceProviders";
import EditUser from "../views/EditUser";
import EditProduct from "../views/EditProduct";
import FuneralLocations from "../views/FuneralLocations";
import FuneralLocationsPlaces from "../views/FuneralLocationsPlaces";
import InvoiceCreate from "../views/InvoiceCreate";
import Invoice from "../views/Invoice";

Vue.use(VueRouter)

const routes = [
      {
        path: '/',
        name: 'home',
        component: Orders,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
          requiresVisitor: true
        }
      },
    {
        path: '/services',
        name: 'services',
        component: Services,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/categories',
        name: 'categories',
        component: Categories,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/materials',
        name: 'materials',
        component: Materials,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/products',
        name: 'products',
        component: Products,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/products/edit/:productId',
        name: 'products.edit',
        component: EditProduct,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/customers',
        name: 'customers',
        component: Customers,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/service_providers',
        name: 'service_providers',
        component: ServiceProviders,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/funeral_locations',
        name: 'funeral_locations',
        component: FuneralLocations,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/funeral_locations_places',
        name: 'funeral_locations_places',
        component: FuneralLocationsPlaces,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/orders',
        name: 'orders',
        component: Orders,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/orders/create',
        name: 'orders.create',
        component: OrderCreate,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/orders/:orderId',
        name: 'order',
        component: Order,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/orders/:orderId/invoice/:invoiceId',
        name: 'invoice',
        component: Invoice,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/orders/:orderId/create_invoice',
        name: 'create_invoice',
        component: InvoiceCreate,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        meta: {
            requiresAuth: true,
            requiresPermission: 'manage users'
        },
    },
    {
        path: '/users/edit/:userId',
        name: 'users.edit',
        component: EditUser,
        meta: {
            requiresAuth: true,
            requiresPermission: 'manage users'
        },
    },
    { path: '*', component: NotFound }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {

        if (!store.getters.loggedIn) {
            next({ name: 'login'})
        }
        else if (to.matched.some(record => record.meta.requiresPermission)) {

            if ( !store.getters.user) {

                store.dispatch( 'getUser' );
                store.watch( store.getters.userFunction, function(){

                    if( !store.getters.user.permissions.includes(to.meta.requiresPermission) ){
                        next({ name: 'home'})
                    } else {
                        next()
                    }
                });

            }

            else if (!store.getters.user.permissions.includes(to.meta.requiresPermission)) {
                next({ name: 'home'})
            } else {
                next()
            }
        }
        else {
            next()
        }

    } else if (to.matched.some(record => record.meta.requiresVisitor)) {

        if (store.getters.loggedIn) {
            next({ name: 'home' })
        } else {
            next()
        }

    } else {
        next()
    }
})

export default router
