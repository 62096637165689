import axios from 'axios'
import {vm} from '@/main'

var baseUrl = process.env.VUE_APP_BASE_URL

const categories = {
    state: {
        categories: null,
        categoryInputData: null,
        showAddCategoryForm: false
    },

    mutations: {
        setCategories(state, categories){
            state.categories = categories
        },
        setInputData(state, payload){
            state.categoryInputData = payload
        },
        showAddCategoryForm(state){
            state.showAddCategoryForm = true
        },
        hideAddCategoryForm(state){
            state.showAddCategoryForm = false
        }
    },

    actions: {
        getCategories({ commit, rootState, dispatch, state }, page){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/search_categories?page='+page +'&q='+rootState.search)
                    .then(response => {
                        commit('setCategories', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },
        getCategoryInputData({ commit, rootState, dispatch }){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/category/create')
                    .then(response => {
                        commit('setInputData', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },
        createNewCategory({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.post(baseUrl +'/api/category', payload)
                    .then(response => {
                        dispatch('getCategories')
                        commit('hideAddCategoryForm')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Kategorija izveidota'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Kategoriju neizdevās izveidot'
                        })
                        vm.$Progress.fail()
                        reject(error)
                    })
            })
        },

        updateCategory({ commit, rootState, dispatch, state }, payload){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.patch(baseUrl +'/api/category/' + rootState.editableItem.id, payload)
                    .then(response => {
                        dispatch('getCategories')
                        dispatch('hideEditModal')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'blue',
                            text: 'Ieraksts izlabots'
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.$Progress.fail()
                            commit('setUserErrorMessages', error.response.data.errors)
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu neizdevās izlabot'
                            })
                        }
                        reject(error)
                    })
            })
        },

        deleteCategory({commit, rootState, dispatch, state}, categoryId){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token

            return new Promise((resolve, reject) => {

                axios.delete(baseUrl +'/api/category/'+categoryId)
                    .then(response => {
                        dispatch('getCategories')
                        vm.$Progress.finish()
                        dispatch('setSnackbar', {
                            color: 'red',
                            text: 'Kategorija dzēsta'
                        })
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error.response.status)
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        showAddCategoryForm({ commit }){
            commit('showAddCategoryForm')
        },
        hideAddCategoryForm({ commit }){
            commit('hideAddCategoryForm')
        },
    },
    getters: {
        categories(state){
            return state.categories
        },
        categoryInputData(state){
            return state.categoryInputData
        },
        showAddCategoryForm(state){
            return state.showAddCategoryForm
        }
    }
}

export default categories