<template>
    <div>
        <v-navigation-drawer
                v-model="drawer"
                fixed
                :clipped="$vuetify.breakpoint.lgAndUp"
                class="blue-grey darken-1"
                dark
                app
        >
            <v-list dense v-if="user">
                <template v-for="link in links">
                    <v-list-item
                            v-if="checkIfLinkIsAllowed(link.permission)"
                            :key="link.text"
                            @click=""
                            router :to="link.route"
                    >
                        <v-list-item-action>
                            <v-icon>{{ link.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ link.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar
                :clipped-left="$vuetify.breakpoint.lgAndUp"
                app
                color="blue-grey darken-2"
                dark
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>

            <v-spacer/>

            <span class="" v-if="user">{{user.name}}</span>

            <v-btn @click="logout" text color="white" >
                Iziet
            </v-btn>

        </v-app-bar>
    </div>
</template>

<script>
    export default {
        name: "Navigation",
        props: ['links', 'user'],
        data: () => ({
            dialog: false,
            drawer: null,
        }),
        methods: {
            logout () {
                this.$store.dispatch('destroyToken');
            },
            checkIfLinkIsAllowed(linkPermission){
                if (linkPermission === ''){
                    return true
                }
                return this.user.permissions.includes(linkPermission);
            }
        },
    }
</script>

<style scoped>

</style>